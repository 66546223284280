import React, { useState, useEffect } from 'react';
import "./MarkupBlockTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from '../../../../hooks/useConfigurationTableCell';
import useConfigurationTableRow from '../../../../hooks/useConfigurationTableRow';
import cabinClassList from "../../../../data/CabinClass";
import Paper from "@material-ui/core/Paper";
import { Chip } from '@material-ui/core';
import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import Box from '@mui/material/Box';

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";


function TablePaginationActions({count, page, rowsPerPage, onPageChange}) {
    const handlePageChange = (event, newPage) => onPageChange(event, newPage);
    return (
        <>
            <IconButton
                 onClick={(e) => handlePageChange(e, 0)}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={(e) => handlePageChange(e, page - 1)}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={(e) => handlePageChange(e, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={(e) => handlePageChange(e, Math.ceil(count / rowsPerPage) - 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}

export default function MarkupBlockTable({
    openDrawer,
    setAction,
    commissionBlocks,
    name,
    setPage,
    page,
    vendorList,
    loadCommissionBlocks,
    initialLoad,
    setInitialLoad,
    filteredCommissionBlocks,
    setFilteredCommissionBlocks,
    filters,
    setFilters,
}) {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    const [rowsPerPage, setRowsPerPage] = useState(25);

      useEffect(() => {
        loadCommissionBlocks();
      }, [page, rowsPerPage]);

    const handlePageChange = (event, newPage) => setPage(newPage + 1);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
      };

    //       const handleClearFilters = () => {
    //     setFilters({ reset: true });
    //     setFilteredCommissionBlocks([]);
    //     setPage(1);
    // };


      const getVisibleBlocks = () => {
        if (!commissionBlocks) return null;
        if (filteredCommissionBlocks.length > 0 && !filters.reset) return filteredCommissionBlocks;
        return commissionBlocks;
    };

    const visibleBlocks = getVisibleBlocks();

    useEffect(() => {
        if (!initialLoad) {
            loadCommissionBlocks();
            setInitialLoad(true);
        }
    }, [page]);




      const renderTableRows = (blocksList = []) =>
        blocksList.map((blocks) => (
            <StyledTableRow
                key={blocks.markupBlock?.id || Math.random()} // Fallback to avoid key errors
                onClick={() => {
                    setAction("view");
                    openDrawer(blocks.markupBlock);
                }}
            >
                <StyledTableCell component="th" scope="row">
                    <div className="table-cell-title">{blocks.markupBlock?.id}</div>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <div className="table-cell-title">{name}</div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-cell-title">{blocks.markupBlock?.airline}</div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-cell-title">{blocks.markupBlock?.fareType}</div>
                </StyledTableCell>
                <StyledTableCell>
                    {cabinClassList
                        ?.filter((item) => item.label === blocks.markupBlock?.cabinClass)
                        .map((row, index) => (
                            <div key={index} className="table-cell-title">{row.label}</div>
                        ))}
                </StyledTableCell>
                <StyledTableCell>
                    {vendorList
                        ?.filter((list) => list.id === blocks.markupBlock?.vendorId)
                        .map((row, index) => (
                            <div key={index} className="table-cell-title">{row.name}</div>
                        ))}
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-cell-title">
                        {blocks.markupBlock?.isActive ? (
                            <Chip label="Active" color="primary" size="small" style={{ backgroundColor: "green" }} />
                        ) : (
                            <Chip label="Inactive" color="primary" style={{ backgroundColor: "red" }} />
                        )}
                    </div>
                </StyledTableCell>
                <StyledTableCell>
                    <div className="table-cell-description">
                        Created by: <span style={{ color: "black" }}>{blocks.createdBy}</span>
                    </div>
                    <div className="table-cell-description">
                        Created on: {blocks.markupBlock?.created?.replace('T', " , ")}
                    </div>
                    <div className="table-cell-description">
                        Last Modified by: <span style={{ color: "black" }}>{blocks.modifiedBy}</span>
                    </div>
                    <div className="table-cell-description">
                        Last Modified on: {blocks.markupBlock?.modified?.replace('T', " , ")}
                    </div>
                </StyledTableCell>
            </StyledTableRow>
        ));
    


    return (
        <div className="supplier-listing">
        {
          filteredCommissionBlocks && filteredCommissionBlocks.length === 0 ? (
            <Alert severity="error">No record found.</Alert>
          ) : (
            <>
              <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                  <TableHead>
                    <TableRow>
                      <TableCell>Supplier Ref ID</TableCell>
                      <TableCell>Pseudo Code</TableCell>
                      <TableCell>Airline</TableCell>
                      <TableCell>FareType</TableCell>
                      <TableCell>Cabin Class</TableCell>
                      <TableCell>VendorNames</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Modified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleBlocks ? (
                      renderTableRows(
                        visibleBlocks.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Results
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={(filteredCommissionBlocks && filteredCommissionBlocks?.length) || (commissionBlocks && commissionBlocks.length)}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </>
          )
        }
      </div>
    );
}
