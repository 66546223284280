import React from "react";
import "./Footer.css";
import Grid from "@mui/material/Grid";
import { Container } from "@material-ui/core";
import cert from "../../assets/iafcert.png";
import { Link } from "react-router-dom";
import getFaresIcon from '../../icons/gfnew.svg';
import kvqa from '../../icons/kvqa.svg';
import uaf from '../../icons/uaf.svg';
import facebook from '../../icons/facebook.svg';
import linkedin from '../../icons/linkedin.svg';
import instagram from '../../icons/instagram.svg';
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className='footer-container'>
        <Grid container sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }} spacing={4}>
          <Grid item xs={6} md={6} lg={3} style={{ paddingLeft: '0' }}>
            <div className='footer-content'>
              <div style={{ display: "flex", alignItems: "center", background: 'white', justifyContent: "center", borderRadius: "0 50px 50px 0", padding: "1em" }}>
                <img alt="LocalFaresIcon" src={getFaresIcon} className="footer-logo"
                  style={{ color: "D82A61", objectFit: "scale-down", }} />
              </div>
              <div className='footer-content-item fs18' style={{ fontFamily: 'Montserrat', paddingLeft: "5vw", color: 'white', fontWeight: 'bold' }}>
                <p style={{ margin: '0' }}>ISO 27001 : 2022 Certified</p>
              </div>
              <div className='footer-content-item' style={{ display: "flex", paddingLeft: "5vw", justifyContent: "flex-start" }}>
                <img
                  style={{
                    width: "5vw",
                    paddingRight: "1em",
                  }}
                  alt='certification'
                  src={kvqa}
                />
                <img
                  style={{
                    width: "10vw",
                  }}
                  alt='certification'
                  src={uaf}
                />
              </div>
              <div className='footer-content-item' style={{ display: "flex", paddingLeft: "5vw", justifyContent: "flex-start" }}>
                <img
                  style={{
                    marginRight: "1vw",
                  }}
                  alt='certification'
                  src={facebook}
                />
                <img
                  style={{
                    marginRight: "1vw",
                  }}
                  alt='certification'
                  src={linkedin}
                />
                <img
                  style={{
                    marginRight: "1vw",
                  }}
                  alt='certification'
                  src={instagram}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3} >
            <div className='footer-content'>
              <div className='footer-content-item  fs18  footer-item-heading'>
                {t("Products and Pricing")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                <Link
                  className='text-link'
                  to={{ pathname: "https://www.akbaroffshore.com/" }}
                  target='_blank'>
                  {t("Products")}
                </Link>
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Pricing")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Optimise your costs")}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3} >
            <div className='footer-content'>
              <div className='footer-content-item  fs18  footer-item-heading'>
                {t("Support")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Solution Architectures")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Operations")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Customer relationship")}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3} style={{ paddingRight: '5vw' }}>
            <div className='footer-content'>
              <div className='footer-content-item fs18  footer-item-heading'>
                {t("Company")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("About Us")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Privacy Policy")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Terms and Conditions")}
              </div>
              <div className='footer-content-item fs16 footer-item-content-text'>
                {t("Careers")}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="TopText p5   txtcntr" style={{ fontFamily: 'Montserrat' }}>© 2024 - GetFares. {t("All Rights Reserved.")}</div>
    </React.Fragment>
  );
}

export default Footer;
