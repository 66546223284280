import React, { Fragment, useEffect, useState } from "react";
import "./MarkupBlockDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { FormControl, InputLabel, NativeSelect, IconButton, useTheme, TextField } from "@material-ui/core";
import moment from "moment";
import countries from "../../../../data/Country";
import cabinClassList from "../../../../data/CabinClass";
import fareBasisTypeList from "../../../../data/FareBasisType";
import fareTypeList from "../../../../data/FareType";
import journeyTypeList from "../../../../data/JourneyType";
import airlineDetails from "../../../../data/AirlineDetails";
import airportDetails from "../../../../data/AirportDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import { isUserHasPermission } from "../../../../services/AuthService";
import { formatDate } from "../../../../services/dateFormatter";
import RiseLoading from "../../../Loader/RiseLoader";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const calTypes = ["Percentage", "Value"];
const infMarkupValues = ["True", "False"];
const applicableOnList = ["Base", "Tax", "Total", "Opportunity"];



export default function MarkupBlockDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  name,
  groupId,
  vendorList,
}) {
  const classes = useStyles();
  const [isActive, setisActive] = useState("true");
  const [markupGroupId, setmarkupGroupId] = useState("");
  const [vendor, setVendor] = useState([]);
  const [airline, setairline] = useState([]);
  const [exairline, setexairline] = useState([]);
  const [FareType, setfareType] = useState([]);
  const [cabinClass, setcabinClass] = useState([]);
  const [journeyType, setJourneyType] = useState('All');
  const [from, setFromDate] = useState(null);
  const [to, setToDate] = useState(null);
  const [inputFrom, setinputFromDate] = useState(null);
  const [inputTo, setinputToDate] = useState(null);
  const [rbd, setrbd] = useState("");
  const [exRBD, setexRBD] = useState("");
  const [fareBasisType, setfareBasisType] = useState("");
  const [fareBasis, setfareBasis] = useState("");
  const [OpportunityFrom, setOpportunityFrom] = useState("");
  const [OpportunityTo, setOpportunityTo] = useState("");
  // const [DisableInfantMarkup, setDisableInfantMarkup] = useState("");
  const [adultMarkupValue, setAdultMarkupValue] = useState("");
  const [childMarkupValue, setChildMarkupValue] = useState("");
  const [infantMarkupValue, setInfantMarkupValue] = useState("");
  const [FeeApplicableType, setFeeApplicableType] = useState("Percentage");
  const [ApplicableOn, setApplicableOn] = useState("Base");
  const [markdownValue, setmarkdownValue] = useState("");
  const [markdownType, setmarkdownType] = useState("Percentage");
  const [markdownApplicableOn, setmarkdownApplicableOn] = useState("Base");
  const [originAirport, setoriginAirport] = useState([]);
  const [destinationAirport, setdestinationAirport] = useState([]);
  const [originCountry, setoriginCountry] = useState([]);
  const [destinationCountry, setdestinationCountry] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setisActive(selectedItem.isActive);
      setmarkupGroupId(selectedItem.markupGroupId);
      setVendor(selectedItem.vendorId);
      if (selectedItem.airline !== undefined && selectedItem.airline !== "") {
        let list = [];
        selectedItem.airline
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setairline(list);
      } else {
        setairline([]);
      }
      if (
        selectedItem.exAirline !== undefined &&
        selectedItem.exAirline !== ""
      ) {
        let list = [];
        selectedItem.exAirline
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setexairline(list);
      } else {
        setexairline([]);
      }
      if (selectedItem.fareType !== undefined && selectedItem.fareType !== "") {
        let list = [];
        selectedItem.fareType
          .split(",")
          .map((fair) => list.push(fareTypeList.find((el) => el === fair)));
        setfareType(list);
      } else {
        setfareType([]);
      }
      if (
        selectedItem.cabinClass !== undefined &&
        selectedItem.cabinClass !== ""
      ) {
        let list = [];
        selectedItem.cabinClass
          .split(",")
          .map((cabin) =>
            list.push(cabinClassList.find((el) => el.label === cabin))
          );
        setcabinClass(list);
      } else {
        setcabinClass([]);
      }
      setJourneyType(selectedItem.journeyType ? selectedItem.journeyType : 'All')
      setFromDate(selectedItem.from ? formatDate(selectedItem.from) : null);
      setToDate(selectedItem.to ? formatDate(selectedItem.to) : null);
      setinputFromDate(selectedItem.from ? selectedItem.from : null);
      setinputToDate(selectedItem.to ? selectedItem.to : null);
      setrbd(selectedItem.rbd);
      setexRBD(selectedItem.exRBD);
      setfareBasisType(selectedItem.fareBasisType);
      setfareBasis(selectedItem.fareBasis);
      setOpportunityFrom(selectedItem.opportunityFrom);
      setOpportunityTo(selectedItem.opportunityTo);
      // setDisableInfantMarkup(selectedItem.disableInfantMarkup == true ? 'True' : 'False');
      setAdultMarkupValue(selectedItem.markupValue);
      setChildMarkupValue(selectedItem.childMarkupValue);
      setInfantMarkupValue(selectedItem.infantMarkupValue);
      setFeeApplicableType(selectedItem.feeApplicableType);
      setApplicableOn(selectedItem.applicableOn);
      setmarkdownValue(selectedItem.markdownValue);
      setmarkdownType(selectedItem.markdownType);
      setmarkdownApplicableOn(selectedItem.markdownApplicableOn);
      if (
        selectedItem.originAirport !== undefined &&
        selectedItem.originAirport !== ""
      ) {
        let list = [];
        selectedItem.originAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setoriginAirport(list);
      } else {
        setoriginAirport([]);
      }
      if (
        selectedItem.destinationAirport !== undefined &&
        selectedItem.destinationAirport !== ""
      ) {
        let list = [];
        selectedItem.destinationAirport
          .split(",")
          .map((air) =>
            list.push(airportDetails.find((el) => el.city_code === air))
          );
        setdestinationAirport(list);
      } else {
        setdestinationAirport([]);
      }

      if (
        selectedItem.originCountry !== undefined &&
        selectedItem.originCountry !== ""
      ) {
        let list = [];
        selectedItem.originCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setoriginCountry(list);
      } else {
        setoriginCountry([]);
      }

      if (
        selectedItem.destinationCountry !== undefined &&
        selectedItem.destinationCountry !== ""
      ) {
        let list = [];
        selectedItem.destinationCountry
          .split(",")
          .map((air) => list.push(countries.find((el) => el.code === air)));
        setdestinationCountry(list);
      } else {
        setdestinationCountry([]);
      }
    } else {
      setisActive("true");
      setmarkupGroupId(groupId);
      setVendor("");
      setairline([]);
      setexairline([]);
      setfareType([]);
      setcabinClass([]);
      setJourneyType('')
      setFromDate(null);
      setToDate(null);
      setinputFromDate(null);
      setinputToDate(null);
      setrbd("");
      setexRBD("");
      setfareBasisType("");
      setfareBasis("");
      setOpportunityFrom("");
      setOpportunityTo("");
      // setDisableInfantMarkup("False");
      setAdultMarkupValue("");
      setChildMarkupValue("");
      setInfantMarkupValue("");
      setFeeApplicableType("Percentage");
      setApplicableOn("Base");
      setmarkdownValue("");
      setmarkdownType("Percentage");
      setmarkdownApplicableOn("Base");
      setoriginAirport([]);
      setdestinationAirport([]);
      setoriginCountry([]);
      setdestinationCountry([]);
    }
  }, [toggleDrawer, action]);

  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
      if (field === "value") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.value)
            : (strValue = strValue + "," + arr.value)
        );
      } else if (field === "Code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.Code)
            : (strValue = strValue + "," + arr.Code)
        );
      } else if (field === "city_code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.city_code)
            : (strValue = strValue + "," + arr.city_code)
        );
      } else if (field === "countrycode") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.code)
            : (strValue = strValue + "," + arr.code)
        );
      } else if (field === "raw") {
        arrayList.map((arr) =>
          strValue === "" ? (strValue = arr) : (strValue = strValue + "," + arr)
        );
      } else if (field === "label") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.label)
            : (strValue = strValue + "," + arr.label)
        );
      }
    }
    return strValue;
  };

  async function handleUpdate() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/MarkupBlock/" +
          selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: selectedItem.createdBy === undefined ? "" : selectedItem.createdBy,
            created: selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified: selectedItem.created === undefined ? "" : selectedItem.created,
            markupGroupId:
              markupGroupId === undefined ? groupId : markupGroupId,
            vendorId: (vendor === undefined ? "0" : vendor),
            airline: handleArrayToString(airline, "Code"),
            exAirline: handleArrayToString(exairline, "Code"),
            fareType: handleArrayToString(FareType, "raw"),
            cabinClass: handleArrayToString(cabinClass, "label"),
            From: inputFrom ? (moment(inputFrom).set({ hour: 10, minute: 30, second: 0, millisecond: 0 })) : null,
            To: inputTo ? (moment(inputTo).set({ hour: 10, minute: 30, second: 0, millisecond: 0 })) : null,
            rbd: rbd === undefined ? "" : rbd,
            exRBD: exRBD === undefined ? "" : exRBD,
            fareBasisType: fareBasisType === undefined || fareBasisType === "" ? "0" : fareBasisType,
            fareBasis: fareBasis === undefined ? "" : fareBasis,
            opportunityFrom: OpportunityFrom === undefined || OpportunityFrom === "" ? "0" : OpportunityFrom,
            opportunityTo: OpportunityTo === undefined || OpportunityTo === "" ? "0" : OpportunityTo,
            // disableInfantMarkup: DisableInfantMarkup === "True" ? true : false,
            disableInfantMarkup: false,
            markupValue: adultMarkupValue === undefined || adultMarkupValue === "" ? "0" : adultMarkupValue,
            childMarkupValue: childMarkupValue === undefined || childMarkupValue === "" ? "0" : parseFloat(childMarkupValue),
            infantMarkupValue: infantMarkupValue === undefined || infantMarkupValue === "" ? "0" : parseFloat(infantMarkupValue),
            feeApplicableType: FeeApplicableType === undefined ? "" : FeeApplicableType,
            applicableOn: ApplicableOn === undefined ? "" : ApplicableOn,
            MarkdownValue: markdownValue === undefined || markdownValue === "" ? "0" : markdownValue,
            MarkdownType: markdownType === undefined ? "" : markdownType,
            MarkdownApplicableOn: markdownApplicableOn === undefined ? "" : markdownApplicableOn,
            journeyType: journeyType ? journeyType : "All",
            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle("Updated Successfully", "success");
          setShowLoader(false);
        })
        .catch((err) => {
          handleSnackbarToggle(err.response.data.title, "error");
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + "api/Configuration/MarkupBlock",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            markupGroupId: markupGroupId === undefined ? groupId : markupGroupId,
            vendorId: (vendor === undefined ? "0" : vendor),
            airline: handleArrayToString(airline, "Code"),
            exAirline: handleArrayToString(exairline, "Code"),
            fareType: handleArrayToString(FareType, "raw"),
            cabinClass: handleArrayToString(cabinClass, "label"),
            From: inputFrom ? (moment(inputFrom).set({ hour: 10, minute: 30, second: 0, millisecond: 0 })) : null,
            To: inputTo ? (moment(inputTo).set({ hour: 10, minute: 30, second: 0, millisecond: 0 })) : null,
            rbd: rbd === undefined ? "" : rbd,
            exRBD: exRBD === undefined ? "" : exRBD,
            fareBasisType: fareBasisType === undefined || fareBasisType === "" ? "0" : fareBasisType,
            fareBasis: fareBasis === undefined ? "" : fareBasis,
            opportunityFrom: OpportunityFrom === undefined || OpportunityFrom === "" ? "0" : OpportunityFrom,
            opportunityTo: OpportunityTo === undefined || OpportunityTo === "" ? "0" : OpportunityTo,
            // disableInfantMarkup: DisableInfantMarkup === "True" ? true : false,
            disableInfantMarkup: false,
            markupValue: adultMarkupValue === undefined || adultMarkupValue === "" ? "0" : adultMarkupValue,
            childMarkupValue: childMarkupValue === undefined || childMarkupValue === "" ? "0" : parseFloat(childMarkupValue),
            infantMarkupValue: infantMarkupValue === undefined || infantMarkupValue === "" ? "0" : parseFloat(infantMarkupValue),
            feeApplicableType: FeeApplicableType === undefined ? "" : FeeApplicableType,
            applicableOn: ApplicableOn === undefined ? "" : ApplicableOn,
            MarkdownValue: markdownValue === undefined || markdownValue === "" ? "0" : markdownValue,
            MarkdownType: markdownType === undefined ? "" : markdownType,
            MarkdownApplicableOn: markdownApplicableOn === undefined ? "" : markdownApplicableOn,
            journeyType: journeyType ? journeyType : "All",
            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle("Created Successfylly", "Success");
          setShowLoader(false);
        })
        .catch((err) => {
          handleSnackbarToggle(err.response.data.title, "error");
          setShowLoader(false);
        });
    }
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="supplier-heading-container supplier-title-flex-container">
        <div>Markup Block</div>
        {action === "view" && (
          <div className="supplier-heading-icons">
            <IconButton fontSize="small">
              <EditRoundedIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditRoundedIcon>
            </IconButton>
          </div>
        )}
      </div>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="supplier-view-edit-drawer-container">
          <div className="supplier-view-edit-drawer-input-container">
            <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
              <div className="view-supplier-drawer-title">MarkupGroup</div>
              <div className="view-supplier-drawer-description ">{name}</div>
            </div>
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendor select"
                  value={
                    vendorList.find((v) => v.id === vendor) || null
                  }
                  onChange={(event, newValue) => {
                    setVendor(newValue ? newValue.id : "0");
                  }}
                  options={vendorList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor"
                      value={vendor}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor
                </div>
                <div className="view-supplier-drawer-description">
                  {vendorList && vendorList.filter(id => id.id === vendor).map((row) => <p>{row.name}</p>)}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="airline select"
                  multiple
                  value={airline && airline}
                  onChange={(event, value) => {
                    setairline(value);
                  }}
                  options={airlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Airline"
                      value={airline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: airline && airline.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="ex airline select"
                  multiple
                  value={exairline && exairline}
                  onChange={(event, value) => {
                    setexairline(value);
                  }}
                  options={airlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude Airline"
                      value={exairline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Airline</div>
                <div className="view-supplier-drawer-description">
                  {airline !== undefined &&
                    airline.length > 0 &&
                    airline.map((air) => (
                      <p>{air !== undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Exclude Airline</div>
                <div className="view-supplier-drawer-description">
                  {exairline !== undefined &&
                    exairline.length > 0 &&
                    exairline.map((air) => (
                      <p>{air !== undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="cabin select"
                  multiple
                  value={cabinClass && cabinClass}
                  onChange={(event, value) => {
                    setcabinClass(value);
                  }}
                  options={cabinClassList}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Cabin Class"
                      value={cabinClass}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: cabinClass && cabinClass.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="faretype select"
                  multiple
                  value={FareType && FareType}
                  onChange={(event, value) => { setfareType(value); }}
                  options={fareTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fare Type"
                      value={FareType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: FareType && FareType.length === 0
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}

            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">cabin Class</div>
                <div className="view-search-drawer-description">
                  {cabinClass !== undefined &&
                    cabinClass.length > 0 &&
                    cabinClass.map((cabin) => <p>{cabin !== undefined ? cabin.label : ""}</p>)}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Fare Type</div>
                <div className="view-search-drawer-description">
                  {FareType !== undefined && FareType.length > 0 && FareType.map((trip) => <p>{trip}</p>)}</div>
              </div>
            )}

          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="journeytype select"
                  value={journeyType && journeyType}
                  onChange={(event, value) => { setJourneyType(value); }}
                  options={journeyTypeList}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Journey Type"
                      value={journeyType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: journeyType && journeyType.length === 0
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Journey Type</div>
                <div className="view-search-drawer-description">
                  {journeyType !== undefined && <p>{journeyType}</p>}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="from"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Sale From Date"
                    format="dd/MM/yyyy"
                    size="small"
                    value={from !== undefined ? from : null}
                    inputValue={from !== undefined ? from : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setFromDate(date ? formatDate(date) : null);
                      setinputFromDate(date ? date : null);
                    }}
                    required
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="to"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Sale To Date"
                    format="dd/MM/yyyy"
                    size="small"
                    value={to !== undefined ? to : null}
                    inputValue={to !== undefined ? to : null}

                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setToDate(date ? formatDate(date) : null);
                      setinputToDate(date ? date : null);
                    }}
                    required
                  />
                </Fragment>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Sale From Date
                </div>
                <div className="view-supplier-drawer-description">
                  {from ? from : null}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Sale To Date</div>
                <div className="view-supplier-drawer-description">
                  {to ? to : null}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="RBD"
                  size="small"
                  color="secondary"
                  value={rbd}
                  onChange={(event) => setrbd(event.target.value)}
                  required
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Exclude-RBD"
                  size="small"
                  color="secondary"
                  value={exRBD}
                  onChange={(event) => setexRBD(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">RBD</div>
                <div className="view-supplier-drawer-description ">{rbd}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Exclude-RBD</div>
                <div className="view-supplier-drawer-description">{exRBD}</div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Status
                  </InputLabel>
                  <NativeSelect
                    variant="outlined"
                    label="Status"
                    size="small"
                    color="secondary"
                    value={isActive}
                    onChange={(event) => setisActive(event.target.value)}
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>

                  </NativeSelect>
                </FormControl>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1"></div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1"></div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Status</div>
                <div className="view-supplier-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1"></div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>FareBasis</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="farebasistype select"
                  value={
                    fareBasisTypeList.find((v) => v === fareBasisType) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setfareBasisType(newValue ? newValue : "");
                  }}
                  options={fareBasisTypeList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="FareBasis Type"
                      value={fareBasisType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="FareBasis"
                  size="small"
                  color="secondary"
                  value={fareBasis}
                  onChange={(event) => setfareBasis(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">FareBasisType</div>
                <div className="view-supplier-drawer-description">
                  {fareBasisType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">FareBasis</div>
                <div className="view-supplier-drawer-description ">
                  {fareBasis}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>Region</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="originAirport select"
                  multiple
                  value={originAirport && originAirport}
                  onChange={(event, value) => {
                    setoriginAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Airport"
                      value={originAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: originAirport && originAirport.length === 0,
                      }}
                    //required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  //required
                  id="destinationAirport select"
                  multiple
                  value={destinationAirport && destinationAirport}
                  onChange={(event, value) => {
                    setdestinationAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Airport"
                      value={destinationAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: destinationAirport && destinationAirport.length === 0,
                      }}
                    //required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Origin Airport</div>
                <div className="view-search-drawer-description">
                  {originAirport !== undefined &&
                    originAirport.length > 0 &&
                    originAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Airport
                </div>
                <div className="view-search-drawer-description">
                  {destinationAirport !== undefined &&
                    destinationAirport.length > 0 &&
                    destinationAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="origincountry select"
                  multiple
                  value={originCountry && originCountry}
                  onChange={(event, value) => {
                    setoriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Country"
                      value={originCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: originCountry && originCountry.length === 0,
                      }}
                    // required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="destinationcountry select"
                  multiple
                  value={destinationCountry && destinationCountry}
                  onChange={(event, value) => {
                    setdestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Country"
                      value={destinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: destinationCountry && destinationCountry.length === 0,
                      }}
                    //required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Origin Country</div>
                <div className="view-search-drawer-description">
                  {originCountry !== undefined &&
                    originCountry.length > 0 &&
                    originCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Country
                </div>
                <div className="view-search-drawer-description">
                  {destinationCountry !== undefined &&
                    destinationCountry.length > 0 &&
                    destinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div className="text-wrap-cls">Mark Up & Mark Down Value</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Opportunity From"
                  size="small"
                  color="secondary"
                  value={OpportunityFrom}
                  onChange={(event) => setOpportunityFrom(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Opportunity To"
                  size="small"
                  color="secondary"
                  value={OpportunityTo}
                  onChange={(event) => setOpportunityTo(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {/* {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Markup Type"
                  value={
                    infMarkupValues.find((v) => v === DisableInfantMarkup) ||
                    'True'
                  }
                  onChange={(event, newValue) => {
                    setDisableInfantMarkup(newValue ? newValue : "");
                  }}
                  options={infMarkupValues}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Disable Infant Markup"
                      value={DisableInfantMarkup}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )} */}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Opportunity From</div>
                <div className="view-supplier-drawer-description">{OpportunityFrom}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Opportunity To</div>
                <div className="view-supplier-drawer-description">{OpportunityTo}</div>
              </div>
            )}
            {/* {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Disable Infant Markup</div>
                <div className="view-supplier-drawer-description">{DisableInfantMarkup}</div>
              </div>
            )} */}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Adult Markup"
                  size="small"
                  color="secondary"
                  value={adultMarkupValue}
                  onChange={(event) => setAdultMarkupValue(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Child Markup"
                  size="small"
                  color="secondary"
                  value={childMarkupValue}
                  onChange={(event) => setChildMarkupValue(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Infant Markup"
                  size="small"
                  color="secondary"
                  value={infantMarkupValue}
                  onChange={(event) => setInfantMarkupValue(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Markup Type"
                  value={
                    calTypes.find((v) => v === FeeApplicableType) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setFeeApplicableType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Markup Type"
                      value={FeeApplicableType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Markup Type"
                  value={
                    applicableOnList.find((v) => v === ApplicableOn) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setApplicableOn(newValue ? newValue : "");
                  }}
                  options={applicableOnList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Applicable On"
                      value={ApplicableOn}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Adult Markup value</div>
                <div className="view-supplier-drawer-description">{adultMarkupValue}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Child Markup value</div>
                <div className="view-supplier-drawer-description">{childMarkupValue}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Infant Markup value</div>
                <div className="view-supplier-drawer-description">{infantMarkupValue}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Markup Type</div>
                <div className="view-supplier-drawer-description">{FeeApplicableType}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Markup Applicable On</div>
                <div className="view-supplier-drawer-description">{ApplicableOn}</div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Mark Down"
                  size="small"
                  color="secondary"
                  value={markdownValue}
                  onChange={(event) => setmarkdownValue(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number"
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Mark Down Type"
                  value={
                    calTypes.find((v) => v === markdownType) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setmarkdownType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Mark Down Type"
                      value={markdownType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Mark Down Type"
                  value={
                    applicableOnList.find((v) => v === markdownApplicableOn) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setmarkdownApplicableOn(newValue ? newValue : "");
                  }}
                  options={applicableOnList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Applicable On"
                      value={markdownApplicableOn}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Mark Down value</div>
                <div className="view-supplier-drawer-description">{markdownValue}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Mark Down Type</div>
                <div className="view-supplier-drawer-description">{markdownType}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Mark Down Applicable On</div>
                <div className="view-supplier-drawer-description">{markdownApplicableOn}</div>
              </div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && (
              <RiseLoading MarkupGroup={showLoader} />
            )}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
