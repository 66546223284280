export default function checkBaggageFilter(item, search) {
    
    let availableBaggages = [...new Set(item?.fareGroups?.flatMap(group => 
        group.baggages?.filter(baggage => baggage.paxType === "ADT").map(baggage => baggage.checkInBag.split(',')[0].replace(/\s+/g, '').trim().toUpperCase())
    ))];

    let baggages = search.filters.baggages;
    let checkedbaggages = baggages.filter((baggage) => baggage.checked === true);    
    
    let isAvailable;
    if (checkedbaggages?.length > 0 && availableBaggages?.length > 0) {
        isAvailable = checkedbaggages.some((checkedBaggage) => 
            availableBaggages.includes(checkedBaggage.checkInBag)
        );
        return isAvailable;
    } else {
        return true;
    }
}
