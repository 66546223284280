import HTTPMethod from "http-method-enum";
import sendProtectedJSONRequest from "./sendProtectedJSONRequest";
import axios from "axios";
import { checkIfUserStaff } from "../services/AuthService";
import logFrontEndData from "../utils/logFrontEndData";

export default function validateFlights(
  traceId,
  clientId,
  additionalClientmarkup,
  purchaseIds,
  currentpage,
  currentaction,
) {

  const isStaff = checkIfUserStaff();
  const headers = {
    "Content-Type": "application/json",
  };

  if (isStaff === true) {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1/B2B",
        JSON.stringify({
          FlightRevalidationRq: {
            traceId: traceId,
            purchaseIds: purchaseIds,
            additionalClientmarkup: additionalClientmarkup,
          },

          clientId: clientId,
        }),
        { headers }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {

        const payload = {
          FlightRevalidationRq: {
            traceId: traceId,
            purchaseIds: purchaseIds,
            additionalClientmarkup: additionalClientmarkup,
          },
          clientId: clientId,
        }

        const logData = {
          traceid: traceId,
          clientUser: clientId,
          currentpage: currentpage,
          currentaction: currentaction,
          ApiUrl: process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1/B2B",
          errorCode: error.response?.status,
          errorDescription: error.response?.statusText,
          ErrorMessage: JSON.stringify(error),
          Payload: JSON.stringify(payload),
        }
        logFrontEndData(logData);        
      });
  } else {
    return axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1",
        JSON.stringify({
          traceId: traceId,
          purchaseIds: purchaseIds,
          additionalClientmarkup: additionalClientmarkup,
        }),
        { headers }
      )
      .then((response) => {
        if (typeof response.body === "string") {
          return JSON.parse(response.data);
        } else {
          return response.data;
        }
      })
      .catch((error) => {

        const payload = {
          traceId: traceId,
          purchaseIds: purchaseIds,
          additionalClientmarkup: additionalClientmarkup,
        }

        const logData = {
          traceid: traceId,
          clientUser: clientId,
          currentpage: currentpage,
          currentaction: currentaction,
          ApiUrl: process.env.REACT_APP_CORE_URL + "Flights/Revalidation/v1",
          errorCode: error.response?.status,
          errorDescription: error.response?.statusText,
          ErrorMessage: JSON.stringify(error),
          Payload: JSON.stringify(payload),
        }
        logFrontEndData(logData);
     
      });
  }
}
