import React, { useCallback, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselCalender.css"; // Custom CSS file
import moment from "moment";
import CurrencyCodeComponent from "../../providers/CurrencyCode";

const CarouselCalendar = ({
  fareCalendar,
  search,
  setSearch,
  isLoading,
  fetchCalenderResults,
  currencyCode
}) => {
  const sliderRef = React.useRef(null);

  // const [lowFare, setLowFare] = useState("");
  // const [highFare, setHighFare] = useState("");

  const numericFare = fareCalendar
    .map((item) =>
      typeof item.lowestFare === "number"
        ? item.lowestFare
        : parseInt(item?.lowestFare?.replace(/[^0-9]/g, ""))
    )
    .filter((price) => !isNaN(price) && price !== 0);

  const lowestFare = Math.min(...numericFare);
  const highestFare = Math.max(...numericFare);

  const selectedDate = new Date(search.originDestinations[0].departureDateTime);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to remove time portion

  const startDate = new Date(selectedDate);
  startDate.setDate(startDate.getDate() - 15);

  // Ensure startDate is not before today
  if (startDate < today) {
    startDate.setTime(today.getTime());
  }

  const endDate = new Date(selectedDate);
  endDate.setDate(endDate.getDate() + 15);

  // Include only dates within the range, but exclude past dates
  const filteredFareCalendar = fareCalendar.filter((item) => {
    const itemDate = new Date(item.departureDateTime);
    return itemDate >= startDate && itemDate <= endDate;
  });

  // Add fallback for any missing dates in the range
  const fullRange = Array.from({
    length: (endDate - startDate) / (1000 * 60 * 60 * 24) + 1,
  }).map((_, i) => {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);

    // Find if there is a matching fare data for this date
    const matchingItem = fareCalendar.find(
      (item) =>
        new Date(item.departureDateTime).toDateString() ===
        currentDate.toDateString()
    );

    return {
      departureDateTime: currentDate.toISOString(),
      lowestFare: matchingItem ? matchingItem.lowestFare : "View", // Use value if available, otherwise default to "View"
    };
  });

  // Merge the fareCalendar data into the full range
  const mergedFareCalendar = fullRange.map((date) => {
    const matchingItem = filteredFareCalendar.find(
      (item) =>
        new Date(item.departureDateTime).toDateString() ===
        new Date(date.departureDateTime).toDateString()
    );
    return matchingItem || date; // Use matching fare data if available, or fallback
  });

  const selectedDateIndex = mergedFareCalendar.findIndex(
    (item) =>
      new Date(item.departureDateTime).toDateString() ===
      new Date(search.originDestinations[0].departureDateTime).toDateString()
  );

  // Adjust the initial slide so that the selected date is centered
  const visibleSlides = 7; // Number of slides visible at once
  const centerIndex = Math.floor(visibleSlides / 2); // Center index of the visible slides
  let initialSlide = Math.max(0, selectedDateIndex - centerIndex);

  // If the selected date is near the start, set it to the start of the slider
  if (selectedDateIndex < centerIndex) {
    initialSlide = 0;
  }

  // If the selected date is near the end, set it to the last available slide
  if (selectedDateIndex + centerIndex >= mergedFareCalendar.length) {
    initialSlide = mergedFareCalendar.length - visibleSlides;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: visibleSlides,
   // slidesToScroll: visibleSlides,
   slidesToScroll: 1,
    arrows: false,
    initialSlide: initialSlide, // Dynamically calculated
  };

  //single value
  const fareOccurrences = numericFare.reduce((acc, fare) => {
    acc[fare] = (acc[fare] || 0) + 1;
    return acc;
  }, {});

  // Find the fare that appears only once
  const singleValue = parseFloat(
    Object.keys(fareOccurrences).find((key) => fareOccurrences[key] === 1)
  );

  const handleClickDepartureDateTime = useCallback(
    (newDate) => {
      setSearch((prevState) => {
        const updatedSearch = { ...prevState };

        if (updatedSearch.type !== "Multicity") {
          updatedSearch.originDestinations[0] = {
            ...updatedSearch.originDestinations[0],
            departureDateTime: new Date(newDate),
          };
        }

        return updatedSearch;
      });
      fetchCalenderResults();
    },
    [search]
  );

  return (
    <div className="carousel-calendar">
      <div className="carousel-wrapper">
        <button
          className="prev-btn"
          onClick={() => sliderRef.current.slickPrev()}
          style={{ border: "none" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#4a4f56"
          >
            <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
          </svg>
        </button>
        <div className="slider-container">
          <Slider ref={sliderRef} {...settings}>
            {mergedFareCalendar.map((item, index) => {
              const priceValue =
                typeof item.lowestFare === "number"
                  ? item.lowestFare
                  : parseFloat(item?.lowestFare?.replace(/[^0-9.]/g, ""));

              const priceClass =
                item.lowestFare === "View" || priceValue === 0
                  ? "grey" // Unavailable fare
                  : priceValue === highestFare && priceValue !== lowestFare
                  ? "red" // Highest fare (not singleValue or lowestFare)
                  : priceValue === lowestFare && priceValue !== highestFare
                  ? "green" // Lowest fare (not singleValue or highestFare)
                  : priceValue === singleValue
                  ? "black" // Single occurrence fare (singleValue)
                  : "black"; // Default case for other conditions

              return (
                <div key={index} className="carousel-item calendar-date-box">
                  <div
                    className={`date-box ${
                      new Date(item.departureDateTime).toDateString() ===
                      new Date(
                        search.originDestinations[0].departureDateTime
                      ).toDateString()
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleClickDepartureDateTime(item.departureDateTime)
                    }
                  >
                    <p className="day">
                      {new Date(item.departureDateTime).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "short",
                          day: "numeric",
                          month: "short",
                        }
                      )}
                    </p>
                    <p className={`prices ${priceClass}`} style={{cursor:"pointer"}}>
                      {item.lowestFare === "View" || priceValue === 0 ? (
                        "View"
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <span>
                              <CurrencyCodeComponent
                                currencyCode={currencyCode}
                              />
                            </span>
                            <span>{priceValue.toFixed(2)}</span>
                          </div>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <button
          className="next-btn"
          onClick={() => sliderRef.current.slickNext()}
          style={{ border: "none" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#4a4f56"
          >
            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CarouselCalendar;
