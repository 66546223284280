import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Drawer from "@material-ui/core/Drawer";
import axios from "axios";
import RiseLoading from "../../Loader/RiseLoader";
import FoidConfigDrawer from "./FoidConfigDrawer/FoidConfigDrawer";
import FoidConfigTable from "./FoidConfigTable/FoidConfigTable";

const FoidConfiguration = () => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [showLoader, setShowLoader] = useState(false);
  

  useEffect(() => {
    setShowLoader(true);
    loadFoidConfigurations();
}, []);


  async function loadFoidConfigurations() {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL + "api/Configuration/FoidConfiguration"
      )
      .then((response) => {
        console.log('loadFoid',response.data);
        setCategories(response.data);
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  }

  const handleFoidRowClick = async (id) => {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
          "api/Configuration/FoidConfiguration/" +
          id
      )
      .then((response) => {
        console.log('asdf',response.data);
        setSelectedItem(response.data);
        setAction("view");
        setShowLoader(false);
        setDrawer(true);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };


  const toggleDrawer = (value) => (event) => {
    if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
    ) {
        return;
    }

    setDrawer(value);

};

const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
};

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setSnackbarOpen(false);
};


  return (
    <div className="search-container">
      <div className="search-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-search-container">
              <div className="search-table-container">
                <div className="search-title-add-button-container">
                  <div className="search-heading">
                    Foid Ticket Changer Configuration
                  </div>
                  <div className="search-icon-button-container">
                    {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
                    <div
                      className="add-search-button mt-0px"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New
                    </div>
                    {/* ) : (
                                    ""
                                )} */}
                  </div>
                </div>

                <FoidConfigTable
                  handleFoidRowClick={handleFoidRowClick}
                  categories={categories}
                ></FoidConfigTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <FoidConfigDrawer
           loadFoidConfigurations={loadFoidConfigurations}
           toggleDrawer={toggleDrawer}
           handleSnackbarToggle={handleSnackbarToggle}
           selectedItem={selectedItem}
           action={action}
           setAction={setAction}
          ></FoidConfigDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
      {showLoader && <RiseLoading />}
    </div>
  );
};

export default FoidConfiguration;
