//import * as React from "react";
import React, { useState, useEffect } from "react";
import "./ViewCancellation.css";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { checkIfUserStaff } from "../../services/AuthService";
import SearchIcon from "@mui/icons-material/Search";
import RiseLoading from "../Loader/RiseLoader";
import Input from "@mui/joy/Input";
import dayjs from "dayjs";
import Button from "@mui/joy/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../services/dateFormatter";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Autocomplete from "@mui/material/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";
import Clarity from "@microsoft/clarity";
import { useLocation } from "react-router";
import supplierList from "../../data/Supplier";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function ViewCancellation() {
  const location = useLocation();
  const today = dayjs();
  const [selectedRef, setSelectedRef] = useState("cancel");
  const [selecteddate, setselecteddate] = useState("");
  const [cancelViewDetails, setCancelViewDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [gfsinputValue, setgfsInputValue] = useState("");
  const [gxinputValue, setgxInputValue] = useState("");
  const [FromDate, setFromDateValue] = useState(today);
  const [ToDate, setToDateValue] = useState(today);
  const [selectedservice, setSelectedService] = useState("");
  const [clientIdvalue, setClientIdValue] = useState("");
  const [selectedviewStatus, setViewSelectedStatus] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [clientProfiles, setClientProfiles] = useState(null);
  const [pccList, setPccList] = useState([]);
  const [pccIdvalue, setPccIdValue] = useState("");
  const { t } = useTranslation();
  const [supplierIdvalue, setSupplierIdValue] = useState("");
  const [filteredPcc, setFilteredPcc] = useState([]);
  // const [vendorName, setVendorName] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendor, setVendor] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    if (cancelViewDetails) {
      const startIndex = (page - 1) * rowsPerPage;
      const endIndex = page * rowsPerPage;
      setTableData(cancelViewDetails.slice(startIndex, endIndex));
    } else {
      setTableData([]);
    }
  }, [cancelViewDetails, page]);

  useEffect(() => {
    if (checkIfUserStaff()) {
      getClients();
    }
  }, []);

  useEffect(() => {
    if (clientProfiles) {
      loadViewCancellationDetails();
    }
  }, [clientProfiles]);

  const handleViewStatusChange = (event, newValue) => {
    setViewSelectedStatus(newValue);
  };
  const handleServicesChange = (event, newValue) => {
    setSelectedService(newValue);
  };
  const handlerefNoChange = (event, newValue) => {
    setSelectedRef(newValue);
  };

  useEffect(() => {
    selectedRef === "gfs"
      ? setselecteddate("Booking_Date")
      : setselecteddate("Cancel_Date");
    setgxInputValue("");
    setgfsInputValue("");
  }, [selectedRef]);

  const viewcancel = (event) => {
    Clarity.event("View_Cancellation_Search", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    if (FromDate > ToDate) {
      alert(`${t("From date shouldn't be greater than to date")}`);
    } else {
      event.preventDefault();
      loadViewCancellationDetails();
    }
  };
  const handlegxInputChange = (e) => {
    setgxInputValue(e.target.value);
  };
  const handlegfsInputChange = (e) => {
    setgfsInputValue(e.target.value);
  };
  const handleDateChange = (event, newValue) => {
    setselecteddate(newValue);
  };

  function handleStatusClick(orderRefId, cancelRefId, cancelStatus) {
    setShowLoader(true);
    sessionStorage.setItem("orderRefId", orderRefId);
    sessionStorage.setItem("cancelRefId", cancelRefId || 0);
    window.open("/flight/ViewCancellationDetails", "_blank");
    setShowLoader(false);
  }

  async function loadViewCancellationDetails() {
    setPage(1);
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetViewCancellation",
        JSON.stringify({
          orderefno: selectedRef === "gfs" ? gfsinputValue : null,
          cancelrefno: selectedRef === "cancel" ? gxinputValue : null,
          clientId: gfsinputValue || gxinputValue ? 0 : clientIdvalue || 0,
          vendorname: gfsinputValue || gxinputValue ? "" : vendor ? vendor.id : "" || null,
          pcc: gfsinputValue || gxinputValue ? "" : pccIdvalue || "",
          fromDate: gfsinputValue || gxinputValue ? null : FromDate || null,
          toDate: gfsinputValue || gxinputValue ? null : ToDate || null,
          DateChoosen: selecteddate || null,
          status:
            gfsinputValue || gxinputValue ? null : selectedviewStatus || null,
          services:
            gfsinputValue || gxinputValue ? null : selectedservice || null,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (response?.data[0]?.cancelrefno) {
          let viewcancel = response.data;

          const sortedDetails = viewcancel.sort((a, b) => {
            const refA = parseInt(a.cancelrefno.replace(/\D/g, ""), 10);
            const refB = parseInt(b.cancelrefno.replace(/\D/g, ""), 10);
            return refB - refA;
          });
          setCancelViewDetails(sortedDetails);
          setShowLoader(false);
        } else {
          setCancelViewDetails([]);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setErrMsg(err);
        setShowLoader(false);
        console.log(err);
      });
  }

  async function getClients() {
    setShowLoader(true);
    await axios
      // .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        // console.log("clientProfiles", response);
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setClientProfiles(() => structuredClone(tempArray));
      })
      .catch(() => {
        setShowLoader(false);
      });
  }


  async function loadVendors() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        vendorArray.unshift({
          id: "all",
          name: "All",
        });
        console.log('vendorArray', vendorArray)
        setVendorList(vendorArray);
      });
  }


  async function loadPCCDetails() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig"
      );

      console.log("response", response);
      const activePccConfigs = response.data
        .map((item) => item.pccConfigs)
        .filter((pccConfig) => pccConfig.isActive === true);
      console.log("Active PCC Configs:", activePccConfigs);

      const tmpArray = activePccConfigs.map((pccConfig) => ({
        id: pccConfig.id,
        value: pccConfig.pccCode,
        vendorId: pccConfig.vendorId,
        //supplierName: pccConfig.supplierName,
        label: pccConfig.pccCode,
      }));

      console.log("Mapped PCC Configs (tmpArray):", tmpArray);

      setPccList(tmpArray);
    } catch (error) {
      console.error("Error loading PCC details:", error);
    }
  }

  useEffect(() => {
    if (checkIfUserStaff()) {
      loadPCCDetails();
      loadVendors();
    }
  }, []);

  function filterPcc(newValue) {
    setPccIdValue('');
    console.log('newValue', newValue);
    // let selectedSupplier =
    //   newValue === "Travel Fusion" ? "Travelfusion" : newValue;
    // console.log("selectedSupplier", selectedSupplier);


    if (newValue) {
      const filtered = pccList.filter(
        (pcc) => pcc.vendorId === newValue.id
      );

      console.log("Filtered PCCs:", filtered);

      setFilteredPcc(filtered);
    } else {
      setFilteredPcc([]);

    }
  }

  const handleCalendarOpen = () => {
    document.body.classList.add('calendar-open');
  };

  const handleCalendarClose = () => {
    document.body.classList.remove('calendar-open');
  };

  console.log("pccList", pccList);
  console.log("clientProfiles", clientProfiles);
  console.log("pccIdvalue", pccIdvalue);
  console.log("supplierIdvalue", supplierIdvalue);

  return (
    <div>
      <div className="calcellationSelectionParentContainer">
        <h5 style={{ textAlign: "left", padding: "30px", color: "#a0155a" }}>
          {t("View Cancellation")}
        </h5>
        <div className="calcellationSelectionContainer">
          <div className="view-cancel">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Select
                  defaultValue={"cancel"}
                  variant="outlined"
                  onChange={handlerefNoChange}
                >
                  <Option value="gfs">{t("GFS RefNo")}</Option>
                  <Option value="cancel">{t("Cancellation RefNo")}</Option>
                </Select>
              </Grid>

              <Grid item xs={3}>
                {selectedRef === "gfs" ? (
                  <Input
                    placeholder={t("Search By Ref No")}
                    size="md"
                    style={{
                      "&:focus, &:hover": {},
                    }}
                    value={gfsinputValue}
                    onChange={handlegfsInputChange}
                  />
                ) : (
                  <Input
                    placeholder={t("Free Text Search")}
                    size="md"
                    style={{
                      "&:focus, &:hover": {
                        borderColor: "#a0155a",
                      },
                    }}
                    value={gxinputValue}
                    onChange={handlegxInputChange}
                  />
                )}
              </Grid>

              {checkIfUserStaff() ? (
                <Grid item xs={3}>
                  {/* <Input
                    placeholder="Client Id"
                    size="md"
                    style={{
                      "&:focus, &:hover": {
                        borderColor: "#a0155a",
                      },
                    }}
                    value={clientIdvalue}
                    onChange={handleClientInputChange}
                  /> */}
                  <ValidatorForm onError={(errors) => console.log(errors)}>
                    <Autocomplete
                      id="Client select"
                      value={
                        clientProfiles !== null
                          ? clientProfiles.find((v) => v.id === clientIdvalue)
                          : null
                      }
                      onChange={(event, newValue) => {
                        setClientIdValue(newValue ? newValue.id : "");
                      }}
                      options={clientProfiles || []}
                      getOptionLabel={(option) =>
                        `${option.clientName} (${option.id})`
                      }
                      filterOptions={(options, { inputValue }) =>
                        options.filter(
                          (option) =>
                            option.clientName
                              .toLowerCase()
                              .includes(inputValue.toLowerCase().trim()) ||
                            option.id.toString().includes(inputValue.trim())
                        )
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          className="rounded"
                          label={t("Select Client")}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          validators={["required"]}
                          errorMessages={[t("Client is required")]}
                        />
                      )}
                    />
                  </ValidatorForm>
                </Grid>
              ) : null}

              {/* Vendor Start: */}
              {checkIfUserStaff() ? (
                <Grid item xs={3}>
                  <ValidatorForm onError={(errors) => console.log(errors)}>
                    <Autocomplete
                      required
                      id="cabin_select"
                      value={vendor} // value now holds a single vendor object or null
                      onChange={(event, newValue) => {
                        setVendor(newValue); // Set vendor to the selected object
                        filterPcc(newValue ? newValue : null);
                        setPccIdValue('');
                      }}
                      options={vendorList}
                      autoHighlight
                      getOptionLabel={(option) => option.name || ""} // Get the name of the vendor as a string
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          label="Vendor"
                          value={vendor ? vendor.name : ""} // Display vendor's name as a string
                          variant="outlined"
                          color="secondary"
                          size="small"
                          inputProps={{
                            ...params.inputProps,

                            required:
                              vendor && vendor.name && vendor.name.length === 0, // Check if vendor name is empty
                          }}
                        //required={true}
                        />
                      )}
                      style={{ maxWidth: "34vw" }}
                    />
                  </ValidatorForm>
                </Grid>
              ) : null}

              {checkIfUserStaff() ? (
                <Grid item xs={3}>
                  <ValidatorForm onError={(errors) => console.log(errors)}>
                    <Autocomplete
                      // style={{ width: "200px", backgroundColor: "#d3d3d317" }}
                      id="Pseudo_Code"
                      value={pccIdvalue ? pccList.find((v) => v.value === pccIdvalue) : null} // Use pccIdvalue as controlled value
                      onChange={(event, newValue) => {
                        setPccIdValue(newValue ? newValue.value : "");
                      }}
                      options={filteredPcc || []}
                      //options={pccList || []}
                      getOptionLabel={(option) => `${option.value}`}
                      filterOptions={(options, { inputValue }) =>
                        options.filter(
                          (option) =>
                            option.value
                              .toLowerCase()
                              .includes(inputValue.toLowerCase().trim()) ||
                            option.value.toString().includes(inputValue.trim())
                        )
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}

                          className="rounded"
                          label={t("Select Pcc")}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          validators={["required"]}
                          errorMessages={[t("Pcc is required")]}
                        />
                      )}
                    />
                  </ValidatorForm>
                </Grid>
              ) : null}

              <Grid item xs={3}>
                <Select defaultValue="None" onChange={handleViewStatusChange}>
                  <Option value="None">{t("Status")}</Option>
                  <Option value="Cancel_Requested">
                    {t("Cancel Requested")}{" "}
                  </Option>
                  <Option value="Cancelled">{t("Cancelled")}</Option>
                  {checkIfUserStaff() && (
                    <Option value="Refund_Initiated">
                      {t("Refund Initiated")}
                    </Option>
                  )}
                  {checkIfUserStaff() && (
                    <Option value="Voided">{t("Voided")}</Option>
                  )}
                  {checkIfUserStaff() && (
                    <Option value="Refunded">{t("Refunded")}</Option>
                  )}
                  {checkIfUserStaff() && (
                    <Option value="Void_Refunded">{t("Void Refunded")}</Option>
                  )}
                  {checkIfUserStaff() && (
                    <Option value="Void_Requested">
                      {t("Void Requested")}
                    </Option>
                  )}
                  <Option value="Cancel_Rejected">
                    {" "}
                    {t("Cancel_Rejected")}
                  </Option>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Select
                  defaultValue="All Services"
                  onChange={handleServicesChange}
                >
                  <Option value="All Services">{t("All Services")}</Option>
                  <Option value="Flight">{t("Flight")}</Option>
                </Select>
              </Grid>

              <Grid item xs={3}>
                <Select
                  value={selecteddate}
                  // defaultValue={"Cancel_Date"}
                  variant="outlined"
                  onChange={handleDateChange}
                  placeholder="Date Type "
                >
                  <Option value="Booking_Date">{t("Booking Date")}</Option>
                  <Option value="Cancel_Date">{t("Cancel Date")}</Option>
                </Select>
              </Grid>

              {selecteddate === "Booking_Date" ? (
                <>
                  <Grid item xs={3}>
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setFromDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          maxDate={today}
                          value={FromDate ? dayjs(FromDate) : dayjs()}
                          label={t("From Date")}
                          onOpen={handleCalendarOpen}
                          onClose={handleCalendarClose}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="add-search-if-item flex-1-input margin-right-16 ms-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setToDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          minDate={dayjs(FromDate)}
                          maxDate={today}
                          value={ToDate ? dayjs(ToDate) : dayjs()}
                          label={t("To Date")}
                          onOpen={handleCalendarOpen}
                          onClose={handleCalendarClose}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={3}>
                    <div className="add-search-if-item flex-1-input margin-right-16">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setFromDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          maxDate={today}
                          value={FromDate ? dayjs(FromDate) : dayjs()}
                          label={t("From Date")}
                          onOpen={handleCalendarOpen}
                          onClose={handleCalendarClose}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="add-search-if-item flex-1-input margin-right-16 ms-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(newValue) => {
                            setToDateValue(newValue.$d ? newValue.$d : null);
                          }}
                          defaultValue={today}
                          disableFuture
                          format="DD/MM/YYYY"
                          minDate={dayjs(FromDate)}
                          maxDate={today}
                          value={ToDate ? dayjs(ToDate) : dayjs()}
                          label={t("To Date")}
                          onOpen={handleCalendarOpen}
                          onClose={handleCalendarClose}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                </>
              )}

              {/* <div colSpan="4" className="padLR2vw padTB1vw">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker defaultValue={today} />
              </LocalizationProvider>
              </div> */}

              <Grid item xs={3}>
                <Button
                  variant="contained"
                  style={{
                    background: "#a0155a",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onClick={viewcancel}
                >
                  {t("Search")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>

        {showLoader ? (
          <RiseLoading isviewBooking={showLoader} />
        ) : (
          <div className="cancellationViewContainer">
            {tableData && tableData[0] ? (
              <>
                <h5 style={{ color: "#a0155a", margin: "10px" }}>
                  {t("Cancellation Details List")}
                </h5>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {checkIfUserStaff() ? (
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontSize: "18px", color: "green" }}
                            >
                              {t("Client ID")}
                            </Typography>
                          </TableCell>
                        ) : null}
                        {checkIfUserStaff() && (
                          <TableCell>
                            <Typography
                              style={{ fontSize: "16px", color: "black" }}
                            >
                              {t("Client Name")}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Cancel Request Date")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Booking RefNo")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("User")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Cancel RefNo")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Services")}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Airline")}
                          </Typography>
                        </TableCell>
                        {checkIfUserStaff() && (
                          <TableCell>
                            <Typography
                              style={{ fontSize: "16px", color: "black" }}
                            >
                              {t("Vendor Name")}
                            </Typography>
                          </TableCell>
                        )}
                        {checkIfUserStaff() && (
                          <TableCell>
                            <Typography
                              style={{ fontSize: "16px", color: "black" }}
                            >
                              {t("PCC")}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Passenger Name")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{ fontSize: "16px", color: "black" }}
                          >
                            {t("Booking Status")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(tableData) &&
                        tableData.map((canceldetail, index) => (
                          <TableRow key={index}>
                            {checkIfUserStaff() && (
                              <TableCell>{canceldetail.clientid}</TableCell>
                            )}
                            {checkIfUserStaff() && (
                              <TableCell>{canceldetail.clientname}</TableCell>
                            )}
                            <TableCell>
                              {formatDate(canceldetail.cancelreqdate)}{" "}
                            </TableCell>
                            <TableCell>{canceldetail.orderrefid}</TableCell>
                            <TableCell>{canceldetail.user}</TableCell>
                            <TableCell>{canceldetail.cancelrefno}</TableCell>
                            <TableCell>{canceldetail.services}</TableCell>
                            <TableCell>{canceldetail.airline}</TableCell>
                            {checkIfUserStaff() && (
                              <TableCell>
                                {canceldetail.vendorname}
                              </TableCell>
                            )}
                            {checkIfUserStaff() && (
                              <TableCell>{canceldetail.pcc}</TableCell>
                            )}
                            <TableCell>{canceldetail.paxname}</TableCell>
                            <TableCell
                              onClick={() =>
                                handleStatusClick(
                                  canceldetail.orderrefid,
                                  canceldetail.cancelrefno,
                                  !checkIfUserStaff() &&
                                    canceldetail.status === "Refund_Initiated"
                                    ? t("Cancelled")
                                    : !checkIfUserStaff() &&
                                      canceldetail.status === "Void_Refunded"
                                      ? t("Refunded")
                                      : !checkIfUserStaff() &&
                                        canceldetail.status === "Voided"
                                        ? t("Cancelled")
                                        : t(canceldetail.status)
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#a0155a",
                                transition: "color 0.3s ease",
                              }}
                              className="change-hover-style"
                            >
                              {canceldetail.cancelrefno.includes("GX")
                                ? !checkIfUserStaff() &&
                                  canceldetail.status === "Refund_Initiated"
                                  ? t("Cancelled")
                                  : !checkIfUserStaff() &&
                                    canceldetail.status === "Void_Refunded"
                                    ? t("Refunded")
                                    : !checkIfUserStaff() &&
                                      canceldetail.status === "Voided"
                                      ? t("Cancelled")
                                      : t(canceldetail.status)
                                : t(canceldetail.status)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  count={cancelViewDetails?.length}
                  rowsPerPage="25"
                  page={page - 1}
                  onChangePage={(event, newPage) =>
                    handleChangePage(event, newPage, page, rowsPerPage)
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                    color: "grey",
                  }}
                >
                  <SearchIcon fontSize="large"></SearchIcon>
                </div>
                <p className="text-center">
                  {t("No result found for your search")}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
