import React, { useState, useEffect } from "react";
import "./SupplierCommissionBlockTable.css";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Alert from "@material-ui/lab/Alert";
import Box from "@mui/material/Box";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import cabinClassList from "../../../../data/CabinClass";

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const handlePageChange = (event, newPage) => onPageChange(event, newPage);

  return (
    <>
      <IconButton
        onClick={(e) => handlePageChange(e, 0)}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, page - 1)}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(e) => handlePageChange(e, Math.ceil(count / rowsPerPage) - 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

function SupplierCommissionBlockTable({
  openDrawer,
  setAction,
  commissionBlocks,
  name,
  setPage,
  page,
  pccList,
  loadCommissionBlocks,
  filters,
  filteredCommissionBlocks,
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    loadCommissionBlocks();
  }, [page, rowsPerPage]);

  const handlePageChange = (event, newPage) => setPage(newPage + 1);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderTableRows = (blocksList) =>
    blocksList.map((blocks) => (
      <StyledTableRow
        key={JSON.stringify(blocks.supplierCommissionBlocks)}
        onClick={() => {
          setAction("view");
          openDrawer(blocks.supplierCommissionBlocks);
        }}
      >
        <StyledTableCell>
          <div className="table-cell-title">{blocks.supplierCommissionBlocks.id}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="table-cell-title">{blocks.supplierCommissionBlocks.pccCode}</div>
          <div className="table-cell-description">
            {pccList
              .filter((id) =>
                id.value.startsWith(blocks.supplierCommissionBlocks.pccCode + ",")
              )
              .map(({ label }) => label?.match(/\(([^)]+)\)/)?.[1] || "")
              .join(", ")}
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="table-cell-title">{blocks.supplierCommissionBlocks.airline}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="table-cell-title">{blocks.supplierCommissionBlocks.fareType}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="table-cell-title">
            {cabinClassList &&
              blocks.supplierCommissionBlocks.cabinClass
                .split(",")
                .map((item) =>
                  cabinClassList.find((id) => id.value === item)?.label || ""
                )
                .join(", ")}
          </div>
        </StyledTableCell>
        <StyledTableCell align="center">
          <div className="table-cell-title pointer">
            {blocks.supplierCommissionBlocks.travelType}
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <div className='table-cell-title'>
            {blocks.supplierCommissionBlocks.isActive ? (
              <Chip label='Active' color='primary' size='small' style={{ backgroundColor: "green" }} />
            ) : (
              <Chip label='In Active' color='primary' style={{ backgroundColor: "red" }} />
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="table-cell-description">
            Created by: <span>{blocks.createdBy}</span>
          </div>
          <div className="table-cell-description">
            Created on: {blocks.supplierCommissionBlocks.created.replace("T", " , ")}
          </div>
          <div className="table-cell-description">
            Last Modified by: <span>{blocks.modefiedBy}</span>
          </div>
          <div className="table-cell-description">
            Last Modified on: {blocks.supplierCommissionBlocks.modified.replace("T", " , ")}
          </div>
        </StyledTableCell>
      </StyledTableRow>
    ));

  const getVisibleBlocks = () => {
    if (!commissionBlocks) return null;
    if (filteredCommissionBlocks?.length > 0) return filteredCommissionBlocks;
    if (filters.reset) return commissionBlocks;
    return null;
  };

  const visibleBlocks = getVisibleBlocks();

  return (
    <div className="supplier-listing">
      {
        commissionBlocks && commissionBlocks.length === 0 ? (
          <Alert severity="error">No record found.</Alert>
        ) : (
          <>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier Ref ID</TableCell>
                    <TableCell>Pseudo Code</TableCell>
                    <TableCell>Airline</TableCell>
                    <TableCell>FareType</TableCell>
                    <TableCell>Cabin Class</TableCell>
                    <TableCell align="center">Travel Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleBlocks ? (
                    renderTableRows(
                      visibleBlocks.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Results
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={(filteredCommissionBlocks && filteredCommissionBlocks?.length) || (commissionBlocks && commissionBlocks.length)}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
            />
          </>
        )
      }
    </div>
  );
}

export default SupplierCommissionBlockTable;
