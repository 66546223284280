import React, { useState, useEffect } from "react";
import "./ManageUser.css";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import Drawer from "@material-ui/core/Drawer";
import SearchUser from "./SearchUser/SearchUser";
import UserTable from "./UserTable/UserTable";
import UserDrawer from "./UserDrawer/UserDrawer";
import AddUser from "./AddUser/AddUser";
import { checkIfUserStaff, getCurrentClientID } from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ManageUser() {
  let { id } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [addUserdrawer, setaddUserdrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [action, setAction] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [userProfiles, setuserProfiles] = useState(null);
  const [page, setPage] = React.useState(0);
  const [allRoles, setallRoles] = useState([]);
  const [alertText, setalertText] = useState("");
  const [mode, setMode] = useState("");
  const [userRole, setUserRole] = useState("");
  const [subUserAlert, setSubUserAlert] = useState("");
  const [subUserSnackbarOpen, setSubUserSnackbarOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {

    if (getCurrentClientID() !== '0') {
      if (id !== getCurrentClientID()) {
        id = getCurrentClientID();
      }
    }
    checkIfUserStaff() && getAllRoles()
  }, [mode]);


  async function getAllRoles() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/Role")
      .then((response) => {
        setallRoles(response.data);
      });
  }

  async function getClients() {
    let filter = "ClinetId==" + id;
    setuserProfiles();
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "ids/User?filters=" + filter)
      .then((response) => {
        response.data.map((item) => {
          if (item.emailConfirmed) {
            item.status = "yes";
          } else {
            item.status = "no";
          }
        });
        setuserProfiles(response.data);
      });
  }


  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);
    setDrawer(false);
    setSnackbarOpen(true);
    getClients();
  };

  const handleSubUserSnackbarToggle = (text, mode) => {
    setSubUserAlert(text);
    setMode(mode);
    setSubUserSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
  };

  const toggleAddUserDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setaddUserdrawer(value);
    setAction("view")
  };

  const openAddUserDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setaddUserdrawer(true);
  };

  return (
    <div className="configuration-container">
      <div className="configuration-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-configuration-container">
              <div className="configuration-table-container">
                <div className="configuration-title-add-button-container">
                  <div className="configuration-heading">
                    {t("Manage User Profiles")}
                  </div>

                  <div className="search-icon-button-container">
                    <div
                      style={{ cursor: "pointer" }}
                      className="add-configuration-button mt-0px"
                      onClick={() => {
                        Clarity.event("Add_User", location.pathname);
                        const users = JSON.parse(localStorage.getItem("user"));
                        window.clarity("identify", users.clientref_id, users);
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      {t("Add New User")}
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="configuration-card-container">
                    <SearchUser></SearchUser>
                  </div>
                </Collapse>
                <UserTable
                  openAddUserDrawer={openAddUserDrawer}
                  setAction={setAction}
                  userProfiles={userProfiles}
                  setPage={setPage}
                  page={page}
                  getClients={getClients}
                ></UserTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="">
          <UserDrawer
            toggleDrawer={toggleDrawer}
            handleSnackbarToggle={handleSnackbarToggle}
            getClients={getClients}
            id={id}
            setDrawer={setDrawer}
            action={action}
            setAction={setAction}
          ></UserDrawer>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={addUserdrawer}
        onClose={toggleAddUserDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <AddUser
            toggleAddUserDrawer={toggleAddUserDrawer}
            action={action}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            handleSubUserSnackbarToggle={handleSubUserSnackbarToggle}
            getClients={getClients}
            allRoles={allRoles}
            clientIdForClientStaff={id}
            setaddUserdrawer={setaddUserdrawer}
          ></AddUser>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == "error" ? (
          <Alert onClose={handleSnackBarClose} severity="error">
            {alertText}
          </Alert>
        ) : (
          <Alert onClose={handleSnackBarClose} severity="success">
            {alertText}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

export default ManageUser;
