import React from 'react';
import { Carousel } from 'react-bootstrap';
// import slider1 from "../assets/slider1.png";
import slider2 from "../assets/slider2.png";
import slider3 from "../assets/slider3.png";
import slider4 from "../assets/slider4.webp";
import slider5 from "../assets/slider5.webp";
import slider6 from "../assets/slider6.webp";

const CarouselComponent = () => {
    return (
        // interval={2000} 
        <Carousel pause="hover" interval={2000000} className='banner-carousel'>
            <Carousel.Item>
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={slider2}
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={slider3}
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={slider4}
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={slider5}
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={slider6}
                />
            </Carousel.Item>
        </Carousel>
    );
};

export default CarouselComponent;
