import React, { useEffect, useState } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Divider } from "@material-ui/core";
import logo from "../../assets/getfares.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../Redux/AuthSlice";
import Avatar from "@material-ui/core/Avatar";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import HotelIcon from "@material-ui/icons/Hotel";
import GroupsIcon from '@mui/icons-material/Groups';
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../services/AuthService";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./Header.css";
import Popover from "@material-ui/core/Popover";
import { Megamenu } from "./MegaMenu";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import axios from "axios";
import getClientBalance from "../../functions/getClientBalance";
import TrainIcon from "@mui/icons-material/Train";
import DMCLogo from "../../assets/DMC.png";
import CircularProgress from '@mui/material/CircularProgress';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@mui/material";
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router";



function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    height: "4em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4em",
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    marginRight: "10px",
  },
  tabContainer: {
    //marginLeft: "auto",
    marginRight: "25px",
    alignItems: "center",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "10px",
  },
  scaddDrawer: {
    width: 350,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  scaddDrawerPaper: {
    width: 350,
    paddingTop: 64,
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#e4e4e4",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  //cart related Css
  cart: {
    padding: "10px",
  },
  CartType: {
    display: "flex",
    fontSize: "1em",
  },
}));
function Header({ user, setUser }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clientId, setClientId] = useState(0);
  const [clientBalance, setclientBalance] = useState(null);
  const [clientCurrencyCode, setclientCurrencyCode] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [holdBookingCount, setHoldBookingCount] = useState("");
  const [pcc, setPCC] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [copiedSnackBarOpen, setCopiedSnackBarOpen] = useState(false);
  const [copiedSnackBarContent, setCopiedSnackBarContent] = useState("");
  const { t } = useTranslation();
  const traceId = useSelector((state) => state.traceIds.value);
  const location = useLocation();
  const [restanchorEl, restsetAnchorEl] = React.useState(null);

  const handleClickRest = (event) => {
    restsetAnchorEl(event.currentTarget);
  };

  const handleCloseRest = () => {
    restsetAnchorEl(null);
  };

  const logoutfn = () => {
    Clarity.event("Logout_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    setUser("");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("user");
    localStorage.removeItem("lastSearch");
    dispatch(logout());
    history.push("/SignIn");
  };
  const getHoldBookingsCount = () => {
    const body = {
      q: "",
      top: 8,
      skip: 0,
      filters: [
        {
          field: "Status",
          value: "Booking_On_Hold"
        },
        {
          field: "DateFrom",
          value: ""
        },
        {
          field: "DateTo",
          value: ""
        }
      ]
    };
    const headers = { "Content-Type": "application/json" };
    axios
      .post(
        process.env.REACT_APP_CORE_URL + "api/Configuration/OrderQueue",
        body,
        { headers }
      )
      .then((response) => {
        setHoldBookingCount(response.data.count)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    setUser(token);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      setClientId(user["clientref_id"]);
      setUserRole(user["role"])
      if (checkIfUserStaff() === false) {
        let getClientId = user["clientref_id"];
        setTimeout(async () => {
          let resp = await getClientBalance(getClientId);
          setclientBalance(resp);
          getClients(getClientId);
        }, 3000);
      }
    }
    return () => { };
  }, []);

  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const handleAdminClick = (event) => {
    if (!open) {
      setAdminAnchorEl(event.currentTarget);
    } else {
      handleAdminClose();
    }
  };

  const handleAdminClose = () => {
    setAdminAnchorEl(null);
  };

  const open = Boolean(adminAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const getFullName = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["full_name"] && user["full_name"].length > 0
      ? user["full_name"]
      : "?";
  };
  const getOldClientId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return (user['oldClientId'] && user['oldClientId'] !== 0);
  }
  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["role"];
  };

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
    if (!checkIfUserStaff()) {
      getHoldBookingsCount();
    }
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const profileOpen = Boolean(profileAnchorEl);

  const goToProfile = () => {
    Clarity.event("View_Profile_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/ManageProfile");
  };

  const goToAccessManagement = () => {
    Clarity.event("Access_Management_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/ClientRoleRights");
  };

  const goToManageUsers = () => {
    Clarity.event("Manage_Users_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/ManageUser/" + clientId);
  };

  const goToOldBookings = () => {
    setProfileAnchorEl(null);
    window.open(
      "https://getfares.com/transaction/clientwise_all_bookings/" + clientId,
      "_blank"
    );
  };
  const goToManageBookings = () => {
    Clarity.event("Manage_Bookings_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    if (window.location.pathname === '/QueueTable') {
      window.location.reload();
    } else {
      history.push("/QueueTable");
    }
  };
  const goToTravelSchedule = () => {
    Clarity.event("Travel_Schedule_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    if (window.location.pathname === '/TravelSchedule') {
      window.location.reload();
    } else {
      history.push("/TravelSchedule");
    }
  };
  const goToQueueTablewithFilter = () => {
    Clarity.event("Hold_Bookings_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    if (window.location.pathname === '/QueueTable') {
      window.location.reload();
      history.push({
        pathname: "/QueueTable",
        state: { data: "BookingOnHold" },
      });
    } else {
      history.push({
        pathname: "/QueueTable",
        state: { data: "BookingOnHold" },
      });
    }
  };


  const goToPayment = () => {
    Clarity.event("Instant_Bank_Transfer_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/AutoImps");
  };
  const goToMyWallet = () => {
    Clarity.event("My_Wallet_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/Recharge");
  };
  const goToManageTransactions = () => {
    Clarity.event("Manage_Transaction_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/DebitTransactions/" + clientId);
  };
  const goToViewCancellation = () => {
    Clarity.event("View_Cancellation_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/flight/ViewCancellation");
  };
  const goToReissue = () => {
    Clarity.event("View_Reissue_Menu_Click", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setProfileAnchorEl(null);
    history.push("/ViewReissue");
  };
  const navigateToHome = () => {
    const userRole = getCurrentRole();
    if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
      history.push("/flight");
    }
    else {
      history.push("/Unauthorized");
    }
  };
  const navigateToRail = () => {
    if ((isClientUserHasPermission("IsSearch", "RailbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
      window.open(`${window.location.origin}/rail`, "_parent");
    }
    else {
      history.push("/Unauthorized");
    }
  };
  const navigateToDMC = () => {
    window.open("https://www.get2italy.com", "_blank");
  };
  const navigateToImportPNR = () => {
    history.push({
      pathname: "/ImportPNR",
      state: { supplier: supplierName, pcc: pcc },
    });
  };

  async function getClients(getClientId) {
    let filter = "id==" + getClientId;
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        setClientName(response?.data[0]?.clientpforile.clientName);
        setclientCurrencyCode(response?.data[0]?.clientpforile.currencyCode);
        setSupplierName(response?.data[0]?.clientpforile.supplierNameType);
        setPCC(response?.data[0]?.clientpforile.pcc);
      });
  }


  const handleExternalService = (service) => {

    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem("user"));

    let url;
    if (service === 'Hotel') {
      url = 'https://app-preprod.jaltix.com/ardor/ATRoute'
    } else if (service === 'Group_Quote') {
      url = 'https://gbt.akbaroffshore.com/sso'
    }

    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    if (service === 'Group_Quote') {
      form.target = "_blank";
    }

    form.style.display = "none";

    const input1 = document.createElement("input");
    input1.type = "hidden";
    input1.name = "clientId";
    input1.value = `${user["clientref_id"]}`;

    form.appendChild(input1);

    const input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "token";
    input2.value = `${token}`;

    form.appendChild(input2);

    if (service === 'Hotel') {
      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "source";
      input3.value = "Booking";

      form.appendChild(input3);
    }

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);

  };

  const handleLogoClick = () => {
    if (window.location.pathname === '/flight/search-results-new' || window.location.pathname === '/flight/review-booking') {
      setCopiedSnackBarOpen(true);
      if (traceId) {
        navigator.clipboard.writeText(traceId);
        setCopiedSnackBarContent("Copied");
      } else {
        navigator.clipboard.writeText("");
        setCopiedSnackBarContent("No TraceId to copy");
      }
    }
    else {
      navigator.clipboard.writeText("");
    }
  };

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" color="white" className={classes.appbar}>
          <Toolbar disableGutters className="justify-content-between">
            <img
              alt="company logo"
              style={{
                width: "160px",
                marginLeft: "24px",
                marginRight: "48px",
                cursor: (window.location.pathname === '/flight/search-results-new' || window.location.pathname === '/flight/review-booking') ? "Pointer" : ""
              }}
              onClick={handleLogoClick}
              src={logo}
            />
            {user ? (
              <React.Fragment>
                <div className="header-nav-items-container">
                  <div
                    className="header-nav-item-container"
                    onClick={navigateToHome}
                  >
                    <div>
                      <FlightTakeoffIcon className="nav-item-icon" />
                    </div>
                    <div className="nav-item-text">{t("Flights")}</div>
                  </div>
                  <div
                    className="header-nav-item-container"
                    onClick={navigateToRail}
                  >
                    <div>
                      <TrainIcon className="nav-item-icon" />
                    </div>
                    <div className="nav-item-text">{t("Rail")}</div>
                  </div>
                  <div
                    className="header-nav-item-container"
                    onClick={navigateToDMC}
                  >
                    <div>
                      <img src={DMCLogo} className="nav-item-icon" alt="" height={24}/>
                    </div>
                    <div className="nav-item-text">{t("DMC")}</div>                   
                  </div>
                  {!checkIfUserStaff() &&
                    <div
                      className="header-nav-item-container"
                      // onClick={navigateToHome}
                      onClick={() => {
                        Clarity.event("Flights_Home_HotelsMenu",location.pathname);
                        console.log('Flights_Home_HotelsMenu',location.pathname);
                        handleExternalService('Hotel')
                      }}
                    >
                      <div>
                        <HotelIcon className="nav-item-icon" />
                      </div>
                      <div className="nav-item-text">{t("Hotels")}</div>                    
                    </div>
                  }
                  {!checkIfUserStaff() &&
                    <div
                      className="header-nav-item-container"
                      // onClick={navigateToHome}
                      onClick={() => {
                        handleExternalService('Group_Quote')
                      }}
                    >
                      <div>
                        <GroupsIcon className="nav-item-icon" />
                      </div>
                      <div className="nav-item-text">Group Quote</div>
                    </div>
                  }

                  {!checkIfUserStaff() && pcc && (
                    <div
                      className="header-nav-item-container"
                      onClick={navigateToImportPNR}
                    >
                      <div>
                        <AirplaneTicketIcon className="nav-item-icon" />
                      </div>
                      <div className="nav-item-text">{t("Import PNR")}</div>

                    </div>
                  )}
                </div>
                <div
                  style={{                    
                    display: "flex",
                    height: "50px",
                  }}
                >
                  {checkIfUserStaff() === false && clientName && (
                    <div>
                      <div
                        className="balance-main-heading"
                        style={{
                          margin: "0px 1vw",
                          maxWidth: "300px",
                          overflowwrap: "break-word",
                        }}
                      >
                        {t("Client Name")} :
                        <br />
                        {clientName}
                      </div>
                    </div>
                  )}
                  {checkIfUserStaff() === false && clientBalance && (
                    <div>
                      <div className="balance-main-heading">
                        {t("Customer Balance")} :
                        <br />
                        {clientCurrencyCode} {clientBalance.balance}
                      </div>
                    </div>
                  )}

                  {checkIfUserStaff() && (
                    <div>
                      <div
                        className="administration-block-container"
                        onClick={handleAdminClick}
                      >
                        <div className="administration-title">
                          {t("Administration")}
                        </div>
                        <div className="administration-drop-icon">
                          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                        </div>
                      </div>
                      <Popover
                        id={id}
                        open={open}
                        anchorReference="anchorPosition"
                        anchorPosition={{
                          top: 70,
                          left: 10,
                          right: 10,
                        }}
                        onClose={handleAdminClose}
                      >
                        <div className="popover-admin-container">
                          <Megamenu closeMenu={handleAdminClose}></Megamenu>
                        </div>
                      </Popover>
                    </div>
                  )}
                  <div
                    className="header-user-details-container"
                    onClick={handleProfileClick}
                  >
                    <div className="header-user-details-avatar-container">
                      <Avatar
                        className={classes.avatar}
                        onClick={handleClickRest}
                        onMouseOver={handleClickRest}
                        onMouseOut={handleCloseRest}
                      >
                        {getFullName().trim()[0]}
                      </Avatar>
                    </div>
                    <div className="header-user-details-detailed">
                      <div className="header-user-details-username">
                        {getFullName()}
                      </div>
                      <div className="header-user-details-user-role">
                        {getUserRole()}
                      </div>
                    </div>
                    <div className="administration-drop-icon">
                      <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                    </div>
                  </div>
                  <Popover
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={profileOpen}
                    anchorEl={profileAnchorEl}
                    onClose={handleProfileClose}
                  >
                    <div className="popover-profile-container">
                      {checkIfUserStaff() === false && (
                        <React.Fragment>
                          {(isClientUserHasPermission("IsManageUser") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToAccessManagement}
                            >
                              {t("Access Management")}
                            </div>
                          )}

                          {(isClientUserHasPermission("IsManageUser") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToManageUsers}
                            >
                              {t("Manage Users")}
                            </div>
                          )}

                          {
                            (getOldClientId()) ?

                              <div
                                className="popover-profile-container-item"
                                onClick={goToOldBookings}
                              >
                                {t(" Old Bookings")}
                              </div>
                              : ''}
                          {(isClientUserHasPermission("IsPayment") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToPayment}
                            >
                              {t("Instant Bank transfer")}
                            </div>
                          )}
                          {(isClientUserHasPermission("IsMyWallet") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToMyWallet}
                            >
                              {t("My Wallet")}
                            </div>)}

                          {(isClientUserHasPermission("IsManageBooking") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToManageBookings}
                            >
                              {t("Manage Bookings")}
                            </div>
                          )}
                          {(isClientUserHasPermission("IsTravelSchedule") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToTravelSchedule}
                            >
                              {t("Travel Schedule")}
                            </div>)}

                          {(isClientUserHasPermission("IsManageTransaction") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToManageTransactions}
                            >
                              {t("Manage Transaction")}
                            </div>)}
                          {(isClientUserHasPermission("IsViewCancellation") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToViewCancellation}
                            >
                              {t("View Cancellation")}
                            </div>)}
                          {(isClientUserHasPermission("IsViewCancellation") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item"
                              onClick={goToReissue}
                            >
                              {t("View Reissue")}
                            </div>)}
                          {(isClientUserHasPermission("IsManageBooking") || userRole === "ClientSuperAdmin") && (
                            <div
                              className="popover-profile-container-item text-danger"
                              onClick={goToQueueTablewithFilter}
                            >
                              {`${t("Hold Bookings")} ${holdBookingCount >= 0 ? "(" + (holdBookingCount) + ")" : <CircularProgress size="1rem" />}`}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      <div
                        className="popover-profile-container-item"
                        onClick={goToProfile}
                      >
                        {t("View Profile")}
                      </div>
                      <Divider />
                      <div
                        className="popover-profile-container-item"
                        onClick={logoutfn}
                      >
                        {t("Logout")}
                      </div>
                    </div>
                  </Popover>
                </div>
              </React.Fragment>
            ) : (
              <div></div>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
      <Snackbar
        open={copiedSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setCopiedSnackBarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setCopiedSnackBarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
          position
        >
          {copiedSnackBarContent}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Header;
