import React, { useState, useEffect } from 'react';
import "./BookingSwitches.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import AddBookingSwitchesCard from './AddBookingSwitchesCard/AddBookingSwitchesCard';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import BookingSwitchesTable from './BookingSwitchesTable/BookingSwitchesTable';
import BookingSwitchesDrawer from './BookingSwitchesDrawer/BookingSwitchesDrawer';
import axios from 'axios';
import RiseLoading from '../../Loader/RiseLoader'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BookingSwitches() {

    const [drawer, setDrawer] = useState(false);
    const [action, setAction] = useState("new");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [bookingSwitches, setbookingSwitches] = useState([]);
    const [bookingSwitchName, setbookingSwitchName] = useState("");
    const [searchPCC, setsearchPCC] = useState("");
    const [supplierName, setsupplierName] = useState("");
    const [supplierNameBooking, setsupplierNameBooking] = useState("");
    const [bookingPCC, setbookingPCC] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        loadBookingSwitches();
        //loadPCCDetails();
    }, []);

    async function loadBookingSwitches() {
        setShowLoader(true);
        let filter = "";

        if (bookingSwitchName !== "") filter = "bookingSwitchName==" + bookingSwitchName;

        if (supplierName !== "") {
            if (filter === "")
                filter = "searchSupplier==" + supplierName;
            else
                filter = filter + "searchSupplier==" + supplierName;
        }
        if (filter !== "") filter = "?filters=" + filter;

        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/BookingSwitchConfiguration" + filter
            )
            .then((response) => {
                setShowLoader(false);
                setbookingSwitches(response.data);
            })
            .catch(error => {
                console.log(error)
                setShowLoader(false);
            });
    }

    const toggleOpenSearch = () => {
        setOpenSearch(!openSearch);
    };

    const toggleDrawer = (value) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawer(value);
    };

    const openDrawer = (selectedItem) => {
        setSelectedItem(selectedItem);
        setDrawer(true);
    };

    const handleSnackbarToggle = () => {
        setDrawer(false);
        setSnackbarOpen(true);
        loadBookingSwitches();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div className="bookingswitches-container">
            <div className="bookingswitches-content-container">
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <div className="search-bookingswitches-container">
                            <div className="bookingswitches-table-container">
                                <div className="bookingswitches-title-add-button-container mb-2">
                                    <div className="bookingswitches-heading">
                                        Re-validation Config
                                    </div>
                                    <div className="search-icon-button-container d-flex justify-content-between">
                                        <div>
                                        </div>
                                        <div>
                                            <div
                                                className="add-bookingswitches-button mt-0px"
                                                onClick={() => {
                                                    setAction("new");
                                                    setDrawer(true);
                                                }}
                                            >
                                                Add New Config
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                    <div className="bookingswitches-card-container">
                                        <AddBookingSwitchesCard
                                            setbookingSwitchName={setbookingSwitchName}
                                            setsearchPCC={setsearchPCC}
                                            setsupplierName={setsupplierName}
                                            setsupplierNameBooking={setsupplierNameBooking}
                                            setbookingPCC={setbookingPCC}
                                            loadBookingSwitches={loadBookingSwitches}
                                        ></AddBookingSwitchesCard>
                                    </div>
                                </Collapse>
                                <BookingSwitchesTable
                                    openDrawer={openDrawer}
                                    setAction={setAction}
                                    bookingSwitches={bookingSwitches}
                                ></BookingSwitchesTable>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
            <Drawer
                anchor="right"
                open={drawer}
                onClose={toggleDrawer(false)}
                BackdropProps={{ invisible: true }}
                variant="persistent"
                className="drawer-width"
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <BookingSwitchesDrawer
                    selectedItem={selectedItem}
                    toggleDrawer={toggleDrawer}
                    action={action}
                    setAction={setAction}
                    handleSnackbarToggle={handleSnackbarToggle}
                    setShowLoader={setShowLoader}
                    showLoader={showLoader}
                ></BookingSwitchesDrawer>
            </Drawer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity="success">
                    {`Setting has been ${action === "new" ? "created" : "updated"}`}
                </Alert>
            </Snackbar>
            {showLoader && <RiseLoading isLoading={showLoader} />}
        </div>
    );
}
