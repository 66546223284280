import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import './Reissue.css'
import { formatDate } from '../../../services/dateFormatter';
import SkeletonView from '../../SkeletonView/SkeletonView';
import Textarea from '@mui/joy/Textarea';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ValidatorForm } from 'react-material-ui-form-validator';
const Reissue = ({ reissuePopUp, setReissuePopUp, results, pnr, reissueRequest, wholeItinerarySelected, setWholeItinerarySelected,
    setReissueDetails, reissueDetails, onwardDate, setOnwardDate, returnDate, setReturnDate, setReissueRemarks
}) => {
    const { t } = useTranslation();
    const [selectedPax, setSelectedPax] = useState({});
    const [disabledPax, setDisabledPax] = useState({});
    const [isReissueDisabled, setIsReissueDisabled] = useState(false);
    const today = dayjs();
    const [itOnwardDate, setItOnwardDate] = useState(null);
    const [itReturnDate, setItReturnDate] = useState(null);
    const [dateConflict, setDateConflict] = useState("");
    const [conflictIndex, setConflictIndex] = useState(-1);
    const [paxConflict, setPaxConflict] = useState("");
    const [paxConflictIndex, setPaxConflictIndex] = useState(-1);
    const [dateErrors, setDateErrors] = useState({});

    const handleSelectPax = (itineraryId, paxId) => {
        const newSelectedPax = { ...selectedPax };
        const newDisabledPax = { ...disabledPax };
        const newReissueDetails = { ...reissueDetails };

        const isSelected = newSelectedPax[itineraryId]?.includes(paxId);
        const segGroupIndex = newReissueDetails.flights[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;

        // Toggle selection
        if (isSelected) {
            newSelectedPax[itineraryId] = newSelectedPax[itineraryId].filter(id => id !== paxId);
        } else {
            if (!newSelectedPax[itineraryId]) {
                newSelectedPax[itineraryId] = [];
            }
            newSelectedPax[itineraryId].push(paxId);
        }

        // Update isChecked status in reissueDetails
        newReissueDetails.flights[0].segGroups.forEach((segGroup, index) => {
            if (index === segGroupIndex) {
                segGroup.passengers.forEach(pax => {
                    if (pax.id === paxId) {
                        pax.isChecked = !isSelected;
                    }
                });
            }
        });

        // Round-trip validation
        const segGroups = newReissueDetails.flights[0].segGroups;
        if (segGroups.length > 1) { // Only check for round trips
            const selectedPaxLists = segGroups.map(seg => newSelectedPax[seg.id] || []);

            // Remove empty selections (segments where no pax is selected)
            const nonEmptySelections = selectedPaxLists.filter(list => list.length > 0);

            // Check if selections are inconsistent
            if (nonEmptySelections.length > 1 &&
                !nonEmptySelections.every((list, _, arr) => JSON.stringify(list.sort()) === JSON.stringify(arr[0].sort()))) {
                setPaxConflictIndex(2);
                setPaxConflict("For round trip, different passengers cannot be selected for different trips.");
            } else {
                setPaxConflictIndex(-1);
                setPaxConflict("");
            }
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setReissueDetails(newReissueDetails);
    };




    const handleSelectAll = (itineraryId) => {
        let newSelectedPax = { ...selectedPax };
        let newDisabledPax = { ...disabledPax };
        const newReissueDetails = { ...reissueDetails };
        const segGroupIndex = newReissueDetails.flights[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;

        const itinerary = results.find(it => it.id === itineraryId);

        if (newSelectedPax[itineraryId]?.length === itinerary.passengers.length) {
            newSelectedPax = {};
            newDisabledPax = {};

            newReissueDetails.flights[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = false;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = false;
                        pax.isDisabled = false;
                    });
                }
            });
        } else {
            newSelectedPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newDisabledPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newReissueDetails.flights[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = true;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = true;
                        pax.isDisabled = true;
                    });
                }
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setReissueDetails(newReissueDetails);
    };


    useEffect(() => {
        setItOnwardDate(results[0]?.departureOn);
        setItReturnDate(results[1]?.departureOn);
    }, [results, selectedPax]);


    const handleWholeItinerarySelection = () => {
        const isSelected = wholeItinerarySelected;

        results.forEach(itinerary => {
            const segGroupIndex = reissueDetails.flights[0].segGroups.findIndex(seg => seg.id === itinerary.id);
            reissueDetails.flights[0].segGroups[segGroupIndex].isAllChecked = !isSelected;

            reissueDetails.flights[0].segGroups[segGroupIndex].passengers.forEach(pax => {
                pax.isChecked = !isSelected;
                pax.isDisabled = !isSelected;
            });
        });

        setWholeItinerarySelected(!isSelected);
    };

    const reissueNow = () => {
        reissueRequest();
    };

    useEffect(() => {
        const onward = onwardDate ? dayjs(onwardDate) : null;
        const returnDt = returnDate ? dayjs(returnDate) : null;
        const itOnward = itOnwardDate ? dayjs(itOnwardDate) : null;
        const itReturn = itReturnDate ? dayjs(itReturnDate) : null;

        if (onward || returnDt) {
            if (onward && returnDt && (onward.isAfter(returnDt))) {
                setDateConflict("*Selected date for onward trip is greater than the existing date for return trip");
                setConflictIndex(0);
            }
            else if (onward && !returnDt && (itReturn && onward.isAfter(itReturn))) {
                setDateConflict("*Selected date for onward trip is greater than the existing date for return trip");
                setConflictIndex(0);
            }
            else if (itOnward && !onward && returnDt && itOnward.isAfter(returnDt)) {
                setDateConflict("*Selected date for return trip is less than the existing date for onward trip");
                setConflictIndex(1);
            }
            else {
                setDateConflict("");
                setConflictIndex(-1);
            }
        } else {
            setDateConflict("");
            setConflictIndex(-1);
        }
    }, [onwardDate, returnDate]);



    useEffect(() => {
        const newDateErrors = {};

        if (wholeItinerarySelected) {
            if (!onwardDate) {
                newDateErrors["onwardDate"] = t("Onward date is required");
            }
            if (!returnDate && results.length > 1) {
                newDateErrors["returnDate"] = t("Return date is required");
            }
        } else {
            results.forEach((itinerary, index) => {
                const segGroupId = itinerary.id;
                const isAnyPaxSelected = selectedPax[segGroupId]?.length > 0;

                if (isAnyPaxSelected) {
                    if (index === 0 && !onwardDate) {
                        newDateErrors["onwardDate"] = t("Onward date is required");
                    }
                    if (index === 1 && !returnDate) {
                        newDateErrors["returnDate"] = t("Return date is required");
                    }
                }
            });
        }

        setDateErrors(newDateErrors);
    }, [selectedPax, onwardDate, returnDate, wholeItinerarySelected]);

    useEffect(() => {
        results.forEach((itinerary, index) => {
            const segGroupId = itinerary.id;
            const isAnyPaxSelected = selectedPax[segGroupId]?.length > 0;

            if (!isAnyPaxSelected) {
                if (index === 0) {
                    setOnwardDate(null);
                } else if (index === 1) {
                    setReturnDate(null);
                }
            }
        });
    }, [selectedPax]);

    return (
        <>
            <Dialog className="" style={{ inset: "40px" }} open={reissuePopUp} onClose={() => setReissuePopUp(false)} maxWidth="md" fullWidth >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div></div>
                        <div><Button onClick={() => {
                            setReissuePopUp(false);
                            setOnwardDate(null);
                            setReturnDate(null);
                            setConflictIndex(-1);
                            setDateConflict("");
                            setDateErrors({});
                        }} color="inherit">
                            <CloseIcon />
                        </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className=''>
                    <div className="reissue">
                        <ValidatorForm>
                            <div className='d-flex justify-content-between'>
                                <div className='text-bold'>Reissue</div>
                                <div>
                                    <Checkbox
                                        onChange={handleWholeItinerarySelection}
                                        checked={wholeItinerarySelected || (results.every(itinerary =>
                                            itinerary.passengers
                                                .filter(pax => pax.reIssueStatus === "None")
                                                .every(pax => pax.isChecked)
                                        ))}
                                    /> {t("Select Whole Itinerary")}
                                </div>
                            </div>

                            {
                                results[0].origin ? (<>
                                    <div >
                                        {results?.map((itinerary, index) => (
                                            <div key={itinerary.id} className="itinerary mt-3 mb-3">
                                                {itinerary.segments.map((segment, segIndex) => (
                                                    <div className='d-flex justify-content-between border border-1 border-danger p-2 rounded mt-1'>
                                                        <div>{segment.origin} ---&gt; {segment.destination}</div>
                                                        <div>{formatDate(segment.departureOn)}</div>
                                                        <div> {segment.mrkAirline + "-" + segment.flightNum}</div>
                                                        <div>PNR- {pnr}</div>
                                                        <div>
                                                            {segIndex === 0 ? <>
                                                                <Checkbox
                                                                    onChange={() => handleSelectAll(itinerary.id)}
                                                                    checked={itinerary.isAllChecked || wholeItinerarySelected || itinerary.passengers.filter(pax => pax.reIssueStatus === "None").every(pax => pax.isChecked)}
                                                                    disabled={itinerary.isDisabled || wholeItinerarySelected}
                                                                /> {t("Select All")}
                                                            </> : <div style={{ width: "90px" }}></div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                <div>
                                                    {itinerary.passengers
                                                        .filter(pax => (pax.reIssueStatus === "None"))
                                                        .sort((a, b) => a.paxType.localeCompare(b.paxType))
                                                        .map(pax => (
                                                            <div key={pax.id} className='d-flex justify-content-between mt-3 mb-3'>
                                                                <div style={{ width: "50%" }}>
                                                                    <Checkbox
                                                                        onChange={() => handleSelectPax(itinerary.id, pax.id, pax.ticketNumber)}
                                                                        checked={pax.isChecked || pax.isCancelled || false}
                                                                        disabled={pax.isDisabled || pax.isCancelled}
                                                                    />
                                                                    {pax?.firstName + " " + pax?.lastName} ({pax?.paxType})
                                                                </div>
                                                                <div className='d-flex justify-content-between' style={{ width: "50%" }}>
                                                                    <div> {t("Ticket Number")}: {pax?.ticketNumber} </div>
                                                                    <div className='text-success'>{pax?.reIssueStatus}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                <div style={{ width: "13rem" }} className='border border-2 rounded mt-4'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label={t("Select Date")}
                                                            disablePast
                                                            disabled={!(selectedPax[itinerary.id]?.length > 0) && !wholeItinerarySelected}
                                                            format="DD/MM/YYYY"
                                                            onChange={(newValue) => {
                                                                if (!newValue) { // Handle null safely
                                                                    index === 0 ? setOnwardDate(null) : setReturnDate(null);
                                                                } else {
                                                                    index === 0 ? setOnwardDate(newValue.$d) : setReturnDate(newValue.$d);
                                                                }
                                                            }}
                                                            value={
                                                                index === 0
                                                                    ? (onwardDate ? dayjs(onwardDate) : null)
                                                                    : (returnDate ? dayjs(returnDate) : null)
                                                            }
                                                            minDate={index === 1 && onwardDate ? dayjs(onwardDate) : null}
                                                            maxDate={dayjs().add(9, "months")}
                                                            slotProps={{
                                                                field: {
                                                                    clearable: true,
                                                                    onClear: () => {
                                                                        index === 0 ? setOnwardDate(null) : setReturnDate(null);
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>

                                                    {dateErrors["onwardDate"] && index === 0 && (
                                                        <span className="text-danger" style={{ fontSize: "0.8rem" }}>
                                                            {dateErrors["onwardDate"]}
                                                        </span>
                                                    )}
                                                    {dateErrors["returnDate"] && index === 1 && (
                                                        <span className="text-danger" style={{ fontSize: "0.8rem" }}>
                                                            {dateErrors["returnDate"]}
                                                        </span>
                                                    )}
                                                </div>
                                                {index === conflictIndex && dateConflict && <span className='text-danger' style={{ fontSize: "0.8rem" }}>{dateConflict}</span>}
                                            </div>
                                        ))}
                                        {paxConflictIndex === 2 && (
                                            <span className='text-danger' style={{ fontSize: "0.8rem" }}>
                                                {paxConflict}
                                            </span>
                                        )}
                                    </div>
                                    <Divider sx={{ opacity: 1, borderBottomWidth: "medium" }} className='mt-5' />

                                    <div className='mt-3'>
                                        <p>
                                            <span className='text-danger'>Note:</span><br></br>
                                            1. {t("If whole itinerary selected, then it will Auto exchange the Tickets")}<br></br>
                                            2. {t("Partial Exchange will create offline request")}<br></br>
                                        </p>
                                    </div>
                                    <div>
                                        <div className='text-bold'>{t("Remark")}</div>
                                        <Textarea sx={{ minHeight: "6rem" }} onChange={(e) => setReissueRemarks(e.target.value)} />
                                    </div>
                                </>)
                                    :
                                    (
                                        <SkeletonView />
                                    )}

                            <div className='d-flex justify-content-between mt-3'>
                                <div>{t("I accept the Terms and Conditions by clicking on Submit Now")}</div>
                                <Button disabled={isReissueDisabled || conflictIndex !== -1 || paxConflictIndex !== -1 || !(Object.keys(dateErrors).length === 0)} onClick={reissueNow} className='srn-btn'>{t("Submit Now")}</Button>
                            </div>
                        </ValidatorForm>
                    </div>
                </DialogContent >


            </Dialog >
        </>
    );
};

export default Reissue;