const airlineDetails = [
  {
    Code: "All",
    Name: "All",
  },
  {
    Code: "WN",
    Name: "Southwest",
  },
  {
    Code: "0B",
    Name: "Blue Air",
  },
  {
    Code: "0O",
    Name: "STA Travel",
  },
  {
    Code: "0V",
    Name: "Vietnam Air Service",
  },
  {
    Code: "YT",
    Name: "Yeti Airlines",
  },
  {
    Code: "1A",
    Name: "Amadeus",
  },
  {
    Code: "1B",
    Name: "Abacus",
  },
  {
    Code: "1C",
    Name: "EDS Information Business",
  },
  {
    Code: "1D",
    Name: "Raddix Solutions Intl",
  },
  {
    Code: "1E",
    Name: "Travelsky Technology Ltd",
  },
  {
    Code: "1F",
    Name: "Infini Travel Informatio",
  },
  {
    Code: "1G",
    Name: "Galileo International",
  },
  {
    Code: "1H",
    Name: "Sirena-Travel",
  },
  {
    Code: "1J",
    Name: "Axess International",
  },
  {
    Code: "1K",
    Name: "Sutra Inc.",
  },
  {
    Code: "1L",
    Name: "CitizenPlane",
  },
  {
    Code: "1N",
    Name: "Navitaire New Skies",
  },
  {
    Code: "1O",
    Name: "Phoenix Systems Dertours",
  },
  {
    Code: "1P",
    Name: "Worldspan",
  },
  {
    Code: "1T",
    Name: "Hitit Bilgisayar Hizmetleri",
  },
  {
    Code: "1U",
    Name: "ITA Software",
  },
  {
    Code: "1V",
    Name: "Galileo International",
  },
  {
    Code: "1X",
    Name: "Branson Air",
  },
  {
    Code: "2A",
    Name: "Deutsche Bahn",
  },
  {
    Code: "2B",
    Name: "Albawings",
  },
  {
    Code: "2C",
    Name: "SNCF",
  },
  {
    Code: "2D",
    Name: "Eastern Airlines",
  },
  {
    Code: "2E",
    Name: "Smokey Bay Air",
  },
  {
    Code: "2G",
    Name: "Northwest Seaplanes",
  },
  {
    Code: "2H",
    Name: "Angara Airlines",
  },
  {
    Code: "2I",
    Name: "Star Peru",
  },
  {
    Code: "2J",
    Name: "Air Burkina",
  },
  {
    Code: "2K",
    Name: "Avianca Ecuador S.A.",
  },
  {
    Code: "2L",
    Name: "Helvetic Airways",
  },
  {
    Code: "2M",
    Name: "Maya Island Air",
  },
  {
    Code: "2N",
    Name: "Nextjet",
  },
  {
    Code: "2O",
    Name: "Redemption",
  },
  {
    Code: "2P",
    Name: "PAL Express",
  },
  {
    Code: "2T",
    Name: "Turbo Megha Airways",
  },
  {
    Code: "2U",
    Name: "Sun D'Or Intl Airlines",
  },
  {
    Code: "2V",
    Name: "Amtrak",
  },
  {
    Code: "2W",
    Name: "World2Fly",
  },
  {
    Code: "2X",
    Name: "Amadeus Two",
  },
  {
    Code: "2Y",
    Name: "Amadeus Pdf 2Y",
  },
  {
    Code: "2Z",
    Name: "Passaredo Transportes",
  },
  {
    Code: "3A",
    Name: "Chu Kong Passenger Transport",
  },
  {
    Code: "3B",
    Name: "TICV",
  },
  {
    Code: "3C",
    Name: "Air Chathams",
  },
  {
    Code: "3E",
    Name: "Multi Aero",
  },
  {
    Code: "BL",
    Name: "Pacific Airlines",
  },
  {
    Code: "3H",
    Name: "Air Inuit",
  },
  {
    Code: "3J",
    Name: "Jubba Airways",
  },
  {
    Code: "3K",
    Name: "Jetstar Asia",
  },
  {
    Code: "3L",
    Name: "Air Arabia Abu Dhabi",
  },
  {
    Code: "3M",
    Name: "Silver Airways",
  },
  {
    Code: "3N",
    Name: "Air Urga",
  },
  {
    Code: "3O",
    Name: "Air Arabia Maroc",
  },
  {
    Code: "3P",
    Name: "World 2 Fly Portugal",
  },
  {
    Code: "3Q",
    Name: "Royal Air Charters",
  },
  {
    Code: "3R",
    Name: "Divi Divi Air",
  },
  {
    Code: "3S",
    Name: "Air Antilles Express",
  },
  {
    Code: "3T",
    Name: "Tarco Aviation",
  },
  {
    Code: "3U",
    Name: "Sichuan Airlines",
  },
  {
    Code: "3V",
    Name: "ASL Airlines Belgium",
  },
  {
    Code: "3W",
    Name: "Malawi Airlines",
  },
  {
    Code: "3Z",
    Name: "Smartwings Poland",
  },
  {
    Code: "4B",
    Name: "Boutique Air",
  },
  {
    Code: "4C",
    Name: "Latam Airlines Colombia",
  },
  {
    Code: "4D",
    Name: "Air Sinai",
  },
  {
    Code: "4F",
    Name: "Freedom Airline Express",
  },
  {
    Code: "4G",
    Name: "Gazpromavia",
  },
  {
    Code: "4H",
    Name: "HiSky",
  },
  {
    Code: "4J",
    Name: "Jetair Caribbean",
  },
  {
    Code: "4K",
    Name: "Kenn Borek Air",
  },
  {
    Code: "4M",
    Name: "Latam Airlines Argentina",
  },
  {
    Code: "4N",
    Name: "Air North",
  },
  {
    Code: "4O",
    Name: "Interjet",
  },
  {
    Code: "4P",
    Name: "Regional Sky",
  },
  {
    Code: "4Q",
    Name: "Safi Airways",
  },
  {
    Code: "4R",
    Name: "Renfe Viajeros",
  },
  {
    Code: "S5",
    Name: "Star Air",
  },
  {
    Code: "4T",
    Name: "Transwest Air",
  },
  {
    Code: "4U",
    Name: "Germanwings",
  },
  {
    Code: "4V",
    Name: "Fly Gangwon",
  },
  {
    Code: "4W",
    Name: "Warbelows Air Ventures",
  },
  {
    Code: "4Z",
    Name: "South African Airlink",
  },
  {
    Code: "5B",
    Name: "Bassaka Air",
  },
  {
    Code: "5E",
    Name: "Alfa Airlines",
  },
  {
    Code: "5F",
    Name: "Fly One",
  },
  {
    Code: "5J",
    Name: "Cebu Air",
  },
  {
    Code: "5K",
    Name: "Hi Fly Transportes Aereo",
  },
  {
    Code: "5M",
    Name: "Fly Montserrat",
  },
  {
    Code: "5N",
    Name: "Smartavia",
  },
  {
    Code: "5O",
    Name: "ASL Airlines France",
  },
  {
    Code: "5R",
    Name: "RUTACA",
  },
  {
    Code: "5S",
    Name: "Global Air Transport",
  },
  {
    Code: "5T",
    Name: "Canadian North",
  },
  {
    Code: "5U",
    Name: "Transportes Aereos",
  },
  {
    Code: "5V",
    Name: "Everts",
  },
  {
    Code: "5W",
    Name: "Wizz Air Abu Dhabi",
  },
  {
    Code: "5Z",
    Name: "Cemair",
  },
  {
    Code: "6A",
    Name: "Armenia Airways",
  },
  {
    Code: "6B",
    Name: "TUIFly Nordic",
  },
  {
    Code: "6C",
    Name: "Air Timor",
  },
  {
    Code: "6D",
    Name: "Smartwings Slovakia",
  },
  {
    Code: "6E",
    Name: "IndiGo",
  },
  {
    Code: "6H",
    Name: "Israir",
  },
  {
    Code: "6I",
    Name: "Air Alsie",
  },
  {
    Code: "6J",
    Name: "Solaseed Air",
  },
  {
    Code: "6N",
    Name: "Niger Airlines",
  },
  {
    Code: "6O",
    Name: "Orbest",
  },
  {
    Code: "6Q",
    Name: "Cham Wings Airlines",
  },
  {
    Code: "6R",
    Name: "Mirny Air",
  },
  {
    Code: "6S",
    Name: "Saudi Gulf Airlines",
  },
  {
    Code: "6W",
    Name: "Flybosnia",
  },
  {
    Code: "6X",
    Name: "Amadeus Six",
  },
  {
    Code: "6Y",
    Name: "Smartlynx Airlines",
  },
  {
    Code: "V9",
    Name: "Van Air Europe",
  },
  {
    Code: "7B",
    Name: "Bees Airline LLC",
  },
  {
    Code: "7C",
    Name: "Jeju Air",
  },
  {
    Code: "7E",
    Name: "Sylt Air Gmbh",
  },
  {
    Code: "7F",
    Name: "First Air",
  },
  {
    Code: "7G",
    Name: "Star Flyer",
  },
  {
    Code: "7H",
    Name: "Ravn Alaska",
  },
  {
    Code: "7I",
    Name: "Air Libya",
  },
  {
    Code: "7J",
    Name: "Tajik Air",
  },
  {
    Code: "7M",
    Name: "MAP Linhas Aereas",
  },
  {
    Code: "7O",
    Name: "Smartwings Hungary",
  },
  {
    Code: "7P",
    Name: "Air Panama",
  },
  {
    Code: "7Q",
    Name: "ELITE Airways",
  },
  {
    Code: "7R",
    Name: "Rusline",
  },
  {
    Code: "7T",
    Name: "Trenitalia",
  },
  {
    Code: "7V",
    Name: "Federal Airlines",
  },
  {
    Code: "7W",
    Name: "Wind Rose Aviation",
  },
  {
    Code: "7X",
    Name: "Amadeus Seven",
  },
  {
    Code: "7Y",
    Name: "Mann Yadanarpon Airlines",
  },
  {
    Code: "7Z",
    Name: "EZ Air BV",
  },
  {
    Code: "8B",
    Name: "Transnusa Aviation",
  },
  {
    Code: "8E",
    Name: "Bering Air",
  },
  {
    Code: "8F",
    Name: "STP Airways",
  },
  {
    Code: "8G",
    Name: "Mid Africa Aviation",
  },
  {
    Code: "8H",
    Name: "BH Air",
  },
  {
    Code: "8I",
    Name: "ITA",
  },
  {
    Code: "8J",
    Name: "Linea Aerea Eco Jet",
  },
  {
    Code: "8L",
    Name: "Lucky Air",
  },
  {
    Code: "8M",
    Name: "Myanmar Airways Intl",
  },
  {
    Code: "8N",
    Name: "Regional Air Services",
  },
  {
    Code: "8P",
    Name: "Pacific Coastal Airlines",
  },
  {
    Code: "8Q",
    Name: "Onur Air",
  },
  {
    Code: "8R",
    Name: "Amelia",
  },
  {
    Code: "8T",
    Name: "Air Tindi Ltd",
  },
  {
    Code: "8U",
    Name: "Afriqiyah Airways",
  },
  {
    Code: "8V",
    Name: "Wright Air Services",
  },
  {
    Code: "8W",
    Name: "Fly Always",
  },
  {
    Code: "8X",
    Name: "Amadeus Eight",
  },
  {
    Code: "8Y",
    Name: "Astro Air International",
  },
  {
    Code: "8Z",
    Name: "Congo Airways",
  },
  {
    Code: "9A",
    Name: "Gran Colombia De Aviacion",
  },
  {
    Code: "9B",
    Name: "Accesrail",
  },
  {
    Code: "9C",
    Name: "Spring Airlines",
  },
  {
    Code: "9D",
    Name: "Genghis Khan Airlines",
  },
  {
    Code: "9E",
    Name: "Endeavor Air",
  },
  {
    Code: "9F",
    Name: "Eurostar",
  },
  {
    Code: "9G",
    Name: "9G Rail Ltd",
  },
  {
    Code: "9H",
    Name: "Changan Airlines",
  },
  {
    Code: "9I",
    Name: "Alliance Air",
  },
  {
    Code: "9J",
    Name: "Dana Airlines",
  },
  {
    Code: "9K",
    Name: "Cape Air",
  },
  {
    Code: "9M",
    Name: "Central Mountain Air",
  },
  {
    Code: "9N",
    Name: "Tropic Air Limited",
  },
  {
    Code: "9P",
    Name: "Fly Jinnah",
  },
  {
    Code: "9Q",
    Name: "Caicos Express Airways",
  },
  {
    Code: "9R",
    Name: "Satena",
  },
  {
    Code: "9S",
    Name: "Amadeus Pdf 9S",
  },
  {
    Code: "9T",
    Name: "Thai Summer Airways",
  },
  {
    Code: "9U",
    Name: "Air Moldova",
  },
  {
    Code: "9V",
    Name: "Avior",
  },
  {
    Code: "9W",
    Name: "Jet Airways",
  },
  {
    Code: "9X",
    Name: "Southern Airways Express",
  },
  {
    Code: "9Y",
    Name: "National Airways",
  },
  {
    Code: "A0",
    Name: "Avianca Argentina",
  },
  {
    Code: "A1",
    Name: "A.P.G. Distribution System",
  },
  {
    Code: "A2",
    Name: "Animawings",
  },
  {
    Code: "A3",
    Name: "Aegean Airlines",
  },
  {
    Code: "A4",
    Name: "Azimuth Airlines",
  },
  {
    Code: "A5",
    Name: "HOP",
  },
  {
    Code: "A6",
    Name: "Air Alps Aviation",
  },
  {
    Code: "A7",
    Name: "Aereo Calafia",
  },
  {
    Code: "A8",
    Name: "Aerolink Uganda Limited",
  },
  {
    Code: "A9",
    Name: "Georgian Airways",
  },
  {
    Code: "AA",
    Name: "American Airlines",
  },
  {
    Code: "AB",
    Name: "Air Berlin",
  },
  {
    Code: "AC",
    Name: "Air Canada",
  },
  {
    Code: "AD",
    Name: "Azul Linhas Aereas Brasileiras",
  },
  {
    Code: "AE",
    Name: "Mandarin Airlines",
  },
  {
    Code: "AF",
    Name: "Air France",
  },
  {
    Code: "AH",
    Name: "Air Algerie",
  },
  {
    Code: "AI",
    Name: "Air India",
  },
  {
    Code: "AK",
    Name: "Airasia",
  },
  {
    Code: "AL",
    Name: "Malta Air",
  },
  {
    Code: "AM",
    Name: "Aeromexico",
  },
  {
    Code: "AO",
    Name: "Avianova Lcc",
  },
  {
    Code: "AP",
    Name: "Alba Star",
  },
  {
    Code: "AQ",
    Name: "Aloha Airlines",
  },
  {
    Code: "AR",
    Name: "Aerolineas Argentinas",
  },
  {
    Code: "AS",
    Name: "Alaska Airlines",
  },
  {
    Code: "AT",
    Name: "Royal Air Maroc",
  },
  {
    Code: "AU",
    Name: "Austral Lineas Aereas",
  },
  {
    Code: "AV",
    Name: "Avianca",
  },
  {
    Code: "AW",
    Name: "Africa World Airlines",
  },
  {
    Code: "AX",
    Name: "American Connection",
  },
  {
    Code: "AY",
    Name: "Finnair",
  },
  {
    Code: "AZ",
    Name: "ITA AIRWAYS",
  },
  {
    Code: "B1",
    Name: "Bravo Passenger Solution",
  },
  {
    Code: "B2",
    Name: "Belavia",
  },
  {
    Code: "B3",
    Name: "Bhutan Airlines",
  },
  {
    Code: "B4",
    Name: "Zanair",
  },
  {
    Code: "B5",
    Name: "East African Safari Air",
  },
  {
    Code: "B6",
    Name: "JetBlue",
  },
  {
    Code: "B7",
    Name: "UNI Airways",
  },
  {
    Code: "B8",
    Name: "Eritrean Airlines",
  },
  {
    Code: "B9",
    Name: "Iran Air Tours",
  },
  {
    Code: "BA",
    Name: "British Airways",
  },
  {
    Code: "BB",
    Name: "Seaborne Airlines",
  },
  {
    Code: "BC",
    Name: "Skymark Airlines",
  },
  {
    Code: "BD",
    Name: "Cambodia Bayon Airlines",
  },
  {
    Code: "BE",
    Name: "Flybe",
  },
  {
    Code: "BF",
    Name: "French Bee",
  },
  {
    Code: "BG",
    Name: "Biman Bangladesh",
  },
  {
    Code: "BI",
    Name: "Royal Brunei",
  },
  {
    Code: "BJ",
    Name: "Nouvelair",
  },
  {
    Code: "BK",
    Name: "Okay Airways Company Limited",
  },
  {
    Code: "BL",
    Name: "Jetstar Pacific Airlines",
  },
  {
    Code: "BM",
    Name: "BMI Regional",
  },
  {
    Code: "BN",
    Name: "Bakhtar Afghan Airline",
  },
  {
    Code: "BP",
    Name: "Air Botswana",
  },
  {
    Code: "BR",
    Name: "Eva Air",
  },
  {
    Code: "BS",
    Name: "US Bangla",
  },
  {
    Code: "BT",
    Name: "Air Baltic",
  },
  {
    Code: "BU",
    Name: "Compagnie Africaine D'Aviation",
  },
  {
    Code: "BV",
    Name: "Blue Panorama Airlines",
  },
  {
    Code: "BW",
    Name: "Caribbean Airlines",
  },
  {
    Code: "BX",
    Name: "Air Busan",
  },
  {
    Code: "BY",
    Name: "TUI",
  },
  {
    Code: "BZ",
    Name: "Blue Bird Airways",
  },
  {
    Code: "C1",
    Name: "Tectimes Sudamerica",
  },
  {
    Code: "C2",
    Name: "Ceiba Intercontinental",
  },
  {
    Code: "C3",
    Name: "Trade Air",
  },
  {
    Code: "C5",
    Name: "Champlain Enterprises",
  },
  {
    Code: "C7",
    Name: "Cinnamon Air",
  },
  {
    Code: "C8",
    Name: "Cronos Airlines",
  },
  {
    Code: "C9",
    Name: "Alphaland Aviation",
  },
  {
    Code: "CA",
    Name: "Air China",
  },
  {
    Code: "CB",
    Name: "Trans Caribbean Air ExportImport",
  },
  {
    Code: "CC",
    Name: "CM Airlines",
  },
  {
    Code: "CD",
    Name: "Corendon Dutch Airlines",
  },
  {
    Code: "CE",
    Name: "Chalair Aviation",
  },
  {
    Code: "CG",
    Name: "Airlines PNG",
  },
  {
    Code: "CI",
    Name: "China Airlines",
  },
  {
    Code: "CJ",
    Name: "BA Cityflyer",
  },
  {
    Code: "CL",
    Name: "Lufthansa Cityline",
  },
  {
    Code: "CM",
    Name: "Copa Airlines",
  },
  {
    Code: "CN",
    Name: "Grand China Air",
  },
  {
    Code: "CO",
    Name: "Continental Airlines",
  },
  {
    Code: "CP",
    Name: "Compass Airlines",
  },
  {
    Code: "CQ",
    Name: "Coastal Travels",
  },
  {
    Code: "CR",
    Name: "Oag Worldwide",
  },
  {
    Code: "CT",
    Name: "Alitalia City Liner Spa",
  },
  {
    Code: "CU",
    Name: "Cubana De Aviacion",
  },
  {
    Code: "CX",
    Name: "Cathay Pacific",
  },
  {
    Code: "CY",
    Name: "Cyprus Airways",
  },
  {
    Code: "CZ",
    Name: "China Southern Airlines",
  },
  {
    Code: "D2",
    Name: "Severstal Aircompany",
  },
  {
    Code: "D3",
    Name: "Daallo Airlines",
  },
  {
    Code: "D6",
    Name: "GECA",
  },
  {
    Code: "D7",
    Name: "Airasia X",
  },
  {
    Code: "D8",
    Name: "Norwegian Air Shuttle",
  },
  {
    Code: "D9",
    Name: "Dena Airways",
  },
  {
    Code: "DC",
    Name: "Braathens Regional",
  },
  {
    Code: "DD",
    Name: "Nok Air",
  },
  {
    Code: "DE",
    Name: "Condor",
  },
  {
    Code: "DF",
    Name: "Condor Berlin",
  },
  {
    Code: "DG",
    Name: "Cebgo",
  },
  {
    Code: "DI",
    Name: "Marabu",
  },
  {
    Code: "DJ",
    Name: "Airasia Japan",
  },
  {
    Code: "DK",
    Name: "Sunclass Airlines",
  },
  {
    Code: "DL",
    Name: "Delta Air Lines",
  },
  {
    Code: "DM",
    Name: "Dominican Wings",
  },
  {
    Code: "DN",
    Name: "Senegal Airlines",
  },
  {
    Code: "DO",
    Name: "Sky High Aviation Services",
  },
  {
    Code: "DP",
    Name: "Pobeda Airlines",
  },
  {
    Code: "DQ",
    Name: "Alexandria Airlines",
  },
  {
    Code: "DR",
    Name: "Sunan Ruili Airlines",
  },
  {
    Code: "DS",
    Name: "Easyjet Switzerland",
  },
  {
    Code: "DT",
    Name: "Taag Angola Airlines",
  },
  {
    Code: "DU",
    Name: "Sky Jet M.G. Inc",
  },
  {
    Code: "DV",
    Name: "Jsc Aircompany Scat",
  },
  {
    Code: "DW",
    Name: "Great Dane Airlines",
  },
  {
    Code: "DX",
    Name: "Danish Air Transport",
  },
  {
    Code: "DY",
    Name: "Norwegian Air Shuttle",
  },
  {
    Code: "DZ",
    Name: "Donghai Airlines",
  },
  {
    Code: "E2",
    Name: "Eurowings Europe",
  },
  {
    Code: "E3",
    Name: "EGO Airways",
  },
  {
    Code: "E4",
    Name: "ATA Aerotaxi Abaete LTDA",
  },
  {
    Code: "E5",
    Name: "Air Arabia Egypt",
  },
  {
    Code: "E7",
    Name: "Equaflight Service",
  },
  {
    Code: "E8",
    Name: "Tayaran Jet JSC",
  },
  {
    Code: "E9",
    Name: "Pan Am Clipper Conx",
  },
  {
    Code: "EB",
    Name: "Wamos Air",
  },
  {
    Code: "ED",
    Name: "Air Explore",
  },
  {
    Code: "EE",
    Name: "Aero Airlines",
  },
  {
    Code: "EF",
    Name: "Xfly",
  },
  {
    Code: "EH",
    Name: "Ana Wings",
  },
  {
    Code: "EI",
    Name: "Aer Lingus",
  },
  {
    Code: "EK",
    Name: "Emirates",
  },
  {
    Code: "EL",
    Name: "Ellinair",
  },
  {
    Code: "EN",
    Name: "Air Dolomiti",
  },
  {
    Code: "EO",
    Name: "Pegas Fly",
  },
  {
    Code: "EP",
    Name: "Iran Aseman Airlines",
  },
  {
    Code: "EQ",
    Name: "Tame Linea Aerea Del Ecuador",
  },
  {
    Code: "ER",
    Name: "Serene Air",
  },
  {
    Code: "ET",
    Name: "Ethiopian Airlines",
  },
  {
    Code: "EU",
    Name: "Chengdu Airlines",
  },
  {
    Code: "EV",
    Name: "Expressjet Airlines",
  },
  {
    Code: "EW",
    Name: "Eurowings",
  },
  {
    Code: "EY",
    Name: "Etihad Airways",
  },
  {
    Code: "EZ",
    Name: "Sun Air of Scandinavia",
  },
  {
    Code: "F1",
    Name: "Farelogix",
  },
  {
    Code: "F3",
    Name: "Flya Deal",
  },
  {
    Code: "F4",
    Name: "Air Flamenco",
  },
  {
    Code: "F6",
    Name: "Fly Compass Service",
  },
  {
    Code: "F7",
    Name: "I Fly",
  },
  {
    Code: "F8",
    Name: "Flair Airlines",
  },
  {
    Code: "F9",
    Name: "Frontier Airlines",
  },
  {
    Code: "FA",
    Name: "Safair",
  },
  {
    Code: "FB",
    Name: "Bulgaria Air",
  },
  {
    Code: "FC",
    Name: "Link Airways Fly FC",
  },
  {
    Code: "FD",
    Name: "Thai Airasia",
  },
  {
    Code: "FE",
    Name: "Seven Four Eight Air Services",
  },
  {
    Code: "FG",
    Name: "Ariana Afghan Airlines",
  },
  {
    Code: "FH",
    Name: "Freebird Airlines",
  },
  {
    Code: "FI",
    Name: "Icelandair",
  },
  {
    Code: "FJ",
    Name: "Fiji Airways",
  },
  {
    Code: "FL",
    Name: "Air Leap Aviation",
  },
  {
    Code: "FM",
    Name: "Shanghai Airlines",
  },
  {
    Code: "FN",
    Name: "Fastjet Zimbabwe",
  },
  {
    Code: "FP",
    Name: "Flypelican",
  },
  {
    Code: "FQ",
    Name: "Flyest Lineas Aereas",
  },
  {
    Code: "FR",
    Name: "Ryanair",
  },
  {
    Code: "FS",
    Name: "Flyr AS, Part-Ops",
  },
  {
    Code: "FT",
    Name: "Fly Egypt",
  },
  {
    Code: "FV",
    Name: "Rossiya-Russian Airlines",
  },
  {
    Code: "FW",
    Name: "Ibex Airlines",
  },
  {
    Code: "FY",
    Name: "Flyfirefly",
  },
  {
    Code: "FZ",
    Name: "Flydubai",
  },
  {
    Code: "G0",
    Name: "Alianza Glancelot",
  },
  {
    Code: "G2",
    Name: "Gullivair",
  },
  {
    Code: "G3",
    Name: "Gol Linhas Aereas S/A",
  },
  {
    Code: "G4",
    Name: "Allegiant Air",
  },
  {
    Code: "G5",
    Name: "China Express Airlines",
  },
  {
    Code: "G6",
    Name: "Fly Arna",
  },
  {
    Code: "G7",
    Name: "Gojet Airlines",
  },
  {
    Code: "G8",
    Name: "GoAir",
  },
  {
    Code: "G9",
    Name: "Air Arabia",
  },
  {
    Code: "GA",
    Name: "Garuda Indonesia",
  },
  {
    Code: "GE",
    Name: "Global Aviation Operation",
  },
  {
    Code: "GF",
    Name: "Gulf Air",
  },
  {
    Code: "GH",
    Name: "Globus Llc",
  },
  {
    Code: "GJ",
    Name: "Zhejiang Loong Airlines",
  },
  {
    Code: "GK",
    Name: "Jetstar Japan",
  },
  {
    Code: "GL",
    Name: "Air Greenland",
  },
  {
    Code: "GM",
    Name: "Chair Airlines",
  },
  {
    Code: "GO",
    Name: "Alianza Glancelot",
  },
  {
    Code: "GP",
    Name: "APG Airlines",
  },
  {
    Code: "GQ",
    Name: "Sky Express",
  },
  {
    Code: "GR",
    Name: "Aurigny Air Services",
  },
  {
    Code: "GS",
    Name: "Tianjin Airlines",
  },
  {
    Code: "GT",
    Name: "Air Guilin",
  },
  {
    Code: "GU",
    Name: "Aviateca",
  },
  {
    Code: "GV",
    Name: "Grant Aviation",
  },
  {
    Code: "GW",
    Name: "Costa Rica Green Airways",
  },
  {
    Code: "GX",
    Name: "GX Airlines",
  },
  {
    Code: "GY",
    Name: "Colorful Guizhou Airline",
  },
  {
    Code: "GZ",
    Name: "Air Rarotonga",
  },
  {
    Code: "H1",
    Name: "Hahn Air",
  },
  {
    Code: "H2",
    Name: "Sky Airline",
  },
  {
    Code: "H4",
    Name: "Hisky Europe SRL",
  },
  {
    Code: "H7",
    Name: "Hisky",
  },
  {
    Code: "H8",
    Name: "Fly Airline PERU",
  },
  {
    Code: "H9",
    Name: "Himalaya Airlines",
  },
  {
    Code: "HA",
    Name: "Hawaiian Airlines",
  },
  {
    Code: "HB",
    Name: "Greater Bay Airlines",
  },
  {
    Code: "HC",
    Name: "Air Senegal",
  },
  {
    Code: "HD",
    Name: "Airdo",
  },
  {
    Code: "HE",
    Name: "Bar Aviation Limited",
  },
  {
    Code: "HF",
    Name: "Air Cote D Ivoire",
  },
  {
    Code: "HH",
    Name: "Qanot Sha",
  },
  {
    Code: "HI",
    Name: "Papillon Airways",
  },
  {
    Code: "HJ",
    Name: "Hellas Jet",
  },
  {
    Code: "HK",
    Name: "Skippers Aviation PTY",
  },
  {
    Code: "HM",
    Name: "Air Seychelles",
  },
  {
    Code: "HN",
    Name: "Equinox Air",
  },
  {
    Code: "HO",
    Name: "Juneyao Airlines",
  },
  {
    Code: "HP",
    Name: "Amapola Flyg AB",
  },
  {
    Code: "HQ",
    Name: "Sino Lac Air",
  },
  {
    Code: "HR",
    Name: "Hahn Air",
  },
  {
    Code: "HS",
    Name: "Heli Securite",
  },
  {
    Code: "HU",
    Name: "Hainan Airlines",
  },
  {
    Code: "HV",
    Name: "Transavia Airlines",
  },
  {
    Code: "HW",
    Name: "North Wright Air",
  },
  {
    Code: "HX",
    Name: "Hong Kong Airlines",
  },
  {
    Code: "HY",
    Name: "Uzbekistan Airways",
  },
  {
    Code: "HZ",
    Name: "Sky Airline",
  },
  {
    Code: "I1",
    Name: "Cts Viaggi",
  },
  {
    Code: "I2",
    Name: "Iberia Express",
  },
  {
    Code: "I3",
    Name: "ATA Airlines",
  },
  {
    Code: "I4",
    Name: "Island Air Express",
  },
  {
    Code: "I5",
    Name: "Airasia India",
  },
  {
    Code: "I6",
    Name: "Mexicana Link",
  },
  {
    Code: "I7",
    Name: "Intermodalidad DE Levante SA",
  },
  {
    Code: "I8",
    Name: "Izhavia",
  },
  {
    Code: "IG",
    Name: "Air Italy",
  },
  {
    Code: "IA",
    Name: "I A W",
  },
  {
    Code: "IB",
    Name: "Iberia",
  },
  {
    Code: "ID",
    Name: "Batik Air Indonesia",
  },
  {
    Code: "IE",
    Name: "Solomon Airlines",
  },
  {
    Code: "IF",
    Name: "Islas Airways",
  },
  {
    Code: "IH",
    Name: "Southern Sky Airlines JSC",
  },
  {
    Code: "IK",
    Name: "Air Kiribati",
  },
  {
    Code: "IL",
    Name: "PT.Trigana Air Service",
  },
  {
    Code: "IN",
    Name: "Nam Air",
  },
  {
    Code: "IO",
    Name: "Iraero",
  },
  {
    Code: "IQ",
    Name: "Qazaq Air",
  },
  {
    Code: "IR",
    Name: "Iran Air",
  },
  {
    Code: "IS",
    Name: "Sepehran Airlines",
  },
  {
    Code: "IT",
    Name: "Tigerair Taiwan",
  },
  {
    Code: "IU",
    Name: "PT. Super Air Jet",
  },
  {
    Code: "IV",
    Name: "GP Aviation",
  },
  {
    Code: "IW",
    Name: "PT Wings Abadi Airlines",
  },
  {
    Code: "IX",
    Name: "Air India Express",
  },
  {
    Code: "IY",
    Name: "Yemenia Yemen Airways",
  },
  {
    Code: "IZ",
    Name: "Arkia",
  },
  {
    Code: "J0",
    Name: "Jam Airlink Express",
  },
  {
    Code: "J2",
    Name: "Azerbaijan Airlines",
  },
  {
    Code: "J3",
    Name: "Northwestern Air Lease",
  },
  {
    Code: "J4",
    Name: "Badr Airlines",
  },
  {
    Code: "J5",
    Name: "Alaska Seaplane Service",
  },
  {
    Code: "J7",
    Name: "Afrijet Business Service",
  },
  {
    Code: "J8",
    Name: "Berjaya Air",
  },
  {
    Code: "J9",
    Name: "Jazeera Airways",
  },
  {
    Code: "JA",
    Name: "JetSmart",
  },
  {
    Code: "JC",
    Name: "Japan Air Commuter",
  },
  {
    Code: "JD",
    Name: "Beijing Capital Airlines",
  },
  {
    Code: "JE",
    Name: "Mango",
  },
  {
    Code: "JF",
    Name: "Jet Asia Airways",
  },
  {
    Code: "JH",
    Name: "Fuji Dream Airlines",
  },
  {
    Code: "JJ",
    Name: "Latam Airlines Brasil",
  },
  {
    Code: "JL",
    Name: "Japan Airlines",
  },
  {
    Code: "JM",
    Name: "Jambojet",
  },
  {
    Code: "JN",
    Name: "Alaska Air Transit",
  },
  {
    Code: "JP",
    Name: "Adria Airways",
  },
  {
    Code: "JQ",
    Name: "Jetstar",
  },
  {
    Code: "JR",
    Name: "Joy Air",
  },
  {
    Code: "JS",
    Name: "Air Koryo",
  },
  {
    Code: "JT",
    Name: "Lion Airlines",
  },
  {
    Code: "JU",
    Name: "Air Serbia",
  },
  {
    Code: "JV",
    Name: "Bearskin Airlines",
  },
  {
    Code: "JW",
    Name: "Vanilla Air",
  },
  {
    Code: "JX",
    Name: "Starlux Airlines",
  },
  {
    Code: "JY",
    Name: "InterCaribbean Airways",
  },
  {
    Code: "K2",
    Name: "Paklook Air",
  },
  {
    Code: "K3",
    Name: "Taquan Air Services",
  },
  {
    Code: "K5",
    Name: "Silverstone Air Services Ltd",
  },
  {
    Code: "K6",
    Name: "Cambodia Angkor Air",
  },
  {
    Code: "K7",
    Name: "Air KBZ",
  },
  {
    Code: "KA",
    Name: "Cathay Dragon",
  },
  {
    Code: "KB",
    Name: "Druk Air",
  },
  {
    Code: "KC",
    Name: "Air Astana",
  },
  {
    Code: "KE",
    Name: "Korean Air",
  },
  {
    Code: "KF",
    Name: "Air Belgium",
  },
  {
    Code: "KG",
    Name: "Key Lime Air Corporation",
  },
  {
    Code: "KI",
    Name: "SKS Airways",
  },
  {
    Code: "KJ",
    Name: "Air Incheon",
  },
  {
    Code: "KK",
    Name: "AtlasGlobal",
  },
  {
    Code: "KL",
    Name: "KLM Royal Dutch Airlines",
  },
  {
    Code: "KM",
    Name: "Air Malta",
  },
  {
    Code: "KN",
    Name: "China United Airlines",
  },
  {
    Code: "KO",
    Name: "OJSC Komiaviatrans",
  },
  {
    Code: "KP",
    Name: "Asky",
  },
  {
    Code: "KQ",
    Name: "Kenya Airways",
  },
  {
    Code: "KR",
    Name: "Cambodia Airways",
  },
  {
    Code: "KT",
    Name: "Mahogany Air",
  },
  {
    Code: "KU",
    Name: "Kuwait Airways",
  },
  {
    Code: "KV",
    Name: "Sky Regional Airlines",
  },
  {
    Code: "KW",
    Name: "Korea Express Air",
  },
  {
    Code: "KX",
    Name: "Cayman Airways",
  },
  {
    Code: "KY",
    Name: "Kunming Airlines",
  },
  {
    Code: "L5",
    Name: "Red Air SRL",
  },
  {
    Code: "L6",
    Name: "Mauritania Airlines",
  },
  {
    Code: "L8",
    Name: "Lulutai Airlines",
  },
  {
    Code: "L9",
    Name: "Lumiwings",
  },
  {
    Code: "LA",
    Name: "Latam Airlines",
  },
  {
    Code: "LF",
    Name: "Contour Airlines",
  },
  {
    Code: "LG",
    Name: "Luxair",
  },
  {
    Code: "LH",
    Name: "Lufthansa",
  },
  {
    Code: "LI",
    Name: "Liat",
  },
  {
    Code: "LJ",
    Name: "Jin Air",
  },
  {
    Code: "LM",
    Name: "Loganair",
  },
  {
    Code: "LN",
    Name: "Libyan Airlines",
  },
  {
    Code: "LO",
    Name: "Lot Polish Airlines",
  },
  {
    Code: "LP",
    Name: "Latam Airlines Peru",
  },
  {
    Code: "LQ",
    Name: "Lanmei Airlines",
  },
  {
    Code: "LR",
    Name: "Avianca Costa Rica SA",
  },
  {
    Code: "LS",
    Name: "Jet2.Com",
  },
  {
    Code: "LT",
    Name: "Llongjiang Airlines",
  },
  {
    Code: "LU",
    Name: "Latam Airlines Chile",
  },
  {
    Code: "LV",
    Name: "Openskies",
  },
  {
    Code: "LW",
    Name: "Lauda Europe",
  },
  {
    Code: "LX",
    Name: "Swiss",
  },
  {
    Code: "LY",
    Name: "El Al Israel Airlines",
  },
  {
    Code: "LZ",
    Name: "Air Link",
  },
  {
    Code: "M0",
    Name: "Aero Mongolia",
  },
  {
    Code: "M2",
    Name: "MHS Aviation",
  },
  {
    Code: "M4",
    Name: "Mistral Air",
  },
  {
    Code: "M5",
    Name: "Kenmore Air",
  },
  {
    Code: "M8",
    Name: "Skyjet",
  },
  {
    Code: "M9",
    Name: "Motor-Sich JSC",
  },
  {
    Code: "MB",
    Name: "MNG Airlines Cargo",
  },
  {
    Code: "MD",
    Name: "Air Madagascar",
  },
  {
    Code: "ME",
    Name: "Middle East Airlines",
  },
  {
    Code: "MF",
    Name: "Xiamen Airlines",
  },
  {
    Code: "MG",
    Name: "Eznis Airways",
  },
  {
    Code: "MH",
    Name: "Malaysia Airlines",
  },
  {
    Code: "MI",
    Name: "Silkair",
  },
  {
    Code: "MJ",
    Name: "Myway Airlines",
  },
  {
    Code: "MK",
    Name: "Air Mauritius",
  },
  {
    Code: "ML",
    Name: "Sky Mali",
  },
  {
    Code: "MM",
    Name: "Peach Aviation",
  },
  {
    Code: "MN",
    Name: "Comair Ltd",
  },
  {
    Code: "MO",
    Name: "Calm Air International",
  },
  {
    Code: "MQ",
    Name: "Envoy Air",
  },
  {
    Code: "MR",
    Name: "Hunnu Air",
  },
  {
    Code: "MS",
    Name: "Egyptair",
  },
  {
    Code: "MT",
    Name: "Thomas Cook Airlines",
  },
  {
    Code: "MU",
    Name: "China Eastern Airlines",
  },
  {
    Code: "MV",
    Name: "Air Mediterranean",
  },
  {
    Code: "MW",
    Name: "Waltzing Matilda Aviation LLC",
  },
  {
    Code: "MX",
    Name: "Breeze Airways",
  },
  {
    Code: "MY",
    Name: "Maswings",
  },
  {
    Code: "MZ",
    Name: "Amakusa Airlines",
  },
  {
    Code: "N2",
    Name: "Aero Contractors Nigeria",
  },
  {
    Code: "N4",
    Name: "Mistral Air",
  },
  {
    Code: "N5",
    Name: "Les Investissements Nolinor",
  },
  {
    Code: "N8",
    Name: "National Airlines",
  },
  {
    Code: "N9",
    Name: "Shree Airlines",
  },
  {
    Code: "NA",
    Name: "Nesma Airlines",
  },
  {
    Code: "NB",
    Name: "Berniq Airways",
  },
  {
    Code: "NC",
    Name: "National Jet Systems",
  },
  {
    Code: "ND",
    Name: "Nordica",
  },
  {
    Code: "NE",
    Name: "Nesma Airlines",
  },
  {
    Code: "NF",
    Name: "Air Vanuatu",
  },
  {
    Code: "NG",
    Name: "Lauda Air",
  },
  {
    Code: "NH",
    Name: "All Nippon Airways",
  },
  {
    Code: "NI",
    Name: "Portugalia",
  },
  {
    Code: "NK",
    Name: "Spirit Airlines",
  },
  {
    Code: "NL",
    Name: "Amelia International",
  },
  {
    Code: "NO",
    Name: "Neos Spa",
  },
  {
    Code: "NP",
    Name: "Nile Air",
  },
  {
    Code: "NQ",
    Name: "Air Japan Company Ltd",
  },
  {
    Code: "NR",
    Name: "Nanta Aviation",
  },
  {
    Code: "NS",
    Name: "Hebei Airlines",
  },
  {
    Code: "NT",
    Name: "Binter Canarias",
  },
  {
    Code: "NU",
    Name: "Japan Transocean Air",
  },
  {
    Code: "NV",
    Name: "Karun Airlines",
  },
  {
    Code: "NX",
    Name: "Air Macau",
  },
  {
    Code: "NZ",
    Name: "Air New Zealand",
  },
  {
    Code: "O2",
    Name: "Linear Air",
  },
  {
    Code: "O4",
    Name: "Orange2fly Airlines",
  },
  {
    Code: "O6",
    Name: "Avianca Brasil",
  },
  {
    Code: "O7",
    Name: "Omni-Blu",
  },
  {
    Code: "OA",
    Name: "Olympic Air",
  },
  {
    Code: "OB",
    Name: "Boliviana De Aviacion",
  },
  {
    Code: "OC",
    Name: "Oriental Air Bridge",
  },
  {
    Code: "OD",
    Name: "Malindo Air",
  },
  {
    Code: "OE",
    Name: "Laudamotion",
  },
  {
    Code: "OF",
    Name: "Overland Airways",
  },
  {
    Code: "OG",
    Name: "Fly PLAY",
  },
  {
    Code: "OH",
    Name: "PSA Airlines.",
  },
  {
    Code: "OI",
    Name: "Hinterland Aviation",
  },
  {
    Code: "OJ",
    Name: "Nyxair OU",
  },
  {
    Code: "OK",
    Name: "Czech Airlines",
  },
  {
    Code: "OL",
    Name: "Samoa Airways",
  },
  {
    Code: "OM",
    Name: "Miat Mongolian Airlines",
  },
  {
    Code: "ON",
    Name: "Naura Airlines",
  },
  {
    Code: "OO",
    Name: "Skywest Airlines",
  },
  {
    Code: "OP",
    Name: "Dac International Airlines",
  },
  {
    Code: "OQ",
    Name: "Chongqing Airlines",
  },
  {
    Code: "OR",
    Name: "TUI Fly Netherlands",
  },
  {
    Code: "OS",
    Name: "Austrian",
  },
  {
    Code: "OT",
    Name: "Tchadia Airlines",
  },
  {
    Code: "OU",
    Name: "Croatia Airlines",
  },
  {
    Code: "OV",
    Name: "SalamAir",
  },
  {
    Code: "OW",
    Name: "Skyward Express",
  },
  {
    Code: "OX",
    Name: "Lattitude Hub (One Airways)",
  },
  {
    Code: "OZ",
    Name: "Asiana Airlines",
  },
  {
    Code: "P0",
    Name: "Proflight Zambia",
  },
  {
    Code: "P1",
    Name: "Publiccharters.Com",
  },
  {
    Code: "P2",
    Name: "Airkenya Express",
  },
  {
    Code: "P4",
    Name: "Air Peace Limited",
  },
  {
    Code: "P5",
    Name: "Aero Republica",
  },
  {
    Code: "P6",
    Name: "Pascan",
  },
  {
    Code: "P8",
    Name: "Sprintair",
  },
  {
    Code: "P9",
    Name: "Peruvian Air Lines",
  },
  {
    Code: "PA",
    Name: "Air Blue",
  },
  {
    Code: "PB",
    Name: "Pal Airlines",
  },
  {
    Code: "PC",
    Name: "Pegasus Airlines",
  },
  {
    Code: "PD",
    Name: "Porter Airlines",
  },
  {
    Code: "PE",
    Name: "Peoples",
  },
  {
    Code: "PF",
    Name: "Air Sial Limited",
  },
  {
    Code: "PG",
    Name: "Bangkok Airways",
  },
  {
    Code: "PH",
    Name: "Pionair Australia",
  },
  {
    Code: "PI",
    Name: "Polar Airlines",
  },
  {
    Code: "PJ",
    Name: "Air Saint Pierre",
  },
  {
    Code: "PK",
    Name: "Pakistan International",
  },
  {
    Code: "PL",
    Name: "Southern Air Charter",
  },
  {
    Code: "PM",
    Name: "CANARY Fly",
  },
  {
    Code: "PN",
    Name: "China West Air",
  },
  {
    Code: "PQ",
    Name: "SkyUp Airlines",
  },
  {
    Code: "PR",
    Name: "Philippine Airlines",
  },
  {
    Code: "PS",
    Name: "Ukraine Intl Airlines",
  },
  {
    Code: "PT",
    Name: "Piedmont Airlines",
  },
  {
    Code: "PU",
    Name: "Plus Ultra Lineas Aereas",
  },
  {
    Code: "PV",
    Name: "Saint Barth Commuter",
  },
  {
    Code: "PW",
    Name: "Precision Air",
  },
  {
    Code: "PX",
    Name: "Air Niugini",
  },
  {
    Code: "PY",
    Name: "Surinam Airways",
  },
  {
    Code: "PZ",
    Name: "Latam Airlines Paraguay",
  },
  {
    Code: "Q1",
    Name: "Sqiva Sistem",
  },
  {
    Code: "Q2",
    Name: "Island Aviation Services",
  },
  {
    Code: "Q3",
    Name: "Anguilla Air Services",
  },
  {
    Code: "Q4",
    Name: "Euro Airlines",
  },
  {
    Code: "Q5",
    Name: "Forty Mile Air",
  },
  {
    Code: "Q6",
    Name: "Volaris Costa Rica",
  },
  {
    Code: "Q8",
    Name: "Trans Air Congo",
  },
  {
    Code: "Q9",
    Name: "Green Africa Airways",
  },
  {
    Code: "QA",
    Name: "Queen Bilqis Airways",
  },
  {
    Code: "QB",
    Name: "Qeshm Air",
  },
  {
    Code: "QC",
    Name: "Camair-Co",
  },
  {
    Code: "QF",
    Name: "Qantas Airways",
  },
  {
    Code: "QG",
    Name: "Citilink Indonesia",
  },
  {
    Code: "QH",
    Name: "Bamboo Airways",
  },
  {
    Code: "QI",
    Name: "IBOM Airlines",
  },
  {
    Code: "QK",
    Name: "Air Canada Jazz",
  },
  {
    Code: "QL",
    Name: "Linea Aerea De Servicio",
  },
  {
    Code: "QM",
    Name: "Monacair",
  },
  {
    Code: "QN",
    Name: "Skytrans",
  },
  {
    Code: "QQ",
    Name: "Alliance Airlines",
  },
  {
    Code: "QP",
    Name: "Akasa Air",
  },
  {
    Code: "QR",
    Name: "Qatar Airways",
  },
  {
    Code: "QS",
    Name: "Smart Wings",
  },
  {
    Code: "QU",
    Name: "Azur Air Ukraine Airlines",
  },
  {
    Code: "QV",
    Name: "Lao Airlines",
  },
  {
    Code: "QW",
    Name: "Qingdao Airlines",
  },
  {
    Code: "QX",
    Name: "Horizon Air",
  },
  {
    Code: "QZ",
    Name: "PT Indonesia Airasia",
  },
  {
    Code: "R0",
    Name: "Tantalus Air",
  },
  {
    Code: "R2",
    Name: "Transair Senegal",
  },
  {
    Code: "R3",
    Name: "JSC Aircompany Yakutia",
  },
  {
    Code: "R4",
    Name: "Real Tonga Our Kingdom Airline",
  },
  {
    Code: "R5",
    Name: "Jordan Aviation",
  },
  {
    Code: "R6",
    Name: "Danu Oro Transportas",
  },
  {
    Code: "R7",
    Name: "Aserca",
  },
  {
    Code: "R8",
    Name: "PUER GENERAL Aviation",
  },
  {
    Code: "RA",
    Name: "Royal Nepal Airlines",
  },
  {
    Code: "RB",
    Name: "Syrian Arab Airlines",
  },
  {
    Code: "RC",
    Name: "Atlantic Airways",
  },
  {
    Code: "RD",
    Name: "Sky Cana",
  },
  {
    Code: "RF",
    Name: "Aero K Airlines",
  },
  {
    Code: "RG",
    Name: "Rotana Jet",
  },
  {
    Code: "RI",
    Name: "Rahila Air",
  },
  {
    Code: "RJ",
    Name: "Royal Jordanian",
  },
  {
    Code: "RK",
    Name: "Ryanair UK",
  },
  {
    Code: "RL",
    Name: "Royal Flight Airlines",
  },
  {
    Code: "RM",
    Name: "Aircompany Armenia",
  },
  {
    Code: "RO",
    Name: "Tarom",
  },
  {
    Code: "RQ",
    Name: "Kam Air",
  },
  {
    Code: "RR",
    Name: "Royal Air Force",
  },
  {
    Code: "RT",
    Name: "JSC UVT Aero",
  },
  {
    Code: "RV",
    Name: "Air Canada Rouge",
  },
  {
    Code: "RW",
    Name: "Royalair philippines",
  },
  {
    Code: "RY",
    Name: "Jiangxi Air",
  },
  {
    Code: "RZ",
    Name: "Sansa",
  },
  {
    Code: "S0",
    Name: "Aerolineas Sosa S.A. DE C.V.",
  },
  {
    Code: "S4",
    Name: "Azores Airlines",
  },
  {
    Code: "S6",
    Name: "Sunrise Airways",
  },
  {
    Code: "S7",
    Name: "Siberia Airlines",
  },
  {
    Code: "S8",
    Name: "Sounds Air",
  },
  {
    Code: "S9",
    Name: "Fly Big",
  },
  {
    Code: "SA",
    Name: "South African Airways",
  },
  {
    Code: "SB",
    Name: "Aircalin",
  },
  {
    Code: "SC",
    Name: "Shandong Airlines",
  },
  {
    Code: "SD",
    Name: "Sudan Airways",
  },
  {
    Code: "SE",
    Name: "XL Airways France",
  },
  {
    Code: "SF",
    Name: "Tassili Airlines",
  },
  {
    Code: "SG",
    Name: "Spicejet",
  },
  {
    Code: "SH",
    Name: "Sharp Aviation",
  },
  {
    Code: "SI",
    Name: "Blue Islands",
  },
  {
    Code: "SJ",
    Name: "Pt Sriwijaya Air",
  },
  {
    Code: "SK",
    Name: "Scandinavian Airlines",
  },
  {
    Code: "SL",
    Name: "Thai Lion",
  },
  {
    Code: "SM",
    Name: "Air Cairo",
  },
  {
    Code: "SN",
    Name: "Brussels Airlines",
  },
  {
    Code: "SO",
    Name: "Sun Air Aviation",
  },
  {
    Code: "SP",
    Name: "SATA Air Acores",
  },
  {
    Code: "SQ",
    Name: "Singapore Airlines",
  },
  {
    Code: "SS",
    Name: "Corsair",
  },
  {
    Code: "ST",
    Name: "Germania",
  },
  {
    Code: "SU",
    Name: "Aeroflot",
  },
  {
    Code: "SV",
    Name: "Saudi Arabian Airlines",
  },
  {
    Code: "SW",
    Name: "Air Namibia",
  },
  {
    Code: "SX",
    Name: "FLYGT",
  },
  {
    Code: "SY",
    Name: "Sun Country",
  },
  {
    Code: "SZ",
    Name: "Somon Air",
  },
  {
    Code: "T0",
    Name: "Avianca Peru S.A",
  },
  {
    Code: "T1",
    Name: "Avtrasoft Limited",
  },
  {
    Code: "T2",
    Name: "Fly ART",
  },
  {
    Code: "T3",
    Name: "Eastern Airways",
  },
  {
    Code: "T4",
    Name: "Trip",
  },
  {
    Code: "T5",
    Name: "Turkmenistan Airlines",
  },
  {
    Code: "T6",
    Name: "Air Swift",
  },
  {
    Code: "T7",
    Name: "Twin Jet",
  },
  {
    Code: "T9",
    Name: "Turpial Airlines",
  },
  {
    Code: "TA",
    Name: "Taca Intl Airlines",
  },
  {
    Code: "TB",
    Name: "Tui Fly Belgium",
  },
  {
    Code: "TC",
    Name: "Air Tanzania",
  },
  {
    Code: "TD",
    Name: "Atlantis European Airway",
  },
  {
    Code: "TF",
    Name: "Braathens Regional Airways AB",
  },
  {
    Code: "TG",
    Name: "Thai Airways",
  },
  {
    Code: "TI",
    Name: "Avtrasoft Limited",
  },
  {
    Code: "TJ",
    Name: "Tradewind Aviation",
  },
  {
    Code: "TK",
    Name: "Turkish Airlines",
  },
  {
    Code: "TL",
    Name: "Airnorth Regional",
  },
  {
    Code: "TM",
    Name: "Lam Mozambique",
  },
  {
    Code: "TN",
    Name: "Air Tahiti Nui",
  },
  {
    Code: "TO",
    Name: "Transavia France",
  },
  {
    Code: "TP",
    Name: "TAP Portugal",
  },
  {
    Code: "TQ",
    Name: "Tailwind Air LLC",
  },
  {
    Code: "TR",
    Name: "Scoot",
  },
  {
    Code: "TS",
    Name: "Air Transat",
  },
  {
    Code: "TT",
    Name: "Tiger Airways Australia",
  },
  {
    Code: "TU",
    Name: "Tunisair",
  },
  {
    Code: "TV",
    Name: "Tibet Airlines",
  },
  {
    Code: "TX",
    Name: "Air Caraibes",
  },
  {
    Code: "TY",
    Name: "Air Caledonie",
  },
  {
    Code: "TZ",
    Name: "Tsaradia",
  },
  {
    Code: "U1",
    Name: "Videcom International",
  },
  {
    Code: "U2",
    Name: "easyJet",
  },
  {
    Code: "U4",
    Name: "Buddha Air",
  },
  {
    Code: "U5",
    Name: "United Nigeria Airlines Company",
  },
  {
    Code: "U6",
    Name: "Ural Airlines",
  },
  {
    Code: "U8",
    Name: "TUS Airways",
  },
  {
    Code: "UA",
    Name: "United Airlines",
  },
  {
    Code: "UB",
    Name: "Myanmar National Airlines",
  },
  {
    Code: "UD",
    Name: "UBD",
  },
  {
    Code: "UE",
    Name: "Ultimate Air Shuttle",
  },
  {
    Code: "UF",
    Name: "Tunis Air Express",
  },
  {
    Code: "UG",
    Name: "Sevenair",
  },
  {
    Code: "UH",
    Name: "Atlasjet Ukraine",
  },
  {
    Code: "UI",
    Name: "AURIC AIR SERVICES LTD",
  },
  {
    Code: "UJ",
    Name: "AL Masria Universal Airlines",
  },
  {
    Code: "UK",
    Name: "Vistara",
  },
  {
    Code: "UL",
    Name: "Srilankan Airlines",
  },
  {
    Code: "UM",
    Name: "Air Zimbabwe",
  },
  {
    Code: "UP",
    Name: "Bahamasair",
  },
  {
    Code: "UQ",
    Name: "Urumqi Airlines",
  },
  {
    Code: "UR",
    Name: "Uganda Airlines",
  },
  {
    Code: "US",
    Name: "Puerto Rico Intl Airlines",
  },
  {
    Code: "UT",
    Name: "Utair Aviation JSC",
  },
  {
    Code: "UO",
    Name: "Hong Kong Express Airways",
  },
  {
    Code: "UU",
    Name: "Air Austral",
  },
  {
    Code: "UX",
    Name: "Air Europa",
  },
  {
    Code: "UY",
    Name: "Cameroon Airlines",
  },
  {
    Code: "UZ",
    Name: "Buraq Air",
  },
  {
    Code: "V0",
    Name: "Conviasa",
  },
  {
    Code: "V3",
    Name: "Carpatair",
  },
  {
    Code: "V4",
    Name: "Vieques Air",
  },
  {
    Code: "V5",
    Name: "Aerovias Dap",
  },
  {
    Code: "V6",
    Name: "VI Airlink",
  },
  {
    Code: "V7",
    Name: "Volotea",
  },
  {
    Code: "V8",
    Name: "Iliamna Air",
  },
  {
    Code: "V9",
    Name: "Van Air Europe",
  },
  {
    Code: "VA",
    Name: "Virgin Australia",
  },
  {
    Code: "VB",
    Name: "Aeroenlaces Nacionales",
  },
  {
    Code: "VC",
    Name: "Via Airlines",
  },
  {
    Code: "VE",
    Name: "Easy Fly S.A.",
  },
  {
    Code: "VF",
    Name: "Fly Armenia Airways",
  },
  {
    Code: "VG",
    Name: "Vipper.com",
  },
  {
    Code: "VH",
    Name: "Viva Air",
  },
  {
    Code: "VJ",
    Name: "Vietjet Air",
  },
  {
    Code: "VM",
    Name: "Max Air",
  },
  {
    Code: "VN",
    Name: "Vietnam Airlines",
  },
  {
    Code: "VO",
    Name: "Voyage Air",
  },
  {
    Code: "VP",
    Name: "Indochina Airlines",
  },
  {
    Code: "VQ",
    Name: "Novo Air",
  },
  {
    Code: "VR",
    Name: "Tacv Cabo Verde Airlines",
  },
  {
    Code: "VS",
    Name: "Virgin Atlantic",
  },
  {
    Code: "VT",
    Name: "Air Tahiti",
  },
  {
    Code: "VU",
    Name: "Vietravel Airlines",
  },
  {
    Code: "VV",
    Name: "Viva Airlines Peru",
  },
  {
    Code: "VW",
    Name: "Aeromar",
  },
  {
    Code: "VX",
    Name: "Virgin America",
  },
  {
    Code: "VY",
    Name: "Vueling Airlines",
  },
  {
    Code: "VZ",
    Name: "Thai Vietjet Air",
  },
  {
    Code: "W1",
    Name: "World Ticket Ltd",
  },
  {
    Code: "W2",
    Name: "Flexflight",
  },
  {
    Code: "W3",
    Name: "Arik Air",
  },
  {
    Code: "W4",
    Name: "Ulendo Airlink",
  },
  {
    Code: "W5",
    Name: "Mahan Airlines",
  },
  {
    Code: "W6",
    Name: "Wizz Air",
  },
  {
    Code: "W9",
    Name: "Wizz Air UK",
  },
  {
    Code: "WA",
    Name: "KLM Cityhopper",
  },
  {
    Code: "WB",
    Name: "Rwand Air",
  },
  {
    Code: "WC",
    Name: "Meregrass",
  },
  {
    Code: "WE",
    Name: "Thai Smile",
  },
  {
    Code: "WF",
    Name: "Wideroe",
  },
  {
    Code: "WG",
    Name: "Sunwing Airlines",
  },
  {
    Code: "WI",
    Name: "Tradewinds Air",
  },
  {
    Code: "WJ",
    Name: "Air Labrador",
  },
  {
    Code: "WK",
    Name: "Edelweiss Air",
  },
  {
    Code: "WM",
    Name: "Windward Island Airways",
  },
  {
    Code: "WN",
    Name: "Southwest Airlines Texas",
  },
  {
    Code: "WO",
    Name: "Swoop Airlines",
  },
  {
    Code: "WP",
    Name: "Air Antwerp",
  },
  {
    Code: "WR",
    Name: "JSC Aviaprad",
  },
  {
    Code: "WS",
    Name: "Westjet",
  },
  {
    Code: "WT",
    Name: "Uepfly (Swift Air)",
  },
  {
    Code: "WU",
    Name: "Western Air",
  },
  {
    Code: "WV",
    Name: "Westair Aviation",
  },
  {
    Code: "WX",
    Name: "Cityjet",
  },
  {
    Code: "WY",
    Name: "Oman Air",
  },
  {
    Code: "WZ",
    Name: "Red Wings Airlines",
  },
  {
    Code: "X3",
    Name: "TUIfly",
  },
  {
    Code: "X4",
    Name: "Air Excursions",
  },
  {
    Code: "X6",
    Name: "Airlines Reporting Corp.",
  },
  {
    Code: "X9",
    Name: "Avion Express",
  },
  {
    Code: "XB",
    Name: "IATA",
  },
  {
    Code: "XC",
    Name: "Corendon Airlines",
  },
  {
    Code: "XF",
    Name: "Mongolian Airways Cargo",
  },
  {
    Code: "XJ",
    Name: "Thai Airasia X Company",
  },
  {
    Code: "XK",
    Name: "Air Corsica",
  },
  {
    Code: "XL",
    Name: "Latam Airlines Ecuador",
  },
  {
    Code: "XN",
    Name: "Travel Express Aviation",
  },
  {
    Code: "XO",
    Name: "seair",
  },
  {
    Code: "XP",
    Name: "Avelo Airlines",
  },
  {
    Code: "XQ",
    Name: "Sun Express",
  },
  {
    Code: "XR",
    Name: "Corendon Airlines Europe",
  },
  {
    Code: "XS",
    Name: "SITA",
  },
  {
    Code: "XT",
    Name: "Indonesia Airasia Extra",
  },
  {
    Code: "XU",
    Name: "African Express Airways",
  },
  {
    Code: "XY",
    Name: "Flynas",
  },
  {
    Code: "XZ",
    Name: "South African Express",
  },
  {
    Code: "Y2",
    Name: "Air Century",
  },
  {
    Code: "Y3",
    Name: "Sky KG Airlines",
  },
  {
    Code: "Y4",
    Name: "Volaris",
  },
  {
    Code: "Y5",
    Name: "Golden Myanmar Airlines",
  },
  {
    Code: "Y6",
    Name: "AB Aviation",
  },
  {
    Code: "Y7",
    Name: "Nordstar",
  },
  {
    Code: "Y8",
    Name: "Suparna Airlines",
  },
  {
    Code: "Y9",
    Name: "Kish Airlines",
  },
  {
    Code: "YA",
    Name: "Nego Airline One",
  },
  {
    Code: "YC",
    Name: "Yamal Airlines",
  },
  {
    Code: "YE",
    Name: "Yan Air",
  },
  {
    Code: "YI",
    Name: "Fly Oya",
  },
  {
    Code: "YJ",
    Name: "Asian Express",
  },
  {
    Code: "YK",
    Name: "Avia Traffic Company",
  },
  {
    Code: "YL",
    Name: "Libyan Wings Airline",
  },
  {
    Code: "YM",
    Name: "Montenegro Airlines",
  },
  {
    Code: "YN",
    Name: "Air Creebec",
  },
  {
    Code: "YO",
    Name: "Heli Air Monaco",
  },
  {
    Code: "YP",
    Name: "Air Premia",
  },
  {
    Code: "YQ",
    Name: "Link Conexion Aerea",
  },
  {
    Code: "YR",
    Name: "Scenic Airlines",
  },
  {
    Code: "YS",
    Name: "Flightlink",
  },
  {
    Code: "YV",
    Name: "Mesa Airlines",
  },
  {
    Code: "YW",
    Name: "Air Nostrum",
  },
  {
    Code: "YX",
    Name: "Republic Airline",
  },
  {
    Code: "Z2",
    Name: "Philippines Airasia",
  },
  {
    Code: "Z3",
    Name: "Bidzy TA Hot Aana Corporation",
  },
  {
    Code: "Z7",
    Name: "Amaszonas Uruguay",
  },
  {
    Code: "Z8",
    Name: "Amaszonas S.A",
  },
  {
    Code: "Z9",
    Name: "JSC Bek Air",
  },
  {
    Code: "ZA",
    Name: "Sky Angkor Airlines",
  },
  {
    Code: "ZB",
    Name: "Air Albania",
  },
  {
    Code: "ZC",
    Name: "FlyAfrica Zimbabwe",
  },
  {
    Code: "ZD",
    Name: "EWA Air",
  },
  {
    Code: "ZE",
    Name: "Eastar Jet",
  },
  {
    Code: "ZF",
    Name: "Azur Air",
  },
  {
    Code: "ZG",
    Name: "Zipair Tokyo",
  },
  {
    Code: "ZH",
    Name: "Shenzhen Airlines",
  },
  {
    Code: "ZL",
    Name: "Regional Express",
  },
  {
    Code: "ZN",
    Name: "Zambia Airways",
  },
  {
    Code: "ZQ",
    Name: "German Airways",
  },
  {
    Code: "ZY",
    Name: "China Air Cargo",
  },
  {
    Code: "ZZ",
    Name: "Airline Service",
  },
  {
    Code: "RX",
    Name: "Regent Airways",
  },
  {
    Code: "QD",
    Name: "Cambodia Intl Airlines",
  },
  {
    Code: "RS",
    Name: "Air Seoul",
  },
  {
    Code: "AG",
    Name: "Aruba Airlines",
  },
  {
    Code: "AN",
    Name: "Advance Air",
  },
  {
    Code: "B0",
    Name: "La Compagnie",
  },
  {
    Code: "ZP",
    Name: "Paran Air",
  },
  {
    Code: "AJ",
    Name: "Aztec Airways",
  },
  {
    Code: "ES",
    Name: "Air Arabia Egypt",
  },
  {
    Code: "TW",
    Name: "Tway Air",
  },
  {
    Code: "3F",
    Name: "Flyone Armenia",
  },
  {
    Code: "CSG",
    Name: "Coupon Spicejet",
  },
  {
    Code: "SSG",
    Name: "SME Spicejet",
  },
  {
    Code: "ESG",
    Name: "Corporate Spicejet",
  },
  {
    Code: "S6E",
    Name: "SME Indigo",
  },
  {
    Code: "E6E",
    Name: "Corporate Indigo",
  },
  {
    Code: "C6E",
    Name: "Coupon Indigo",
  },
  {
    Code: "DH",
    Name: "Norwegian Air Norway",
  },
  {
    Code: "EC",
    Name: "EasyJet Europe",
  },
  {
    Code: "IP",
    Name: "Pelta Airlines",
  },
];

export default airlineDetails;
