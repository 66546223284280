import { useEffect } from "react";

const Kit19Integration = () => {
    useEffect(() => {
        const loadTawkScript = () => {
            const script = document.createElement("script");
            script.src = 'https://embed.tawk.to/67287a762480f5b4f5984751/1ibr1so3k';
            script.async = true;
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');
            document.body.appendChild(script);
        };

        loadTawkScript();

        return () => {
            const scripts = document.querySelectorAll('script[src="https://embed.tawk.to/67287a762480f5b4f5984751/1ibr1so3k"]');
            scripts.forEach((script) => script.remove());
        };
    }, []);

    return (
        <>
        </>
    );
};

export default Kit19Integration;
