import React, { useState, useEffect, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import "./TripTypeRadioButtons.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import tripType from "../../data/TripType";
import moment from "moment";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
function TripTypeRadioButtons({ search, setSearch, t }) {
  const [value, setValue] = useState(search.type);
  const [isErrorShow, serIsErrorShow] = useState(false);
  
  let AllTravelTypeList = tripType;
  if(search.preference.value === "EthnicFare"){
    AllTravelTypeList = tripType.filter(item => item !== "MultiCity");
  }

  const handleChange = (event) => {
    setTripType(event.target.value);
    setValue(event.target.value);
  };

  const setTripType = (value) => {
    const existingSearch = { ...search };
    existingSearch.type = value;
    existingSearch.destinations = [];
    if (existingSearch?.type === "Multidestination") {
      existingSearch?.destinations.push({
        id: existingSearch?.destinations.length + 1,
        from: existingSearch?.to,
        to: "",
        depart: getNextDay(existingSearch?.depart),
        // depart: "",
      });
    }

    if (existingSearch.type === "RoundTrip") {
      // existingSearch.depart = "";
      existingSearch.originDestinations[0].returnDateTime = existingSearch?.originDestinations[0].returnDateTime ? (existingSearch?.originDestinations[0].returnDateTime) : moment(existingSearch?.originDestinations[0].departureDateTime)?.add(1, "d");
    }

    if (existingSearch.type === "MultiCity") {
      if (existingSearch.originDestinations.length === 1) {
        if (
          existingSearch.originDestinations[0].origin === 'From' ||
          existingSearch.originDestinations[0].destination === 'To' ||
          existingSearch.originDestinations[0].origin === '' ||
          existingSearch.originDestinations[0].destination === '' ||
          existingSearch.originDestinations[0].origin === null ||
          existingSearch.originDestinations[0].destination === null
        ) {
          serIsErrorShow(true);
          return;
        }
        existingSearch.originDestinations.push({
          departureDateTime: moment(existingSearch?.originDestinations[existingSearch.originDestinations.length - 1].departureDateTime)?.add(1, "d"),
          destination: 'To',
          origin: existingSearch.originDestinations[existingSearch.originDestinations.length - 1].destination,
          returnDateTime: '',
        })
      }
    }
    setSearch(existingSearch);
  };

  const getNextDay = (day) => {
    const nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 1);
    return nextDay;
  };

  useEffect(() => {
    if (value) {
      if (search.type !== value) {
        setValue(search.type);
      }
    }
  }, [search, value]);

  // Delete "All" From Trip Type
  let toRemove = "All";
  let index = AllTravelTypeList.indexOf(toRemove);
  if (index > -1) {
    AllTravelTypeList.splice(0, 1);
  }

  const handleSnackBarClose = () => {
    serIsErrorShow(false);
  }

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isErrorShow}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose}
          severity="error"
          sx={{ backgroundColor: 'rgb(253, 237, 237) !important'}}
        >
          Origin and destination cannot be left blank. Please fill in both fields to proceed
        </Alert>
      </Snackbar>
      <div className="trip-type-select-width">
        <FormControl component="fieldset">
          <Select
            value={value}
            onChange={handleChange}
            autoWidth
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {AllTravelTypeList.map((item) => (
              <MenuItem value={item}>
                {t(item.trim())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Fragment>
  );
}

export default TripTypeRadioButtons;
