import getFare from "./getFare";
import getTotalFare from "./getTotalFare";
export default function getStartingRates(flights) {
  let nonStopRates = [];
  let oneStopRates = [];
  let moreStopsRate = [];
  let nonStopStartingRate = 0;
  let oneStopStaringRate = 0;
  let moreStopStartingRate = 0;

  flights.forEach((flight) => {
    const maxSegsLength = flight.segGroups.reduce((max, group) => {
      return Math.max(max, group.segs.length);
  }, 0);
  
    flight.segGroups.forEach((segGroup) => {

      if (maxSegsLength === 1) {
        nonStopRates.push(
          getTotalFare(
            flight.fareGroups[0],
            flight.adtNum,
            flight.chdNum,
            flight.infNum
          ).total.toFixed(2));
          //getFare(flight.fareGroups[0].fares[0]));
      } else if (maxSegsLength === 2) {
        oneStopRates.push(
          getTotalFare(
            flight.fareGroups[0],
            flight.adtNum,
            flight.chdNum,
            flight.infNum
          ).total.toFixed(2));
          //getFare(flight.fareGroups[0].fares[0]));
      } else {
        moreStopsRate.push(
          getTotalFare(
            flight.fareGroups[0],
            flight.adtNum,
            flight.chdNum,
            flight.infNum
          ).total.toFixed(2));
          //getFare(flight.fareGroups[0].fares[0]));
      }
    });
  });
  nonStopStartingRate = Math.min(...nonStopRates);
  oneStopStaringRate = Math.min(...oneStopRates);
  moreStopStartingRate = Math.min(...moreStopsRate);
  return {
    nonStopStartingRate,
    oneStopStaringRate,
    moreStopStartingRate,
  };
}
