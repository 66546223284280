import React from 'react'
import { Box } from "@mui/material";
import Button from "@mui/joy/Button";
import { Table } from "@mui/joy";
import Typography from "@mui/material/Typography";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

const ReissueSupplierFare = ({ bookingDetails, t, handleInputChange, reissueInputUpdates
}) => {
    return (
        <div>
            {bookingDetails &&
                bookingDetails?.flightBookingStatus &&
                (bookingDetails?.flightBookingStatus === "ReIssue_Requested" ||
                    bookingDetails?.flightBookingStatus === "ReIssued" ||
                    bookingDetails?.flightBookingStatus === "ReIssue_Pending" ||
                    bookingDetails?.flightBookingStatus === "ReIssue_Rejected") && (
                    //(bookingDetails.flight[0].segGroups && bookingDetails.flight[0].segGroups.length > 1))  && (
                    <>
                        {/* <div className='itenary-container booking-padding-top-40 flight-itenary-header'>

                            <div className="d-flex align-items-center">
                                <div className='mt-4'>
                                    {t("Supplier Fare (Reissue Fare Input)")} -
                                </div>
                                <div className='mt-4'>
                                    {bookingDetails &&
                                        bookingDetails?.flight[0] &&
                                        bookingDetails?.flight[0]?.currency ? (
                                        <p className="ms-1"
                                            style={{
                                                fontSize: "20px",
                                                marginBottom: "0px",
                                            }}>
                                            {bookingDetails?.flight[0]?.currency}
                                        </p>
                                    ) : (
                                        <p> </p>
                                    )}
                                </div>
                            </div>
                            <Box sx={{ textAlign: 'right' }}>
                                <Button
                                    variant="outlined"
                                    className="addButton mt-3"
                                    color="primary"
                                    startIcon={<ContentPasteGoIcon />}
                                    onClick={() => {
                                        setTicketModalOpen(true);
                                    }}
                                >
                                    {t("View and Paste Ticket")}
                                </Button>
                                {isEditorUpdated &&
                                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }} className="error">{t("Ticket image pasted successfully.")}</Typography>
                                }
                            </Box>
                        </div> */}


                        <div className='flight-itenary-header mt-5'>
                            <div
                                className='flight-itenary-title-container' >
                                <div className=''>
                                    {t(bookingDetails.flightBookingStatus === "ReIssued"
                                        ? "New Supplier Fare"
                                        : "Supplier Fare (Reissue Fare Input)"
                                    )}
                                </div>
                            </div>
                            <div className=''>
                                {bookingDetails &&
                                    bookingDetails?.supplierCurrency ? (
                                    <p className="ms-1"
                                        style={{
                                            fontSize: "20px",
                                            marginBottom: "0px",
                                        }}>
                                        {bookingDetails?.supplierCurrency}
                                    </p>
                                ) : (
                                    <p> </p>
                                )}
                            </div>
                        </div>

                        <div className='reissue-supplierfare'>
                            <Table className='textCenter itenary-passenger-table'>
                                <thead className="mui-table-header">

                                    <th class="text-center">
                                        {t("PaxType")}
                                    </th>
                                    <th class="text-center">
                                        {t("BaseFare")}
                                    </th>
                                    <th class="text-center">
                                        {t("Taxes")}
                                    </th>
                                    <th class="text-center">
                                        {t("DateChangeFee")}
                                    </th>
                                    <th class="text-center">
                                        {t("VendorFee")}
                                    </th>
                                    <th class="text-center">
                                        {t("VendorTax")}
                                    </th>
                                    <th class="text-center">
                                        {t("Baggage/SSR")}
                                    </th>
                                    <th class="text-center">
                                        {t("MarkUp")}
                                    </th>
                                    <th class="text-center">
                                        {t("GFSServiceFee")}
                                    </th>
                                    <th class="text-center">
                                        {t("SupplierFare(perPax)")}
                                    </th>
                                    <th class="text-center">
                                        {t("SupplierTotal")}
                                    </th>
                                </thead>
                                <tbody>
                                    {reissueInputUpdates
                                        .sort((a, b) => {
                                            const order = { ADT: 1, CHD: 2, INF: 3 };
                                            return (order[a.paxType] || 4) - (order[b.paxType] || 4);
                                        })
                                        .map((reissueInput, index) => (
                                            <tr className='itenary-passenger-table-item price-row' key={index}>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {reissueInput.paxType} * {
                                                        reissueInput.paxType === "ADT" ? bookingDetails.adtCount :
                                                            reissueInput.paxType === "CHD" ? bookingDetails.chdCount :
                                                                reissueInput.paxType === "INF" ? bookingDetails.infCount :
                                                                    bookingDetails.adtCount
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.baseFare}
                                                            onChange={(e) => handleInputChange(index, 'baseFare', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.baseFare.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.tax}
                                                            onChange={(e) => handleInputChange(index, 'tax', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.tax.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.dateChangeFee}
                                                            onChange={(e) => handleInputChange(index, 'dateChangeFee', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.dateChangeFee.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.vendorIssuanceFee}
                                                            onChange={(e) => handleInputChange(index, 'vendorIssuanceFee', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.vendorIssuanceFee.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.vendorTax}
                                                            onChange={(e) => handleInputChange(index, 'vendorTax', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.vendorTax.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.baggagefare}
                                                            disabled
                                                            onChange={(e) => handleInputChange(index, 'baggagefare', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.baggagefare.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.markup}
                                                            onChange={(e) => handleInputChange(index, 'markup', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.markup.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {bookingDetails?.flightBookingStatus !== "ReIssued" ?
                                                        <input
                                                            type='number'
                                                            value={reissueInput.gfsServiceFee}
                                                            onChange={(e) => handleInputChange(index, 'gfsServiceFee', parseFloat(e.target.value))}
                                                            style={{
                                                                width: "60px",
                                                                height: "30px",
                                                                padding: "5px",
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        : reissueInput.gfsServiceFee.toFixed(2)
                                                    }
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {Number(reissueInput.supplierTotal).toFixed(2)}
                                                </td>
                                                <td className='width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12' align='center'>
                                                    {Number(reissueInput.supplierNetTotal).toFixed(2)}
                                                </td>
                                            </tr>
                                        ))}

                                    <tr className='itenary-passenger-table-item price-row'>
                                        <td align='center' className="fw600">Total</td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.baseFare || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.tax || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.dateChangeFee || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.vendorIssuanceFee || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.vendorTax || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.baggagefare || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            -
                                        </td>
                                        <td align='center' className="fw600">
                                            -
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.supplierTotal || 0), 0).toFixed(2)}
                                        </td>
                                        <td align='center' className="fw600">
                                            {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.supplierNetTotal || 0), 0).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className="itenary-passenger-table-item price-row">
                                        <td
                                            colSpan={11}
                                            align=""
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                                        >
                                            <span className="fs12">
                                                <span className="colorRed">*</span>
                                                Baggage Amount is averaged value of all added baggage of pax
                                                to match single pax Fare
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </>
                )}
        </div>
    )
}

export default ReissueSupplierFare