import React from 'react'
import { checkIfUserStaff } from '../../services/AuthService';
import { Table } from "@mui/joy";

const ReissueClientFare = ({ bookingDetails, t, reissueInputUpdates, clientData, isStaff }) => {
    return (
        <div>
            <div className='flight-itenary-header mt-5' >
                <div className='flight-itenary-title-container'>
                    {t("Client Fare")}
                </div>
                <div className=''>
                    {bookingDetails &&
                        bookingDetails?.clientCurrency ? (
                        <p className="ms-1"
                            style={{
                                fontSize: "20px",
                                marginBottom: "0px",
                            }}>
                            {bookingDetails?.clientCurrency}
                        </p>
                    ) : (
                        <p> </p>
                    )}
                </div>
            </div>

            <div className='reissue-clientfare'>
                <Table className='textCenter  itenary-passenger-table'>
                    <thead className="mui-table-header">
                        {clientData && <th class="text-center">
                            {t("PaxType")}
                        </th>}
                        <th class="text-center">
                            {t("BaseFare")}
                        </th>
                        <th class="text-center">
                            {t("Taxes")}
                        </th>
                        <th class="text-center">
                            {t("DateChangeFee")}
                        </th>
                        <th class="text-center">
                            {t("Baggage/SSR")}
                        </th>
                        <th class="text-center">
                            {t("GFSServiceFee")}
                        </th>
                        <th class="text-center">
                            {t("ClientFare(perPax)")}
                        </th>
                        <th class="text-center">
                            {t("ClientTotal")}
                        </th>
                    </thead>
                    <tbody className='itenary-passenger-table-item price-row'>
                        {clientData && clientData.length > 0 ? (
                            <>
                                {clientData
                                    .sort((a, b) => {
                                        const order = { ADT: 1, CHD: 2, INF: 3 };
                                        return (order[a.paxType] || 4) - (order[b.paxType] || 4);
                                    })

                                    .map((reissueInput, index) => (
                                        <tr key={index}>
                                            <td align='center'>
                                                {reissueInput.paxType} * {
                                                    reissueInput.paxType === "ADT" ? bookingDetails.adtCount :
                                                        reissueInput.paxType === "CHD" ? bookingDetails.chdCount :
                                                            reissueInput.paxType === "INF" ? bookingDetails.infCount :
                                                                bookingDetails.adtCount}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? Number(reissueInput.clientBaseFare).toFixed(2) : (reissueInput.baseFare !== undefined ? Number(reissueInput.baseFare).toFixed(2) : "0.00")}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? Number(reissueInput.clientTax).toFixed(2) : (reissueInput.taxes !== undefined ? Number(reissueInput.taxes).toFixed(2) : "0.00")}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? Number(reissueInput.clientDateChangeFee).toFixed(2) : (reissueInput.dateChangeFee !== undefined ? Number(reissueInput.dateChangeFee).toFixed(2) : "0.00")}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? Number(reissueInput.clientBaggage).toFixed(2) : (reissueInput.ssrFee !== undefined ? Number(reissueInput.ssrFee).toFixed(2) : "0.00")}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? Number(reissueInput.clientGfsServiceFee).toFixed(2) : (reissueInput.gfsServiceFee !== undefined ? Number(reissueInput.gfsServiceFee).toFixed(2) : "0.00")}
                                            </td>
                                            <td align='center'>
                                                {Number(reissueInput.clientTotal)?.toFixed(2) || "0.00"}
                                            </td>
                                            <td align='center'>
                                                {isStaff ? (Number(reissueInput.clientNetTotal)?.toFixed(2) || "0.00") :
                                                    ((Number(reissueInput.clientTotal)?.toFixed(2) * reissueInput.paxCount) || "0.00")}
                                            </td>
                                        </tr>
                                    ))}

                                {/* Total Row */}
                                <tr className='fw600'>
                                    <td align='center' className="fw600">Total</td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientBaseFare || input.baseFare || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientTax || input.taxes || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientDateChangeFee || input.dateChangeFee || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientBaggage || input.ssrFee || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientGfsServiceFee || input.gfsServiceFee || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {clientData.reduce((total, input) => total + parseFloat(input.clientTotal || 0), 0).toFixed(2)}
                                    </td>
                                    <td align='center' className="fw600">
                                        {isStaff
                                            ? clientData.reduce((total, input) => total + parseFloat(input.clientNetTotal || 0), 0).toFixed(2)
                                            : clientData.reduce((total, input) => total + (parseFloat(input.clientTotal || 0) * input.paxCount), 0).toFixed(2)}
                                    </td>
                                </tr>
                                {checkIfUserStaff() &&
                                    <tr className="itenary-passenger-table-item price-row">
                                        <td
                                            colSpan={8}
                                            align=""
                                            className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 import-header-item-font-weight"
                                        >
                                            <span className="fs12">
                                                <span className="colorRed">*</span>
                                                Baggage Amount is averaged value of all added baggage of pax
                                                to match single pax Fare
                                            </span>
                                        </td>
                                    </tr>}
                            </>
                        ) : (
                            <tr>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                                <td align='center'>0.00</td>
                            </tr>
                        )}


                    </tbody>
                </Table>
            </div>
        </div >
    )
}

export default ReissueClientFare