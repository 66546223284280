import { createSlice } from "@reduxjs/toolkit";

export const TraceIdSlice = createSlice({
    name: "traceIds",
    initialState: {
        value: "", // Default value for traceId
    },
    reducers: {
        setTraceIds: (state, action) => {
            state.value = action.payload; // Update the traceId
        },
        clearTraceIds: (state) => {
            state.value = ""; // Clear the traceId
        },
    },
});

export const { setTraceIds, clearTraceIds } = TraceIdSlice.actions;

export default TraceIdSlice.reducer;
