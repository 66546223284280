import React, { useEffect, useState, useRef } from "react";
import "./FSH.css";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from "react-router-dom";
// import { RiseLoader } from "react-spinners";
import RiseLoading from "../../Loader/RiseLoader";
import FSHSearch from "./Search/FSHSearch";
import dayjs from "dayjs";
import MuiAlert from "@material-ui/lab/Alert";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import Skeleton from "@material-ui/lab/Skeleton";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function FSH() {
  let fshExportData = [];
  const csvLink = useRef();
  const history = useHistory();
  const today = dayjs();
  const [fsh, setFsh] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [clientId, setClientId] = useState("");
  const [userId, setUserId] = useState(null);
  const [fromDate, setFromDate] = useState(today.$d);
  const [toDate, setToDate] = useState(today.$d);
  const [exportresults, setExportResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientProfiles, setClientProfiles] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [traceId, setTraceId] = useState("");
  const collections = [
    {
      id: 1,
      collectionName: 'Collection 1 - From 13Sep2024'
    },
    {
      id: 2,
      collectionName: 'Collection 2 - Upto 11Sep2024'
    }
  ];
  const [collection, setCollection] = useState(collections[0]);


  const handleChangePage = (event, newPage) => {
    loadFlightSearchDetails("next", newPage + 1);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    getClients();
  }, [])

  useEffect(() => {
    setShowFilter(true);
  }, [clientProfiles])



  useEffect(() => {
    if (
      fromDate.getDate() <= toDate.getDate() &&
      fromDate.getMonth() <= toDate.getMonth() &&
      fromDate.getFullYear() <= toDate.getFullYear()
    ) {
      setErrorMessage("");
    } else if (fromDate > toDate) {
      setErrorMessage("From Date cannot be greater than To Date");
    } else {
      setErrorMessage("");
    }
  }, [fromDate, toDate]);
  function formatDate(date) {
    let date1 = new Date(date);
    let date2 = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
      10,
      30,
      0
    );
    const date3 = new Date(date2);
    const yyyy = date3.getFullYear();
    let mm = date3.getMonth() + 1;
    let dd = date3.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formatedDate = yyyy + "-" + mm + "-" + dd;
    return formatedDate;
  }
  async function loadFlightSearchDetails(flag, pgNo) {
    let frmDate = formatDate(fromDate);
    let tDate = formatDate(toDate);
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/FlightSearchHistory",
        JSON.stringify({
          pageSize: pageSize,
          pageNumber: flag === "next" ? pgNo : 1,
          origin: traceId ? null : origin ? origin : null,
          destination: traceId ? null : destination ? destination : null,
          clientId: traceId ? null : clientId ? clientId : 0,
          userId: userId ? userId : null,
          fromDate: traceId ? null : fromDate ? frmDate : null,
          toDate: traceId ? null : toDate ? tDate : null,
          traceId: traceId ? traceId : "",
          collection: collection.id
        }),
        {
          headers,
        }
      )
      .then((response) => {

        setShowLoader(false);
        console.log(response.data.flightHistoryRequest);
        setTotalCount(response.data.count);
        if (
          flag === "next" &&
          response?.data?.flightHistoryRequest &&
          response?.data?.flightHistoryRequest?.length > 0
        ) {
          setPageNumber((pageNumber) => pgNo);
          setFsh((fsh) => response.data.flightHistoryRequest);
        } else if (
          flag === "next" &&
          response?.data?.flightHistoryRequest?.length === 0
        ) {
          setAlert({
            open: true,
            message: "No data Found",
            severity: "error",
          });
        } else if (
          flag !== "next" &&
          response?.data?.flightHistoryRequest &&
          response?.data?.flightHistoryRequest?.length > 0
        ) {
          setFsh((fsh) => response.data.flightHistoryRequest);
          setPageNumber((pageNumber) => 1);
        } else if (
          flag !== "next" &&
          response?.data?.flightHistoryRequest &&
          response?.data?.flightHistoryRequest?.length === 0
        ) {
          setFsh((fsh) => []);
          setPageNumber((pageNumber) => 1);
        }
      })
      .catch((error) => {

        setShowLoader(false);
        setAlert({
          open: true,
          message:
            "Error while exporting data, pls Modify Search Criteria",
          severity: "error",
        });
      });
    setShowLoader(false);
  }

  function handleViewPage(id) {
    let link = "/FSHDetails?id=" + id;
    window.open(link, "_blank", "noreferrer");
  }

  let myqueueExportExcel = async () => {

    let frmDate = formatDate(fromDate);
    let tDate = formatDate(toDate);
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/FlightSearchHistory",
        JSON.stringify({
          pageSize: 63000,
          pageNumber: 1,
          origin: origin ? origin : null,
          destination: destination ? destination : null,
          clientId: clientId ? clientId : 0,
          userId: userId ? userId : null,
          fromDate: fromDate ? frmDate : null,
          toDate: toDate ? tDate : null,
          traceId: traceId ? traceId : "",
          collection: collection.id
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (
          response?.data?.flightHistoryRequest &&
          response?.data?.flightHistoryRequest?.length > 0
        ) {
          let temp = response?.data?.flightHistoryRequest;
          var resultlist = [];
          temp.forEach((item) => {
            const SalesData = {
              clientId: item.clientId,
              Origin: item.origin,
              Destination: item.destination,
              SearchedOn: item.searchDate
                ? item.searchDate.split("T")[0] +
                " " +
                item.searchDate.split("T")[1].split(".")[0]
                : "",
              OnwardDate: item.onwardDate ? item.onwardDate.split("T")[0] : "",
              ReturnDate: item.isReturn ? item.returnDate.split("T")[0] : "",
              PaxCount:
                "A-" +
                item.adultCount +
                " C-" +
                item.childCount +
                " I-" +
                item.infantCount,
              TravelType: item.airTravelType,
              CabinClass: item.cabinClass,
              ResponseTime: item.responseTime,
              TraceID: item.traceId,
            };
            resultlist.push(SalesData);
          });
          setExportResults(resultlist);

          setTimeout(() => {
            csvLink.current.link.click();
            if (response.data.count >= 63000) {
              setAlert({
                open: true,
                message:
                  "Results consists of more than 60K Records, pls Modify Search Criteria",
                severity: "error",
              });
            }
            setShowLoader(false);
          }, 5000);
        } else {
          setAlert({
            open: true,
            message:
              "Error while exporting data,Results consists of more than 60K Records, pls Modify Search Criteria",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setAlert({
          open: true,
          message:
            "Error while exporting data,Results consists of more than 60K Records, pls Modify Search Criteria",
          severity: "error",
        });
      });
  };
  useEffect(() => {
    console.log("exportresults", exportresults);
  }, [exportresults]);

  async function getClients() {
    await axios
      // .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
      )
      .then((response) => {
        // console.log("clientProfiles", response);
        let tempArray = response.data.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );
        setClientProfiles((clientProfiles) => structuredClone(tempArray));
      });
  }


  return (
    <Container style={{ marginTop: "20px" }}>
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <div className="search-heading" style={{ marginBottom: "10px" }}>
        Flight Search History
      </div>



      {clientProfiles !== undefined && clientProfiles?.length > 0 ? (
        <div className="importbooking-search-search-container">
          <div className="importbooking-search-table-container">
            <div className="search-title-add-button-container">
              <div className="search-heading"></div>
            </div>
            <div className="importbooking-search-card-container">
              <FSHSearch
                origin={origin}
                destination={destination}
                fromDate={fromDate}
                errorMessage={errorMessage}
                toDate={toDate}
                clientId={clientId}
                userId={userId}
                setOrigin={setOrigin}
                setDestination={setDestination}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setClientId={setClientId}
                setUserId={setUserId}
                loadFlightSearchDetails={loadFlightSearchDetails}
                clientProfiles={clientProfiles}
                traceId={traceId}
                setTraceId={setTraceId}
                collection={collection}
                setCollection={setCollection}
                collections={collections}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="table-loading">
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
            <div style={{ width: "100%", margin: "5px" }}>
              <Skeleton width="100%" height={24} animation="wave" />
            </div>
          </div>
        </div>
      )}


      {fsh.length === 0 ? (
        <TableContainer component={Paper}>
          <Table style={{ width: "100%" }}>
            <TableRow>
              <TableCell component="th" scope="row" align="justify">
                <Alert severity="error">No record found.</Alert>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      ) : (
        <>
          {" "}
          <div className="">
            <div className="sidebar-heading-fsh">
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  color: "white",
                  backgroundColor: "#9f155a",
                  marginBottom: "1vh",
                  float: "right",
                }}
                onClick={() => myqueueExportExcel()}
              >
                Export Queue
              </Button>
              <CSVLink
                className="hidden"
                ref={csvLink}
                target="_blank"
                data={exportresults}
                filename="FSH-Export-Data"
              />
            </div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Origin</TableCell>
                    <TableCell align="center">Destination</TableCell>
                    <TableCell align="center">Number of Results</TableCell>
                    <TableCell align="center">Searched On</TableCell>
                    <TableCell align="center">Onward date</TableCell>
                    <TableCell align="center">Pax Count</TableCell>
                    <TableCell align="center">CabinClass</TableCell>
                    <TableCell align="center">Travel Type</TableCell>
                    <TableCell align="center">Response(ms)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fsh.map((row) => (
                    <StyledTableRow
                      key={JSON.stringify(row)}
                      onClick={() => handleViewPage(row.id)}
                    >
                      <TableCell align="center">
                        {row.origin}({row.originCountry})
                      </TableCell>
                      <TableCell align="center">
                        {row.destination}({row.destinationCountry})
                      </TableCell>
                      <TableCell align="center">
                        {row.numberOfResults}
                      </TableCell>
                      <TableCell align="center">
                        {row.searchDate.split("T")[0]}{" "}
                        {row.searchDate.split("T")[1].split(".")[0]}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.onwardDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        A{row.adultCount}-C{row.childCount}-I{row.infantCount}
                      </TableCell>
                      <TableCell align="center">{row.cabinClass}</TableCell>
                      <TableCell align="center">{row.airTravelType}</TableCell>
                      <TableCell align="center">{row.responseTime}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50]}
              component="div"
              count={totalCount}
              rowsPerPage={pageSize}
              page={pageNumber - 1}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
            />
          </div>
        </>
      )}

      {showLoader && <RiseLoading FSH={showLoader} />}
    </Container>
  );
}

export default FSH;
