import React, { useState, useEffect } from "react";
import "./ManageProfile.css";
import axios, { Axios } from "axios";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { getAllPermissions, checkIfUserStaff, isClientUserHasPermission, getCurrentRole } from "../../services/AuthService";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import { MenuItem, Select } from "@mui/material";
import Languages from "../../data/Languages"
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router";
function ManageProfile() {
  const useStyles = makeStyles((theme) => ({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const classes = useStyles();
  const location = useLocation();
  const [userinfo, setuserinfo] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [languageDropDown, setlanguageDropDown] = useState(false);
  const [preferedLanguage, setPreferedLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  async function getUser() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "connect/userinfo")
      .then((response) => {
        setuserinfo(response.data);
        setPreferedLanguage((preferedLanguage) => response?.data?.language ? (response?.data?.language).toLowerCase() : 'en')
        localStorage.setItem("i18nextLng", response?.data?.language ? (response?.data?.language).toLowerCase() : 'en');
        setlanguageDropDown((languageDropDown) => response?.data?.language ? false : true)
        i18n.changeLanguage(response?.data?.language ? (response?.data?.language).toLowerCase() : 'en');
        getClients(response.data.clientref_id);
      });
  }
  async function getClients(getClientId) {
    let filter = "id==" + getClientId;
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        filter
      )
      .then((response) => {
        console.log(response);
        setClientName(response?.data[0]?.clientpforile.clientName);
      });
  }
  useEffect(() => {
    getUser();
    setUserRole(getCurrentRole());
  }, []);
  useEffect(() => {
    console.log("preferedLanguage", preferedLanguage)
    console.log("languageDropDown", languageDropDown)
  }, [preferedLanguage, languageDropDown])
  const getFullName = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["full_name"] && user["full_name"].length > 0
      ? user["full_name"]
      : "?";
  };

  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user["role"];
  };

  const history = useHistory();
  const openMarkupPage = () => {
    history.push("/AgentMarkup");
  };

  const openPassengerProfilePage = () => {
    Clarity.event("New_Traveler_Profile_Add", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    history.push("/PassengerProfile");
  };
  const handleChange = (event) => {
    setPreferedLanguage(event.target.value);

  };
  const updatePreferedLanguage = async () => {
    try {
      let response = await axios.put(process.env.REACT_APP_CORE_URL + "ids/User/UpdateUserLanguage", {
        "Email": userinfo.email_id,
        "Language": preferedLanguage
      })
      if (response) {
        localStorage.setItem("i18nextLng", preferedLanguage);
        i18n.changeLanguage(preferedLanguage);
        setlanguageDropDown((languageDropDown) => false)
        alert("Updated Successfully!!")
      } else {
        setlanguageDropDown((languageDropDown) => true)
        alert("error occured")
      }
    } catch (error) {
      setlanguageDropDown((languageDropDown) => true)
      console.log(error)
      alert("error occured")
    }
  }
  return (
    <div className="manage-profile-container">
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <div className="profile-header-user-details-container">
            <div className="profile-header-user-details-avatar-container">
              <Avatar className="avatar-component">
                {getFullName().trim()[0]}
              </Avatar>
            </div>
            <div className="profile-header-user-details-detailed">
              <div className="profile-header-user-details-username">
                {getFullName()}
              </div>
              <div className="profile-header-user-details-user-role">
                {getUserRole()}
              </div>
            </div>
          </div>
          <div className="profile-item">
            <Grid container>
              <Grid item xs={6}>
                <div className="profile-item-title">{t("User Details")}</div>
              </Grid>
              <Grid item xs={6}>
                <div className="profile-item-user-details-container">
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      {t("Full Name")}
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.full_name : ""}
                    </div>
                  </div>
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      {t("User Name")}
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.preferred_username : ""}
                    </div>
                  </div>
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">{t("Email")}</div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.email_id : ""}
                    </div>
                  </div>
                  {/* <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">
                      Phone number
                    </div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? 123456789 : ""}
                    </div>
                  </div> */}
                  <div className="profile-item-user-details">
                    <div className="profile-item-user-details-text">{t("Role")}</div>
                    <div className="profile-item-user-details-subtext">
                      {userinfo ? userinfo.role : ""}
                    </div>
                  </div>
                  {userinfo && userinfo.clientref_id ? (
                    <div className="profile-item-user-details">
                      <div className="profile-item-user-details-text">
                        {t("Client ID")}
                      </div>
                      <div className="profile-item-user-details-subtext">
                        {userinfo.clientref_id}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {userinfo && preferedLanguage ? (
                    <div className="profile-item-user-details">

                      {
                        languageDropDown ? (
                          <>
                            <div className="profile-item-user-details-text">
                              {t("Language")}
                            </div>

                            <div className="profile-item-user-details-subtext">
                              <Select value={preferedLanguage} onChange={handleChange}
                                autoWidth
                                className="languageSelect"
                                style={{ paddingLeft: '0' }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },

                                  getContentAnchorEl: null,
                                }}>
                                {Languages.map((item) => (
                                  <MenuItem value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Button
                                variant="contained"
                                className={`fs10 fw500`}
                                onClick={updatePreferedLanguage}
                                disableElevation
                                size="small"
                                class
                                style={{
                                  width: "5vw",
                                  padding: "5px",
                                  textTransform: "capitalize",
                                  fontSize: '10px',
                                  backgroundColor: "#D82A61",
                                  color: "#fff",
                                  borderRadius: "50px",
                                  border: "none"
                                }}
                              >
                                {t('Update')}
                              </Button>

                            </div>
                          </>
                        )
                          :
                          <>
                            <div className="profile-item-user-details-text">
                              {t("Language")}
                            </div>
                            <div className="profile-item-user-details-subtext">
                              {Languages.find(lang => lang.value === preferedLanguage)?.label}
                              <EditIcon style={{ paddingLeft: '1vw', color: 'var(--bs-pink)' }} onClick={() => setlanguageDropDown((languageDropDown) => true)} />
                            </div>
                          </>
                      }
                    </div>
                  ) : ''
                  }

                  {clientName ? (
                    <div className="profile-item-user-details">
                      <div className="profile-item-user-details-text">
                        {t("Client Name")}
                      </div>
                      <div className="profile-item-user-details-subtext">
                        {clientName}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="profile-item">
            <Grid container>
              <Grid item xs={6}>
                <div className="profile-item-title">{t("Permissions")}</div>
                {!checkIfUserStaff() && (
                  <>
                    {(isClientUserHasPermission("IsMarkUp") || userRole === "ClientSuperAdmin") && (
                      <div onClick={openMarkupPage} className="d-flex justify-content-between markup-btn">
                        <div>
                          {t("Mark Up")}
                        </div>
                        <div>
                          <BadgeOutlinedIcon />
                        </div>
                      </div>
                    )}
                    <div onClick={openPassengerProfilePage} className="d-flex justify-content-between markup-btn">
                      <div>
                        {t("Traveller Profile")}
                      </div>
                      <div>
                        <BadgeOutlinedIcon />
                      </div>
                    </div>


                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {getAllPermissions() && <div className="profile-item-user-details-container profile-items-flex">
                  <div className="profile-item-user-details table-column-group">
                    {getAllPermissions() &&
                      getAllPermissions().map((row) => (
                        <Chip className="custom-role-chip" label={row} />
                      ))}
                  </div>
                </div>}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

export default ManageProfile;
