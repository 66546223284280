

export default function getBaggageFilter(flights) {
    const seenBags = new Set();
    const result = [];

    flights.forEach(item => {
        item.fareGroups.forEach(fareGroup => {
            fareGroup.baggages.filter(baggage => baggage.paxType === "ADT").forEach(baggage => {
                const checkInBagValue = baggage.checkInBag.split(',')[0].replace(/\s+/g, '').trim().toUpperCase();                
                if (!seenBags.has(checkInBagValue)) {
                    seenBags.add(checkInBagValue);
                    result.push({
                        checkInBag: checkInBagValue,
                        checked: false,
                    });
                }
            });
        });
    });

    result.sort((a, b) => {
        const extractNumber = (str) => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0], 10) : Infinity;
        };

        const isPC = (str) => str.includes("PC");
        const isKG = (str) => str.includes("KG");

        if (isPC(a.checkInBag) && !isPC(b.checkInBag)) return -1;
        if (!isPC(a.checkInBag) && isPC(b.checkInBag)) return 1;
        return extractNumber(a.checkInBag) - extractNumber(b.checkInBag);
    });

    return result;
}
