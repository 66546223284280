import { createContext, useContext, useEffect, useState } from "react";
import browserSignature from "browser-signature";

const BrowserSignatureContext = createContext(null);

export const BrowserSignatureProvider = ({ children }) => {
    const [signature, setSignature] = useState(null);
    console.log('signature', signature)
    useEffect(() => {
        let storedSignature = localStorage.getItem("browserId");
        if (!storedSignature) {
            storedSignature = browserSignature();
            localStorage.setItem("browserId", storedSignature);
        }
        setSignature(storedSignature);
    }, []);

    return (
        <BrowserSignatureContext.Provider value={signature}>
            {children}
        </BrowserSignatureContext.Provider>
    );
};

export const useBrowserSignature = () => useContext(BrowserSignatureContext);
