import React, { useEffect, useState } from "react";
import "./ClientRoleDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function ClientRoleDrawer({
  toggleDrawer,
  handleSnackbarToggle,
  setPopUpMsg,
  setUpdateType,
  getRoles,
  clientID,
  setErrMsg
}) {
  const [roleName, setroleName] = useState();
const {t} = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  async function handleSubmit() {
    Clarity.event("Access_Add_roles", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);

   setUpdateType("Role");
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/" +
        roleName,
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        handleSnackbarToggle();
        setroleName("");
        setPopUpMsg(true);
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle();
        setPopUpMsg(false);
        setroleName("");
        setErrMsg(err.response.data.detail);
      });

    // your submit logic
    getRoles();
  }


  return (
    <div className="add-client-role-drawer">
      <div className="close-btn-add-crole">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>

      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmit}
      >
        <div className="configuration-heading-container configuration-title-flex-container">
          <div>{t("Add New Role")}</div>
        </div>
        <div className="configuration-heading-container configuration-title-flex-container">
          <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
            <TextValidator
              variant="outlined"
              label={t("Role Name")}
              size="small"
              color="secondary"
              value={roleName}
              onChange={(event) => setroleName(event.target.value)}
              validators={["required"]}
              errorMessages={["This Field is Required."]}
            />
          </div>
        </div>
        <div className="configuration-heading-container configuration-title-flex-container">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{
              textTransform: "none",
            }}
            type="submit"
          >
            {t("Add Role")}
          </Button>
        </div>
      </ValidatorForm>

    </div>
  );
}
