import axios from 'axios';

const logFrontEndData = async (logData) => {
    const payload = {
        traceid: logData.traceid,
        clientUser: logData.clientUser?.toString(),
        browserID: navigator.userAgent,
        dateTime: new Date(),
        date: new Date().toISOString().split('T')[0],
        currentpage: logData.currentpage,
        currentaction: logData.currentaction,
        ApiUrl: logData.ApiUrl,
        errorCode: logData.errorCode?.toString(),
        errorDescription: logData.errorDescription,
        ErrorMessage: logData.ErrorMessage ?? '',
        Payload: logData.Payload ?? '',
    };

    const headers = {
        "Content-Type": "application/json",
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CORE_URL}api/Configuration/ElasticLog/FrontEndLogs`,
            JSON.stringify(payload),
            { headers }
        );

        if (typeof response.data === "string") {
            console.log(JSON.parse(response.data));
        } else {
            console.log(response.data);
        }
    } catch (error) {
        console.log(error);
    }
};

export default logFrontEndData;