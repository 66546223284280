import React, { Fragment, useState, useEffect, useRef } from "react";
import "./DebitTransactions.css";
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../services/AuthService";
import useConfigurationTableCell from "../../hooks/useConfigurationTableCell";
import { getCurrentClientID } from "../../../src/services/AuthService";
import Alert from "@material-ui/lab/Alert";
import { isUserHasPermission } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { formatDate } from "../../services/dateFormatter";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import RiseLoading from "../Loader/RiseLoader";
import { useTranslation } from "react-i18next";
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function DebitTransactions() {
  const { t } = useTranslation();
  let { id } = useParams();
  const location = useLocation();
  const csvLink = useRef();
  const [clientProfiles, setclientProfiles] = useState(null);
  const [clientId, setClientId] = useState(0);
  const [DateFrom, setDateFrom] = useState();
  const [DateTo, setDateTo] = useState();
  const [inputDateFrom, setinputDateFrom] = useState();
  const [inputDateTo, setinputDateTo] = useState();
  const [transactions, settransactions] = useState(null);
  const history = useHistory();
  const [totalCount, setTotalCount] = useState();
  const [clientBalance, setclientBalance] = useState();
  const [clinetProfile, setclinetProfile] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [exportresults, setExportResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateBtnDisabled, setisUpdateBtnDisabled] = useState(true);
  const [validationmessage, setValidationMessage] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const StyledTableCell = useConfigurationTableCell;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const userRole = getCurrentRole();
    if (
      isUserHasPermission("DebitTransactionRead") ||
      isUserHasPermission("ClientRead") || isClientUserHasPermission("IsManageTransaction")
      || userRole === "ClientSuperAdmin"
    ) {
      if (getCurrentClientID() !== "0") {
        if (id !== getCurrentClientID()) {
          id = getCurrentClientID();
        }
      }
      getClients();
    } else {
      if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || checkIfUserStaff() || userRole === "ClientSuperAdmin") {
        history.push("/flight");
      }
      else {
        history.push("/Unauthorized");
      }
    }
    checkPermissions();
  }, []);


  const checkPermissions = () => {
    const userRole = getCurrentRole();
    if ((!checkIfUserStaff() && isClientUserHasPermission("IsTransactionReport")) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
      setIsDisabled(false);
    }
    else {
      setIsDisabled(true);
    }
  }

  async function getClients() {
    setShowLoader(true);
    let filter = "Id==" + id,
      url = "";
    if (checkIfUserStaff()) {
      url =
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile/GetClientList?sorts=created";
    } else {
      url =
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created";
    }

    if (id > 0) {
      url = url + "&filters=" + filter;
    }
    try {
      await axios.get(url).then((response) => {
        setShowLoader(false);
        if (checkIfUserStaff()) {
          setclientProfiles(response.data);
        } else {
          setclientProfiles(response.data[0]);
        }

        if (id > 0) {
          setClientId(id);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }


  async function getClientBalance() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/Transactions/GetAccountBanalce?clientId=" +
        clientId
      )
      .then((response) => {
        setclientBalance(response.data.balance);
      });
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  async function submitTransactions() {
    Clarity.event("Manage_Transaction_Search", location.pathname);
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    setShowLoader(true);
    setisUpdateBtnDisabled(true);
    let filter = "";
    filter =
      "ClientId==" +
      clientId +
      ",Created>=" +
      moment(inputDateFrom).format("MM/DD/YYYY") +
      ",Created<=" +
      moment(addDays(inputDateTo, 1)).format("MM/DD/YYYY");
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/Transactions?filters=" +
        filter
      )
      .then((response) => {
        setisUpdateBtnDisabled(false);
        setPage(0);
        setTotalCount(response.data.length);
        settransactions(response.data);
        if (clientId > 0) {
          getClientBalance();
        }
        if (checkIfUserStaff()) {
          setclinetProfile(
            clientProfiles.find((element) => {
              return element.id === clientId;
            })
          );
        }
        setShowLoader(false);
        setisUpdateBtnDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }
  const validateDate = () => {
    let valid = true;
    if (!clientId) {
      setValidationMessage("Please select Client.");
    } else {
      if (
        (inputDateTo === undefined || inputDateTo === null) &&
        (inputDateFrom === null || inputDateFrom === undefined)
      ) {
        setValidationMessage("");
      } else if (
        new Date(inputDateFrom) !== null &&
        (inputDateTo === undefined || inputDateTo === null)
      ) {
        setValidationMessage(t("Please select To Date."));
        valid = false;
      } else if (
        (inputDateFrom === null || inputDateFrom === undefined) &&
        new Date(inputDateTo) !== null
      ) {
        setValidationMessage(t("Please select From Date."));
        valid = false;
      } else if (new Date(inputDateFrom) == new Date(inputDateTo)) {
        valid = true;
        setValidationMessage("");
      } else if (new Date(inputDateFrom) > new Date(inputDateTo)) {
        setValidationMessage(t("From date should not be greater than To Date"));
        valid = false;
      } else {
        setValidationMessage("");
      }
    }
    return valid;
  };
  useEffect(() => {
    if (validateDate()) {
      setisUpdateBtnDisabled(
        clientId && inputDateFrom && inputDateTo ? false : true
      );
      if (!clientId) {
        setValidationMessage(t("Please select Client."));
      }
    }
  }, [clientId, inputDateFrom, inputDateTo]);

  let myqueueExportExcel = () => {
    if (!isDisabled) {
      setIsLoading(() => true);
      var resultList = [];
      transactions.forEach((element) => {
        let temp = {
          "Order Ref Id": element.orderRefId,
          "Transaction Details": element.description,
          "Client Id": element.clientId,
          "Transaction Date": moment(element.created).format("DD/MM/YYYY"),
          Type: element.transactionType,
          Amount: element.amount,
          "Running balance": element.runningBalance,
          Remarks: element.remarks ? element.remarks : "",
          "Added By": element.createdBy ? element.createdBy : ''
          // PCC: element.PCC,
          // Origin: element.Origin,
          // OriginCountry: element.OriginCountry,
          // Destination: element.Destination,
          // DestinationCountry: element.DestinationCountry,
          // ClientType: element.ClientType,
        };
        resultList.push(temp);
      });
      setExportResults(resultList);
      setTimeout(() => {
        csvLink.current.link.click();
        setIsLoading(() => false);
      }, 5000);
    }
  };

  return (
    <Container>
      <div className="trans-head-container">
        <div className="trans-heading">{t("Client Transactions")}</div>
      </div>

      <div>
        {clientProfiles && (
          <>
            <div className="trans-client-container">
              {
                checkIfUserStaff() &&
                <>
                  <div className="trans-client-container-label trans-client-container-labelLeft">
                    {t("Select Client")}
                  </div>
                  <div className="trans-client-container-item2 debit-transaction-panel">
                    <Autocomplete
                      id="combo-box-demo"
                      value={
                        // clientProfiles &&
                        // (clientProfiles.find((v) => v.clientpforile.id === clientId) ||
                        //   null)
                        clientProfiles &&
                        (clientProfiles.find((v) => v.id === clientId) || null)
                      }
                      onChange={(event, newValue) => {
                        //setClientId(newValue ? newValue.clientpforile.id : 0);
                        setClientId(newValue ? newValue.id : 0);
                        // checkAllValuesEntered()
                      }}
                      options={clientProfiles}
                      //getOptionLabel={(option) => option.clientpforile.clientName+' ('+option.clientpforile.id+')'}
                      getOptionLabel={(option) =>
                        option.clientName + " (" + option.id + ")"
                      }
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          value={clientId}
                          {...params}
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </div>
                </>
              }
              <div className={`trans-client-container-label ${checkIfUserStaff() === false && 'trans-client-container-labelLeft'}`}>{t("From Date")}</div>
              <div className="trans-client-container-item2">
                <Fragment>
                  <KeyboardDatePicker
                    id="DateFrom"
                    autoOk
                    margin="normal"
                    maxDate={new Date()}
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    value={DateFrom !== undefined ? DateFrom : null}
                    inputValue={DateFrom !== undefined ? DateFrom : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setDateFrom(date ? formatDate(date) : null);
                      setinputDateFrom(date ? date : null);
                      // checkAllValuesEntered()
                    }}
                    required
                  />
                </Fragment>
              </div>
              <div className="trans-client-container-label">{t("To Date")}</div>
              <div className="trans-client-container-item2">
                <Fragment>
                  <KeyboardDatePicker
                    id="DateTo"
                    autoOk
                    margin="normal"
                    maxDate={new Date()}
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    value={DateTo !== undefined ? DateTo : null}
                    inputValue={DateTo !== undefined ? DateTo : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setDateTo(date ? formatDate(date) : null);
                      setinputDateTo(date ? date : null);
                    }}
                    required
                  />
                </Fragment>
              </div>

              <button
                className="trans-client-container-search-button"
                onClick={() => submitTransactions()}
                disabled={isUpdateBtnDisabled}
              >
                <p style={{ margin: 0 }}>
                  <SearchIcon />
                  {t("Search")}
                </p>
              </button>
            </div>
          </>
        )}
        {/* {checkIfUserStaff() == false && (
          <>
            <div className="trans-client-container pad15">
              <div className="trans-client-container-label fromDate">
                From Date
              </div>
              <div className="trans-client-container-item2">
                <Fragment>
                  <KeyboardDatePicker
                    id="DateFrom"
                    autoOk
                    margin="normal"
                    variant="inline"
                    format="dd/MM/yyyy"
                    size="small"
                    maxDate={new Date()}
                    value={DateFrom !== undefined ? DateFrom : null}
                    inputValue={DateFrom !== undefined ? DateFrom : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setDateFrom(date ? formatDate(date) : null);
                      setinputDateFrom(date ? date : null);
                      // checkAllValuesEntered()
                    }}
                    required
                  />
                </Fragment>
              </div>
              <div className="trans-client-container-label">To Date</div>
              <div className="trans-client-container-item2">
                <Fragment>
                  <KeyboardDatePicker
                    id="DateTo"
                    autoOk
                    margin="normal"
                    variant="inline"
                    maxDate={new Date()}
                    format="dd/MM/yyyy"
                    style={{ borderRadius: "10px" }}
                    className="ToDate"
                    size="small"
                    value={DateTo !== undefined ? DateTo : null}
                    inputValue={DateTo !== undefined ? DateTo : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setDateTo(date ? formatDate(date) : null);
                      setinputDateTo(date ? date : null);
                      // checkAllValuesEntered()
                    }}
                    required
                  />
                </Fragment>
              </div>
              <button
                className="trans-client-container-search-button"
                onClick={() => submitTransactions()}
                disabled={isUpdateBtnDisabled}
              >
                <p style={{ margin: 0 }}>
                  <SearchIcon />
                  Search
                </p>
              </button>
            </div>
          </>
        )} */}
        <div className="invalidDate">{validationmessage}</div>
      </div>

      <div className="trans-data">
        {transactions ? (
          <React.Fragment>
            <div className="trans-client-balance">
              <div className="trans-client-balance-label">{t("Client Balance")}</div>
              {checkIfUserStaff() == true ? (
                <>
                  <div className="trans-client-balance-Amount">
                    {clinetProfile && clinetProfile.currencycode}{" "}
                    {clientBalance}
                  </div>
                </>
              ) : (
                <>
                  <div className="trans-client-balance-Amount">
                    {clientProfiles &&
                      clientProfiles.clientpforile.currencyCode}{" "}
                    {clientBalance}
                  </div>
                </>
              )}
              <div
                className={`trans-client-container-export-button 
                  ${isDisabled ? 'disabled' : ''}`}
                onClick={() => myqueueExportExcel()}
              >
                {t("Export Transactions")}
              </div>

              <CSVLink
                className="hidden"
                ref={csvLink}
                target="_blank"
                data={exportresults}
                filename="Credit Report"
              />
            </div>
            <div className="sidebar-heading-right"></div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("Order")}</TableCell>
                    <TableCell align="center">{t("Service Type")}</TableCell>
                    <TableCell align="center">{t("Transaction Details")}</TableCell>
                    <TableCell align="center">{t("Client")}</TableCell>
                    <TableCell align="center">{t("Transaction Date")}</TableCell>
                    <TableCell align="center">{t("Type")}</TableCell>
                    <TableCell align="center">{t("Amount")}</TableCell>
                    <TableCell align="center">{t("Running Balance")}</TableCell>
                    <TableCell align="center">{t("Added By")}</TableCell>
                    {checkIfUserStaff() === true ? (
                      <TableCell align="center" width="250px">
                        {t("Remarks")}
                      </TableCell>
                    ) : (
                      " "
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalCount === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        colSpan={7}
                      >
                        <Alert severity="error">{t("No record found.")}</Alert>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    transactions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow key={JSON.stringify(row)}>
                          <TableCell align="center">{row.orderRefId}</TableCell>
                          <TableCell align="center">{row.orderRefId.startsWith('GF') ? 'Flight' : row.orderRefId.startsWith('GR') ? 'Rail' : ''}</TableCell>
                          <TableCell align="center">{row.description}</TableCell>
                          <TableCell align="center">{row.clientId}</TableCell>
                          <TableCell align="center">
                            {moment(row.created).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {row.transactionType === 'CreditCard' ? 'Credit Card' : row.transactionType}
                          </TableCell>
                          <TableCell align="center">{row.amount}</TableCell>
                          <TableCell align="center">
                            {row.runningBalance}
                          </TableCell>
                          <TableCell align="center">
                            {row.createdBy}
                          </TableCell>
                          {checkIfUserStaff() === true ? (
                            <TableCell display="flex" align="center">
                              {row.remarks}
                            </TableCell>
                          ) : (
                            " "
                          )}
                        </StyledTableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 25, 35]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
            />
          </React.Fragment>
        ) : (
          <>
            {" "}
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("Order")}</TableCell>
                    <TableCell align="center">{t("Service Type")}</TableCell>
                    <TableCell align="center">{t("Transaction Details")}</TableCell>
                    <TableCell align="center">{t("Client")}</TableCell>
                    <TableCell align="center">{t("Transaction Date")}</TableCell>
                    <TableCell align="center">{t("Type")}</TableCell>
                    <TableCell align="center">{t("Amount")}</TableCell>
                    <TableCell align="center">{t("Running Balance")}</TableCell>
                    <TableCell align="center">{t("Added By")}</TableCell>
                    <TableCell align="center" width="250px">
                      {t("Remarks")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="center"
                      colSpan={10}
                    >
                      <Alert severity="error">{t("No record found")}.</Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 25, 35]}
              component="div"
              count={0}
              rowsPerPage={15}
              page={1}
            />
          </>
        )}
      </div>
      {showLoader && <RiseLoading DebitTransactions={showLoader} />}
      {isLoading && <RiseLoading excelExport={isLoading} />}
    </Container>
  );
}

export default DebitTransactions;
