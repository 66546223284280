import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { OutlinedInput, InputAdornment } from "@material-ui/core";

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    return (
        <>
            <IconButton
                onClick={(event) => onPageChange(event, 0)}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={(event) => onPageChange(event, page - 1)}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={(event) => onPageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={(event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}

export default function ATCConfigTable({ handleAtcRowClick, categories }) {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    const [searchedVal, setSearchedVal] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    // Filtered categories based on search input
    const filteredCategories = categories.filter(({ airline }) =>
        searchedVal === "" || airline.toLowerCase().includes(searchedVal.toLowerCase())
    );

    // Ensure `page` is within valid range
    React.useEffect(() => {
        if (page > 0 && page * rowsPerPage >= filteredCategories.length) {
            setPage(Math.max(0, Math.ceil(filteredCategories.length / rowsPerPage) - 1));
        }
    }, [filteredCategories.length, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="search-category-listing">
            <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} className="py-2">
                                <OutlinedInput
                                    id="keyword-search"
                                    onChange={(e) => { setSearchedVal(e.target.value); setPage(0); }}
                                    placeholder="Keyword Search by Airline"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Airlines - Code</TableCell>
                            <TableCell>Auto Ticket Changer Status</TableCell>
                            <TableCell>Last Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCategories.length > 0 ? (
                            filteredCategories
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((group, index) => (
                                    <StyledTableRow key={group.id}>
                                        <StyledTableCell>{index + 1 + page * rowsPerPage}</StyledTableCell>
                                        <StyledTableCell>
                                            <div
                                                className="table-cell-title pointer"
                                                onClick={() => handleAtcRowClick(group)}
                                            >
                                                {group.airline}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Chip
                                                label={group.isActive ? "Enabled" : "Disabled"}
                                                color="primary"
                                                size="small"
                                                style={{ backgroundColor: group.isActive ? "green" : "red" }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <div className="table-cell-description">Created by: <span style={{ color: "black" }}>{group.createdBy}</span></div>
                                            <div className="table-cell-description">Created on: {group.created.replace('T', " , ")}</div>
                                            <div className="table-cell-description">Last Modified by: <span style={{ color: "black" }}>{group.modifiedBy}</span></div>
                                            <div className="table-cell-description">Last Modified on: {group.modified.replace('T', " , ")}</div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={4} align="center">
                                    <Alert severity="error">No record found.</Alert>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredCategories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </div>
    );
}
