import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import getTotalFare from "../../functions/getTotalFare";
import { useTranslation } from "react-i18next";
import getTotalFareWithAkbarMarkup from "../../functions/getTotalFareWithAkbarMarkup";
export default function BrandedFareHeadTable({
  result,
  selectedItinerary,
  setSelectedItinerary,
  adultAkbarMarkup,
  childAknarMarkup,
  infantAknarMarkup,
  getStructuredFareRule,
  value
}) {
  const { t } = useTranslation();
  return (
    <div className="brandFareDetailsDiv">
      <Table className="fullHeight fareDetailsTable mb-3">
        <TableHead>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              {" "}
              <p>{t("Brand Type")}</p>
            </TableCell>
            {result.fareGroups.map((item, index) => (
              <TableCell
                className="fareDetailsHeadCell"
                onClick={() => {
                  if (result.vendor === "1S") {
                    getStructuredFareRule(item.purchaseId);
                  }
                  setSelectedItinerary(index);
                }}
              >
                <p> {item?.priceClass?.toLowerCase()}</p>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              {t("Selection")}
            </TableCell>
            {result.fareGroups.map((item, index) => (
              <TableCell
                className="fareDetailsBodyCell"
                onClick={() => {
                  if (result.vendor === "1S" && (value === "Cancellation" || value === "Date Change")) {
                    getStructuredFareRule(item.purchaseId);
                  }
                  setSelectedItinerary(index);
                }}
              >
                {index === selectedItinerary ? (
                  <p className="fs16 textTransCap ">&#9989;</p>
                ) : (
                  <p className="fs14 textTransCap colorBlack">
                    <AdjustIcon style={{ width: "18px" }} />
                  </p>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              {" "}
              <p>{t("Fares")}</p>
            </TableCell>
            {result.fareGroups.map((item, index) => {
              let adultBaseFare = 0;
              let childBaseFare = 0;
              let infantBaseFare = 0;
              let adultTaxPrice = 0;
              let childTaxPrice = 0;
              let infantTaxPrice = 0;

              item.fares.forEach((fare) => {
                if (fare.paxType === "INF") {
                  infantBaseFare = infantBaseFare + fare.base;
                  fare.taxes.forEach((item) => {
                    infantTaxPrice = infantTaxPrice + item.amt;
                  });
                } else if (fare.paxType === "CHD") {
                  childBaseFare = childBaseFare + fare.base;
                  fare.taxes.forEach((item) => {
                    childTaxPrice = childTaxPrice + item.amt;
                  });
                } else {
                  adultBaseFare = adultBaseFare + fare.base;
                  fare.taxes.forEach((item) => {
                    adultTaxPrice = adultTaxPrice + item.amt;
                  });
                }
              });

              adultBaseFare = adultBaseFare + (adultAkbarMarkup * (result.markupROE ? result.markupROE : 0));
              childBaseFare = childBaseFare + (childAknarMarkup * (result.markupROE ? result.markupROE : 0));
              infantBaseFare = infantBaseFare + (infantAknarMarkup * (result.markupROE ? result.markupROE : 0));

              return (
                <TableCell className="fareDetailsBodyCell">
                  <p>
                    {" "}
                    {getTotalFareWithAkbarMarkup(
                      adultBaseFare,
                      childBaseFare,
                      infantBaseFare,
                      adultTaxPrice,
                      childTaxPrice,
                      infantTaxPrice,
                      result.adtNum,
                      result.chdNum,
                      result.infNum
                    ).toFixed(2)}
                  </p>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      </Table>
    </div >
  );
}
