import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import RiseLoading from "../../../Loader/RiseLoader";
import airlineDetails from "../../../../data/AirlineDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import cabinClassList from "../../../../data/CabinClass";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import countries from "../../../../data/Country";


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function FoidConfigDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  loadFoidConfigurations,
  vendorList,
}) {
  const classes = useStyles();

  const [showLoader, setShowLoader] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [vendor, setVendor] = useState([]);
  const [airline, setAirline] = useState("");
  const [gds, setGds] = useState([]);
  const [foidPermit, setFoidPermit] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [exIndia, setExIndia] = useState(false);
  const [rafFees, setRafFees] = useState(0);
  const [cabinClass, setcabinClass] = useState([]);
  const [tax, setTax] = useState(0);
  const [taxType, setTaxType] = useState("");
  const [applicableOn, setApplicableOn] = useState("");
  const [status, setStatus] = useState("");
  const [foidType, setFoidType] = useState("passport");
  const [originCountry, setoriginCountry] = useState([]);
  const [destinationCountry, setdestinationCountry] = useState([]);
      
useEffect(() => {
  console.log('selectedItem', selectedItem);
  if (action === "edit" || action === "view") {
    const selectedAirline =
      airlineDetails.find((v) => v.Code === selectedItem.airlines) || null;
    setAirline(selectedAirline);
    setFoidType(selectedItem.foidType);
    setFoidPermit(selectedItem.isMandatory ? "Yes" : "No");
    setStatus(selectedItem.isActive ? "Active" : "In-Active");

    // Map origin and destination country codes to country objects

    // Handle originCountry
    let originCountryObjs = [];
    if (selectedItem.originCountry) {
      const originCountryCodes = selectedItem.originCountry.split(",");
      originCountryObjs = originCountryCodes
        .map((code) => countries.find((country) => country.code === code))
        .filter(Boolean); // filter out undefined values
    }


    let destinationCountryObjs = [];
    if (selectedItem.destinationCountry) {
      const destinationCountryObj = selectedItem.destinationCountry.split(",");
      destinationCountryObjs = destinationCountryObj
        .map((code) => countries.find((country) => country.code === code))
        .filter(Boolean); // filter out undefined values
    }

 // Set the mapped country objects into the state
    setoriginCountry(originCountryObjs);
    setdestinationCountry(destinationCountryObjs);

  } else {
    setAirline("");
    setFoidType("passport");
    setFoidPermit("");
    setStatus("");
    setoriginCountry([]);
    setdestinationCountry([]);
  }
}, [toggleDrawer, action]);

  async function handleUpdate() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };

    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/FoidConfiguration/" +
            selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: status === "Active" ? true : false,
            airlines: airline.Code,
            foidType: foidType,
            isMandatory: foidPermit === "Yes" ? true : false,
            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(destinationCountry, "countrycode"),

          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
          setShowLoader(false);
          loadFoidConfigurations();
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
            "api/Configuration/FoidConfiguration",
          JSON.stringify({
            isActive: status === "Active" ? true : false,
            airlines: airline.Code,
            foidType: foidType,
            isMandatory: foidPermit === "Yes"  ? true : false,
            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(destinationCountry, "countrycode"),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
          loadFoidConfigurations();
          setErrMsg("");
        })
        .catch((err) => {
          console.log(err);
          // handleSnackbarToggle();
          setShowLoader(false);
          setErrMsg("*" + err.response.data.detail);
        });
    }
  }

  

  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
        if (field === "countrycode") {
        arrayList.map(arr => (strValue === "" ? strValue = arr.code : strValue = strValue + "," + arr.code));
      }    
    }
    return strValue;
  }

const handleRadioChange = (event) => {
    setFoidType(event.target.value);
  };




  return (
    <div className="ticket-drawer-container atc-config-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="search-heading-container search-title-flex-container">
        <div>
          {action === "edit" ? "Edit" : action === "new" ? "Add" : ""} Foid
          Configuration
        </div>
        {action === "view" && (
          <div className="search-heading-icons">
            {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
            <IconButton fontSize="small">
              <EditRoundedIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditRoundedIcon>
            </IconButton>
            {/* ) : (
                            ""
                        )} */}
          </div>
        )}
      </div>

      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="search-view-edit-drawer-container">
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendor-multi-select"
                  value={
                    airlineDetails.find((v) => v.Code === airline?.Code) || null
                  }
                  onChange={(event, value) => {
                    setAirline(value);
                  }}
                  options={airlineDetails}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Select Airlines"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Airline</div>
                <div className="view-supplier-drawer-description">
                  {airline.Name}
                </div>
              </div>
            )}
          </div>

          {/* OriginCOuntry: */}
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
               <Autocomplete
                  id="origincountry select"
                  multiple
                  value={originCountry && originCountry}
                  onChange={(event, value) => {
                    setoriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  getOptionDisabled={(option) => {
                    // If "All" is selected, disable all other options except "All"
                    if (originCountry.some((item) => item.code === 'All') && option.code !== "All") {
                      return true; // Disable all options except "All"
                    }
                    // If any country (other than "All") is selected, disable "All"
                    if (originCountry.length > 0 && option.code === "All") {
                      return true; // Disable "All" if any country is selected
                    }
                    return false; // Enable all other options by default
                  }}
                  
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Country"
                      value={originCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: originCountry && originCountry.length === 0,
                      }}
                      required={true}
                    />
                  )}
                 
                />
              </div>
            )}
            {action === "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Origin Country</div>
                <div className="view-supplier-drawer-description">
                {originCountry !== undefined &&
                    originCountry.length > 0 &&
                    originCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>

          {/* Desitination COuntry: */}

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
              <Autocomplete
                  id="destinationcountry select"
                  multiple
                  value={destinationCountry && destinationCountry}
                  onChange={(event, value) => {
                    setdestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  getOptionDisabled={(option) => {
                    // If "All" is selected, disable all other options except "All"
                    if (destinationCountry.some((item) => item.code === 'All') && option.code !== "All") {
                      return true; // Disable all options except "All"
                    }
                    // If any country (other than "All") is selected, disable "All"
                    if (destinationCountry.length > 0 && option.code === "All") {
                      return true; // Disable "All" if any country is selected
                    }
                    return false; // Enable all other options by default
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Country"
                      value={destinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required:
                          destinationCountry && destinationCountry.length === 0,
                      }}
                      required={true}
                    />
                  )}
               
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title"> Destination Country</div>
                <div className="view-supplier-drawer-description">
                {destinationCountry !== undefined &&
                    destinationCountry.length > 0 &&
                    destinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container ">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <FormControl  style={{ display: 'flex', flexDirection:'row', alignItems:'center'}}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Foid Type:
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={foidType}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                    style={{marginLeft:'20px'}}
                      value={foidType}
                      control={<Radio />}
                      label="Passport"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title" style={{ marginRight: '8px' }}>Foid Type</div>
                <div className="view-supplier-drawer-description">
                  {foidType}
                </div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Status"
                  value={foidPermit}
                  onChange={(event, newValue) => setFoidPermit(newValue)}
                  options={["Yes", "No"]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Mandatory"
                      variant="outlined"
                      color="secondary"
                      required
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                     Mandatory
                </div>
                <div className="view-supplier-drawer-description">
                   {foidPermit}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Status"
                  value={status}
                  onChange={(event, newValue) => setStatus(newValue)}
                  options={["Active", "In-Active"]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Status"
                      variant="outlined"
                      color="secondary"
                      required
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Status</div>
                <div className="view-supplier-drawer-description">
                  {status}
                </div>
              </div>
            )}
          </div>
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <RiseLoading Search={showLoader} />}
            <div className="ms-5 t text-danger">{errMsg}</div>
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
