import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import "./FilterComponents.css";
import CurrencyCodeComponent from "../../../providers/CurrencyCode";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Clarity from '@microsoft/clarity';

function NumberOfStops({ search, setSearch, toggleDrawer, currencyCode }) {
  const classes = useCheckboxStyles();

  const { t } = useTranslation();
  const location = useLocation();
  const handleChange = (event) => {
    Clarity.event("Flight_search_StopsFilter", location.pathname);
    console.log("Flight_search_StopsFilter", location.pathname)
    const users = JSON.parse(localStorage.getItem("user"));
    window.clarity("identify", users.clientref_id, users);
    const existingSearch = { ...search };
    existingSearch.filters.stops.find(
      (item) => item.title === event.target.name
    ).checked = event.target.checked;
    // toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className='number-of-stops-container mt-12 mb-16'>
      <FormControl component='fieldset'>
        <FormGroup>
          {search.filters.stops &&
            search.filters.stops.length > 0 &&
            search.filters.stops.map((stop, index) => (
              <div key={`NumofStopsMainDiv_` + index}>
                {stop?.startingFrom &&
                  stop?.startingFrom !== 0 &&
                  stop?.startingFrom !== Infinity && (
                    <div
                      className='stops-item-container'
                      key={`NumofStopsSubDiv_` + index}>
                      <div className='stop-item-title'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.root}
                              color='default'
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon
                                  )}
                                />
                              }
                              icon={<span className={classes.icon} />}
                              checked={stop.checked}
                              onChange={handleChange}
                              name={stop.title}
                            />
                          }
                          label={t(stop.title)}
                        />
                      </div>
                      <div className='stop-item-price'>
                        <CurrencyCodeComponent currencyCode={currencyCode} />
                        {stop?.startingFrom && stop?.startingFrom.toFixed(2)}
                      </div>
                    </div>
                  )}
              </div>
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default NumberOfStops;
