import React from "react";
import "./FilterComponents.css";
import SunRiseIcon from "../../../assets/sunrise.png";
import SunSetIcon from "../../../assets/sunset.png";
import SunIcon from "../../../assets/sun.png";
import MoonIcon from "../../../assets/moon.png";
import SunRiseLightIcon from "../../../assets/sunrise_light.png";
import SunSetLightIcon from "../../../assets/sunset_light.png";
import SunLightIcon from "../../../assets/sun_light.png";
import MoonLightIcon from "../../../assets/moon_light.png";
import { useLocation } from "react-router";
import Clarity from '@microsoft/clarity';

function DepartArrival({
  type,
  journey,
  journeyIndex,
  search,
  setSearch,
  toggleDrawer,
}) 

{
  const location = useLocation(); 
  const handleChange = (option, index) => {
    if(journey.type === 'depart') {
      Clarity.event("Flight_search_DepartureCity", location.pathname);
      const users = JSON.parse(localStorage.getItem("user"));
      window.clarity("identify", users.clientref_id, users);
    }
    if(journey.type === 'arrival') {
      Clarity.event("Flight_search_ArrivalCity", location.pathname);
      const users = JSON.parse(localStorage.getItem("user"));
      window.clarity("identify", users.clientref_id, users);
    }
   

    
    const existingSearch = { ...search };
    existingSearch.filters.arrivalDepart[journeyIndex].departArrival[
      index
    ].checked = !existingSearch.filters.arrivalDepart[journeyIndex]
      .departArrival[index].checked;
    // toggleDrawer(false);
    setSearch(existingSearch);
  };

  return (
    <div className="depart-arrival-container">
      {journey.departArrival.map((option, index) => (
        <div
          className={
            "depart-arrival-item " +
            (option.checked ? "depart-arrival-item-checked" : "")
          }
          key={`depart_arrival_container_` + index}
          onClick={() => handleChange(option, index)}
        >
          <div className="depart-arrival-icon">
            {option.time === "05-12" && (
              <img
                height={24}
                width={24}
                src={option.checked ? SunRiseLightIcon : SunRiseIcon}
              ></img>
            )}
            {option.time === "12-18" && (
              <img
                height={20}
                width={20}
                src={option.checked ? SunLightIcon : SunIcon}
              ></img>
            )}
            {option.time === "18-23" && (
              <img
                height={20}
                width={20}
                src={option.checked ? SunSetLightIcon : SunSetIcon}
              ></img>
            )}
            {option.time === "23-05" && (
              <img
                height={14}
                width={14}
                src={option.checked ? MoonLightIcon : MoonIcon}
              ></img>
            )}
          </div>
          <div className="depart-arrival-text">{option.time}</div>
        </div>
      ))}
    </div>
  );
}

export default DepartArrival;
