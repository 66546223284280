import React from "react";
import { Table } from "@mui/joy";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { checkIfUserStaff } from "../../services/AuthService";
import { useTranslation } from "react-i18next";
export default function Baggage({ flight }) {
    const { t } = useTranslation();
    return (
        <div className='flight-itenary'>
            {(flight.vendor !== "G9" &&
                flight.baggages &&
                flight.baggages.length > 0) ||
                (flight.meals && flight.meals.length > 0) ? (
                <>
                    <div className='flight-itenary-header'>
                        <div className='flight-itenary-title-container'>
                            <div>{t("SSR Details")}</div>
                        </div>
                    </div>
                    {flight.baggages.length > 0 ? (
                        <div className='baggage-details'>
                            {flight.pcc !== "G9-Test" &&
                                flight.pcc !== "G9-Test-new" &&
                                flight.pcc !== "G9-Live" &&
                                flight.baggages.filter(
                                    (item) => item.amount === 0 && item.paxName == undefined
                                ).length > 0 ? (
                                <div>
                                    {flight.pcc !== "G9-Test" &&
                                        flight.pcc !== "G9-Test-new" &&
                                        flight.pcc !== "G9-Live" &&
                                        flight.baggages.filter(
                                            (item) => item.amount === 0 && item.paxName == undefined
                                        ).length > 0 ? (
                                        <div className='bagTableTitle'>
                                            <span>{t("Free Baggage")} </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {flight.pcc !== "G9-Test" &&
                                        flight.pcc !== "G9-Test-new" &&
                                        flight.pcc !== "G9-Live" &&
                                        flight.baggages.filter(
                                            (item) => item.amount === 0 && item.paxName == undefined
                                        ).length > 0 ? (
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <thead className='mui-table-header'>
                                                    <th style={{ textAlign: "center" }}>
                                                        {t("Passenger Type")}
                                                    </th>
                                                    <th style={{ textAlign: "center" }}> {t("CheckIn Bag")}</th>
                                                    <th style={{ textAlign: "center" }}>{t("Cabin Bag")}</th>
                                                    <th style={{ textAlign: "center" }}>{t("City Pair")}</th>
                                                </thead>
                                                <tbody>
                                                    {flight.baggages
                                                        .filter(
                                                            (item) =>
                                                                item.amount === 0 && item.paxName == undefined
                                                        )
                                                        .map((row) => (
                                                            <tr
                                                                key={row.paxType}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": {
                                                                        border: 0,
                                                                    },
                                                                }}>
                                                                <td align='center'>
                                                                    {row.paxType}
                                                                </td>

                                                                <td align='center'>
                                                                    {row.checkInBag ? row.checkInBag : "NIL"}
                                                                </td>
                                                                <td align='center'>
                                                                    {row.cabinBag ? row.cabinBag : "NIL"}
                                                                    {/* {GDSInfo === "Amadeus" ||
                                                GDSInfo === "Sabre" ||
                                                GDSInfo === "Galileo"
                                                  ? "7KG"
                                                  : row.cabinBag
                                                  ? row.cabinBag
                                                  : "NIL"} */}
                                                                </td>
                                                                <td align='center'>
                                                                    {row.cityPair ? row.cityPair : "NIL"}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            ) : null}

                            {flight.baggages.filter((item) => item.paxName != undefined)
                                .length > 0 ? (
                                <div className='bagTableTitle'>
                                    <span>{t("Paid Baggage")} </span>
                                </div>
                            ) : (
                                ""
                            )}
                            {flight.baggages.filter((item) => item.paxName != undefined)
                                .length > 0 ? (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <thead className='mui-table-header'>
                                            <th style={{ textAlign: "center" }}>{t("Passenger Type")}</th>
                                            <th style={{ textAlign: "center" }}> {t("Passenger Name")}</th>
                                            <th style={{ textAlign: "center" }}> {t("CheckIn Bag")}</th>
                                            <th style={{ textAlign: "center" }}>{t("Cabin Bag")}</th>
                                            <th style={{ textAlign: "center" }}>{t("City Pair")}</th>
                                            <th style={{ textAlign: "center" }}>{t("Amount")}</th>
                                        </thead>
                                        <tbody>
                                            {flight.baggages
                                                .filter((item) => item.paxName != undefined)
                                                .map((row) => (
                                                    <tr
                                                        key={row.paxName}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}>
                                                        <td align='center'>
                                                            {row.paxType}
                                                        </td>
                                                        <td align='center'>{row.paxName}</td>
                                                        <td align='center'>
                                                            {row.checkInBag ? row.checkInBag : "NIL"}
                                                        </td>
                                                        <td align='center'>
                                                            {row.cabinBag ? row.cabinBag : "NIL"}
                                                        </td>
                                                        <td align='center'>
                                                            {row.cityPair ? row.cityPair : "NIL"}
                                                        </td>
                                                        <td align='center'>
                                                            {
                                                                row.amount ? flight?.clientFare[0]?.clientCurrency + " " + row.amount : "NIL"
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {flight.meals != undefined && flight.meals.length > 0 ? (
                        <>
                            <div className='bagTableTitle'>
                                <span>{t("Paid Meal")} </span>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                                    <thead className='mui-table-header'>
                                        <th style={{ textAlign: "center" }}>{t("Passenger Type")}</th>
                                        <th style={{ textAlign: "center" }}> {t("Passenger Nam")}e</th>
                                        <th style={{ textAlign: "center" }}> {t("Descriptio")}n</th>
                                        <th style={{ textAlign: "center" }}>{t("City Pair")}</th>
                                        <th style={{ textAlign: "center" }}>{t("Amount")}</th>
                                    </thead>

                                    <tbody>
                                        {flight.meals
                                            .filter((item) => item.paxName != undefined)

                                            .map((row) => (
                                                <tr
                                                    key={row.paxName}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                    }}>
                                                    <td align='center'>{row.paxType}</td>
                                                    <td align='center'>{row.paxName}</td>
                                                    <td align='center'>
                                                        {row.description ? row.description : "NIL"}
                                                    </td>

                                                    <td align='center'>
                                                        {row.cityPair ? row.cityPair : "NIL"}
                                                    </td>
                                                    <td align='center'>
                                                        {
                                                            row.amount ? flight?.clientFare[0]?.clientCurrency + " " + row.amount : "NIL"
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : null}
                    {/* Seat */}
                    {flight.seats != undefined && flight.seats.length > 0 ? (
                        <>
                            <div className='bagTableTitle'>
                                <span>{t("Seat Details")}</span>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                                    <thead className='mui-table-header'>
                                        <th style={{ textAlign: "center" }}>{t("Passenger Typ")}e</th>
                                        <th style={{ textAlign: "center" }}> {t("Passenger Name")}</th>
                                        <th style={{ textAlign: "center" }}> {t("Seat No")}</th>
                                        <th style={{ textAlign: "center" }}>{t("AirCraftType")}</th>
                                        <th style={{ textAlign: "center" }}>{t("City Pair")}</th>
                                        <th style={{ textAlign: "center" }}>{t("Amount")}</th>
                                    </thead>

                                    <tbody>
                                        {flight.seats
                                            .filter((item) => item.paxName != undefined)

                                            .map((row) => (
                                                <tr
                                                    key={row.paxName}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                    }}>
                                                    <td align='center'>{row.paxType}</td>
                                                    <td align='center'>{row.paxName}</td>
                                                    <td align='center'>
                                                        {row.seatNo ? row.seatNo : "NIL"}
                                                    </td>
                                                    <td align='center'>
                                                        {row.airCraftType ? row.airCraftType : "NIL"}
                                                    </td>

                                                    <td align='center'>
                                                        {row.cityPair ? row.cityPair : "NIL"}
                                                    </td>
                                                    <td align='center'>
                                                        {
                                                            row.amount ? flight?.clientFare[0]?.clientCurrency + " " + row.amount : "NIL"
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : null}
                </>
            ) : null}
        </div>
    );
}
