import React, { useState } from "react";
import "./ModifySearchBar.css";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import ModifySearchItems from "./ModifySearchItems";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from "@mui/material";

function ModifySearchBar({
  search,
  setSearch,
  modifySearch,
  handleModifySearch,
  loading,
}) {
  const { t } = useTranslation();
  const [isCollapseOPen, setIsCollapseOPen] = useState(false);

  return (
    <div className='modify-search-bar-container'>
      <Grid container spacing={2} className='height-100 align-items-start'>
        <Grid item xs={10} onClick={() => (loading ? "" : modifySearch())} style={{ cursor: 'pointer' }}>
          {(search?.type !== 'MultiCity') || (!isCollapseOPen)? (
            <ModifySearchItems search={search} itemIndex={0} />
          ) : (isCollapseOPen &&
            search?.originDestinations.map((item, index) => {
              return (
                <ModifySearchItems search={search} itemIndex={index} />
              )
            })
          )
          }
        </Grid>

        <Grid item xs={2} className='modify-search-button-container'>
          <div
            className='modify-search-block pointer'
            onClick={() => (loading ? "" : modifySearch())}>
            {t("MODIFY")}
          </div>
          {search?.type === 'MultiCity' &&
            <IconButton aria-label="down arrow" size="small"
              onClick={() => {
                setIsCollapseOPen(!isCollapseOPen);
              }}
            >
              <ArrowDropDownIcon fontSize="large" sx={{ color: 'white', transform: isCollapseOPen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.15s ease !important'}} />
            </IconButton>
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default ModifySearchBar;
