import logFrontEndData from "../utils/logFrontEndData";
const getIP = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        return response && data && data?.ip ? data?.ip : "0.0.0.0";
    } catch (error) {
        console.error("Error fetching IP address:", error);

        const logData = {
            traceid: "",
            clientUser: "",
            currentpage: "",
            currentaction: `Fetching Ip`,
            ApiUrl: "https://ipinfo.io/json",
            errorCode: "",
            errorDescription: "",
            ErrorMessage: JSON.stringify(error),
            Payload: "",
        }

        logFrontEndData(logData);
        return "0.0.0.0";
    }
};

export default getIP;

