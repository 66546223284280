import { ArrowForward } from "@mui/icons-material";
import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import "./PrevNextComponent.css";
import { useLocation } from "react-router";
import Clarity from '@microsoft/clarity';

export default function PrevNextComponent({
  t,
  fetchPrevNextDayresults,
  search,
}) {
    const location = useLocation();
  const currentDate = new Date();
  const departDate = new Date(search.originDestinations[0].departureDateTime);
  const isPrevDisabled =
    currentDate.toDateString() === departDate.toDateString();
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className="prevNextButton"
          color="#9f155a"
          variant="plain"
          disabled={isPrevDisabled}
          onClick={() => {
            fetchPrevNextDayresults("prev");
            Clarity.event("Flight_search_PreviousDay", location.pathname);
            const users = JSON.parse(localStorage.getItem("user"));
            window.clarity("identify", users.clientref_id, users);

          }}
          startDecorator={<ArrowBack />}
        >
          {t("Previous Day")}
        </Button>
        <Button
          className="prevNextButton"
          variant="plain"
          onClick={() => {
            fetchPrevNextDayresults("next");
            Clarity.event("Flight_search_NextDay", location.pathname);
            const users = JSON.parse(localStorage.getItem("user"));
            window.clarity("identify", users.clientref_id, users);
          }}
          startDecorator={<ArrowForward />}
        >
          {t("Next Day")}
        </Button>
      </div>
    </div>
  );
}
