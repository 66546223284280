import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { IconButton, TextField, } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import RiseLoading from "../../../Loader/RiseLoader";
import airlineDetails from "../../../../data/AirlineDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import cabinClassList from "../../../../data/CabinClass";


const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function ATCConfigDrawer({
    toggleDrawer,
    selectedItem,
    action,
    setAction,
    handleSnackbarToggle,
    loadConfigurations,
    vendorList,
}) {
    const classes = useStyles();

    const [showLoader, setShowLoader] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [vendor, setVendor] = useState([]);
    const [airline, setAirline] = useState("");
    const [gds, setGds] = useState([]);
    const [atcPermit, setAtcPermit] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [exIndia, setExIndia] = useState(false);
    const [rafFees, setRafFees] = useState(0);
    const [cabinClass, setcabinClass] = useState([]);
    const [tax, setTax] = useState(0);
    const [taxType, setTaxType] = useState("");
    const [applicableOn, setApplicableOn] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        console.log('selectedItem', selectedItem);
        if (action === "edit" || action === "view") {
            const selectedAirline = airlineDetails.find((v) => v.Code === selectedItem.airline) || null;
            setAirline(selectedAirline);

            const vendorIds = selectedItem.vendors.split(",").map(Number);
            const selectedVendors = vendorList.filter((v) => vendorIds.includes(v.id));
            setVendor(selectedVendors);

            setGds(selectedItem.gds ? (Array.isArray(selectedItem.gds) ? selectedItem.gds : [selectedItem.gds]) : []);

            setAtcPermit(selectedItem.autoTicketChangerPermitted ? "Yes" : "No");
            setServiceType(selectedItem.serviceType);
            setExIndia(selectedItem.isExIndiaCheck);
            setRafFees(selectedItem.rafFeesCancel);

            const selectedCabin = selectedItem.cabinType.split(",");
            const selectedCabins = cabinClassList.filter((v) => selectedCabin.includes(v.label));
            setcabinClass(selectedCabins);

            setTax(selectedItem.taxValue);
            setTaxType(selectedItem.taxType);

            setApplicableOn(selectedItem.applicableOn);

            setStatus(selectedItem.isActive ? "Active" : "In-Active");
        } else {
            setAirline("");
            setVendor([]);
            setGds([]);
            setAtcPermit("");
            setServiceType("");
            setExIndia(false);
            setRafFees(0);
            setcabinClass([]);
            setTax(0);
            setTaxType("");
            setApplicableOn("");
            setStatus("");
        }
    }, [toggleDrawer, action]);

    async function handleUpdate() {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };

        if (action === "edit" || action === "view") {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL +
                    "api/Configuration/AutoTicketConfiguration/" +
                    selectedItem.id,
                    JSON.stringify({
                        id: selectedItem.id,
                        isActive: status === "Active" ? true : false,
                        airline: airline.Code,
                        gds: gds.join(","),
                        autoTicketChangerPermitted: atcPermit ? true : false,
                        serviceType: serviceType,
                        vendors: vendor.map((v) => v.id).join(","),
                        rafFeesCancel: rafFees,
                        cabinType: cabinClass.map((c) => c.label).join(","),
                        taxValue: tax,
                        taxType: taxType,
                        applicableOn: applicableOn,
                        isExIndiaCheck: exIndia
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    handleSnackbarToggle();
                    setShowLoader(false);
                    loadConfigurations();
                })
                .catch((err) => {
                    console.log(err);
                    handleSnackbarToggle();
                    setShowLoader(false);
                });
        } else {
            setShowLoader(true);
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/AutoTicketConfiguration",
                    JSON.stringify({
                        isActive: status === "Active" ? true : false,
                        airline: airline.Code,
                        gds: gds.join(","),
                        autoTicketChangerPermitted: atcPermit ? true : false,
                        serviceType: serviceType,
                        vendors: vendor.map((v) => v.id).join(","),
                        rafFeesCancel: rafFees,
                        cabinType: cabinClass.map((c) => c.label).join(","),
                        taxValue: tax,
                        taxType: taxType,
                        applicableOn: applicableOn,
                        isExIndiaCheck: exIndia
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    console.log(res);
                    handleSnackbarToggle();
                    setShowLoader(false);
                    loadConfigurations();
                    setErrMsg("");
                })
                .catch((err) => {
                    console.log(err);
                    // handleSnackbarToggle();
                    setShowLoader(false);
                    setErrMsg('*' + err.response.data.detail);
                });
        }
    }

    useEffect(() => {
        if (exIndia === false) {
            setRafFees(0);
            setcabinClass([]);
            setTax(0);
            setTaxType("");
            setApplicableOn("");
        }
    }, [exIndia])



    return (
        <div className="ticket-drawer-container atc-config-container">
            <div className="close-button-container">
                <CloseIcon
                    className="icon-fill pointer"
                    fontSize="medium"
                    onClick={toggleDrawer(false)}
                ></CloseIcon>
            </div>
            <div className="search-heading-container search-title-flex-container">
                <div>{action === "edit" ? "Edit" : action === "new" ? "Add" : ""} ATC Configuration</div>
                {action === "view" && (
                    <div className="search-heading-icons">
                        {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
                        <IconButton fontSize="small">
                            <EditRoundedIcon
                                fontSize="small"
                                className="icon-blue"
                                onClick={() => setAction("edit")}
                            ></EditRoundedIcon>
                        </IconButton>
                        {/* ) : (
                            ""
                        )} */}
                    </div>
                )}
            </div>

            <ValidatorForm
                onError={(errors) => console.log(errors)}
                onSubmit={handleUpdate}
            >
                <div className="search-view-edit-drawer-container">
                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    id="vendor-multi-select"
                                    value={airlineDetails.find((v) => v.Code === airline?.Code) || null}
                                    onChange={(event, value) => {
                                        setAirline(value);
                                    }}
                                    options={airlineDetails}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.Code === "All" ? "All" : option.Name + " (" + option.Code + ")"}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Select Airlines"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                            }}
                                            required
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Airline
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {airline.Name}
                                </div>
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">

                                <Autocomplete
                                    required
                                    id="cabin select"
                                    multiple
                                    value={vendor || vendor}
                                    onChange={(event, newValue) => {
                                        setVendor(newValue);
                                    }}
                                    options={vendorList}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name || ""}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Vendor"
                                            value={vendor}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password",
                                                required: vendor && vendor.length === 0,
                                            }}
                                            required={true}
                                        />
                                    )}
                                    style={{ maxWidth: "34vw" }}
                                />

                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Vendor
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {vendor !== undefined &&
                                        vendor.length > 0 &&
                                        vendor.map((v) => (
                                            <p>{v !== undefined ? v.name : ""}</p>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    value={(gds && gds) || []}
                                    onChange={(event, value) => setGds(value)}
                                    options={['All', 'Galileo', 'Sabre', 'Amadeus']}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            variant="outlined"
                                            label="Select GDS"
                                            color="secondary"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    GDS
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {gds}
                                </div>
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    id="Status"
                                    value={atcPermit}
                                    onChange={(event, newValue) => setAtcPermit(newValue)}
                                    options={['Yes', 'No']}
                                    getOptionLabel={option => option}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Auto Ticket Changer Permitted"
                                            variant="outlined"
                                            color="secondary"
                                            required
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Auto Ticket Changer Permitted
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {atcPermit ? "Yes" : "No"}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    id="tags-standard"
                                    value={(serviceType && serviceType)}
                                    onChange={(event, value) => setServiceType(value)}
                                    options={['Cancel', 'Exchange', 'All']}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            variant="outlined"
                                            label="Service Type"
                                            color="secondary"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Service Type
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {serviceType}
                                </div>
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <Autocomplete
                                    id="Status"
                                    value={status}
                                    onChange={(event, newValue) => setStatus(newValue)}
                                    options={["Active", "In-Active"]}
                                    getOptionLabel={option => option}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label="Status"
                                            variant="outlined"
                                            color="secondary"
                                            required
                                            size="small"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                    Status
                                </div>
                                <div className="view-supplier-drawer-description">
                                    {status}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="supplier-view-edit-drawer-input-container">
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                <FormControlLabel
                                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    className="ms-2"
                                    control={
                                        <Checkbox
                                            size="medium"
                                            name="ex/India"
                                            checked={exIndia}
                                            onChange={(e) => setExIndia(e.target.checked)}
                                            color="primary"
                                            className="rounded"
                                        />
                                    }
                                    label={"Ex / India"}
                                />
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                            </div>
                        )}
                        {action !== "view" && (
                            <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                            </div>
                        )}
                        {action === "view" && (
                            <div className="view-supplier-drawer config-input-flex-1">
                                <div className="view-supplier-drawer-title">
                                </div>
                                <div className="view-supplier-drawer-description">
                                </div>
                            </div>
                        )}
                    </div>
                    {exIndia &&
                        <>
                            <div className="supplier-view-edit-drawer-input-container">
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                        <TextValidator
                                            variant="outlined"
                                            label="RAF Fees (Cancel)"
                                            value={rafFees || ""}
                                            onChange={(event) => setRafFees(event.target.value)}
                                            color="secondary"
                                            size="small" />
                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                            RAF Fees (Cancel)
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                            {rafFees}
                                        </div>
                                    </div>
                                )}
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                        </div>
                                    </div>
                                )}
                            </div><div className="supplier-view-edit-drawer-if-container">
                                <div className="supplier-view-edit-drawer-if-line"></div>
                                <div className="text-wrap-cls">Taxes to apply</div>
                                <div className="supplier-view-edit-drawer-if-line"></div>
                            </div><div className="supplier-view-edit-drawer-input-container">
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                        <Autocomplete
                                            required
                                            id="cabin select"
                                            multiple
                                            value={cabinClass || cabinClass}
                                            onChange={(event, value) => {
                                                setcabinClass(value);
                                            }}
                                            options={cabinClassList}
                                            autoHighlight
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Cabin Type"
                                                    value={cabinClass}
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password",
                                                        required: cabinClass && cabinClass.length === 0,
                                                    }}
                                                    required={true} />
                                            )}
                                            style={{ maxWidth: "34vw" }} />
                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                            Cabin Type
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                            {cabinClass !== undefined &&
                                                cabinClass.length > 0 &&
                                                cabinClass.map((v) => (

                                                    <p>{v !== undefined ? v.label : ""}</p>
                                                ))}
                                        </div>
                                    </div>
                                )}
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                        <TextValidator
                                            variant="outlined"
                                            label="Tax Value"
                                            value={tax || ""}
                                            onChange={(event) => setTax(event.target.value)}
                                            color="secondary"
                                            size="small" />
                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                            Tax Value
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                            {tax}
                                        </div>
                                    </div>
                                )}
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                        <Autocomplete
                                            id="Status"
                                            value={taxType}
                                            onChange={(event, newValue) => setTaxType(newValue)}
                                            options={['Value', 'Percentage']}
                                            getOptionLabel={option => option}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Tax Type"
                                                    variant="outlined"
                                                    color="secondary"
                                                    required
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }} />
                                            )} />
                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                            Tax Type
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                            {taxType}
                                        </div>
                                    </div>
                                )}
                                {action !== "view" && (
                                    <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                                        <Autocomplete
                                            id="Status"
                                            value={applicableOn}
                                            onChange={(event, newValue) => setApplicableOn(newValue)}
                                            options={['Penalty', 'Penalty + RAF']}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    label="Applicable On"
                                                    variant="outlined"
                                                    color="secondary"
                                                    required
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }} />
                                            )} />

                                    </div>
                                )}
                                {action === "view" && (
                                    <div className="view-supplier-drawer config-input-flex-1">
                                        <div className="view-supplier-drawer-title">
                                            Applicable On
                                        </div>
                                        <div className="view-supplier-drawer-description">
                                            {applicableOn}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    }

                </div>
                {action !== "view" && (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{
                                textTransform: "none",
                                marginLeft: "25px",
                                marginRight: "25px",
                                marginBottom: "25px",
                                width: "500px",
                            }}
                            type="submit"
                        >
                            {action === "edit" ? "Update" : "Create"}
                        </Button>
                        {showLoader && (
                            <RiseLoading Search={showLoader} />
                        )}
                        <div className="ms-5 t text-danger">{errMsg}</div>
                    </div>

                )}
            </ValidatorForm>
        </div>
    );
}