import { Box, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Table } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import parse from 'html-react-parser';
import axios from "axios";
import { checkIfUserStaff } from "../../services/AuthService";
import RiseLoading from "../Loader/RiseLoader";
import { useTranslation } from "react-i18next";

export default function ComissionAndTaxDetails({ flight, endClientName, endClientEmail, orderId, bookingSource }) {
    const { t } = useTranslation();
    const [ticketModalopen, setTicketModalOpen] = useState(false);
    const [ticketImage, setTicketImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function getTicketImage(orderId) {
        setIsLoading(true);
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "Flights/Booking/GetBooking/v1/GetTicketImg/" +
                orderId
            )
            .then((response) => {
                let ticketData = response.data;
                setTicketModalOpen(true);
                if (ticketData[0].ticketImage) {
                    setTicketImage(ticketData[0].ticketImage);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                setTicketImage(`<p>${e.response.data}</p>`);
                setIsLoading(false);
            })
    }

    const ticketPopupHandler = () => {
        getTicketImage(orderId);
    }

    if (isLoading) {
        return (
            <RiseLoading isLoading={isLoading} />
        )
    }

    return (
        <div className="flight-itenary">
            <Box className="flight-itenary-header">
                <Box display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className="flight-itenary-title-container"
                    sx={{ flex: '1 1 100%' }}
                >
                    <div>
                        {t("Commission And Tax Details")}
                    </div>
                    {checkIfUserStaff() &&
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={ticketPopupHandler}
                        >
                            {t("View ticket image")}
                        </Button>
                    }
                </Box>
            </Box>
            {/* <Table> */}
            <div div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Vendor Tax IATA")}:</span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxIATA}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Vendor Tax PLB")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxPLB}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Vendor Tax Type")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("TDS")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.tds}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Is TDS Applicable")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.isTDSApplicable}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Service Tax")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTax}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Service Tax Name")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Service Tax Type")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxType}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Service Tax ApplicableOn")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.vendorServiceTaxApplicable}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("CaseId")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.caseId}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("MCO")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mco}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("MCO Parted")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoParted}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("MCO Charge")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("MCO Remark")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.mcoRemark}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("PG Charge")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.commissionDetails?.pgCharge}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row " style={{ display: "grid", gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Corp/Retail")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight.isCorporate ? "Corporate" : "Retail"}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Client Name")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {endClientName}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Client Email")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {endClientEmail}
                        </span>
                    </div>
                </div>
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Sales Representative")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {flight?.salesRepresentative ? flight?.salesRepresentative : '-'}
                        </span>
                    </div>
                </div>
                {(bookingSource === "ImportPNR" || bookingSource === "OfflineBooking" || bookingSource === "ImportPNRB2B" || bookingSource === "ImportPNRReissue" || bookingSource === "OfflineBookingReissue") &&
                    (
                        <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                            <div className='' style={{ textAlign: "center" }}>
                                <p style={{ paddingTop: "10px" }}>
                                    <span>{t("Sales Staff")}: </span>
                                </p>
                            </div>
                            <div
                                style={{
                                    color: "black",
                                    textAlign: "center",
                                }}>
                                <span>
                                    {flight?.salesStaff ? flight?.salesStaff : '-'}
                                </span>
                            </div>
                        </div>
                    )}
                <div className="vb-highlight-txt  dispFlex flex-column  justify-content-center  pt-2">
                    <div className='' style={{ textAlign: "center" }}>
                        <p style={{ paddingTop: "10px" }}>
                            <span>{t("Source Of Booking")}: </span>
                        </p>
                    </div>
                    <div
                        style={{
                            color: "black",
                            textAlign: "center",
                        }}>
                        <span>
                            {
                                (flight?.info && flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    ? (flight?.info?.match(/%%%([^%]+)%%%/)?.[1])
                                    : '-'
                            }
                        </span>
                    </div>
                </div>
            </div>
            {/* </Table> */}
            <Dialog
                open={ticketModalopen}
                onClose={() => {
                    setTicketModalOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Ticket Image")}
                </DialogTitle>
                <DialogContent>
                    <div className="custom-editor">
                        {parse(ticketImage)}
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );
}