export default function getTotalFareWithAkbarMarkup(
  adultBaseFare,
  childBaseFare,
  infantBaseFare,
  adultTaxPrice,
  childTaxPrice,
  infantTaxPrice,
  adult,
  children,
  infant
) {
  const adultTotalPrice = (adultBaseFare + adultTaxPrice) * adult;
  const childrenTotalPrice = (childBaseFare + childTaxPrice) * children;
  const infantTotalPrice = (infantBaseFare + infantTaxPrice) * infant;

  const totalFare = adultTotalPrice + childrenTotalPrice + infantTotalPrice;

  return totalFare;
}
