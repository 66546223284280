import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import findAirlinebyCode from "../../functions/findAirlineByCode";
import getDuration from "../../functions/getDuration";
import getAirportByCode from "../../functions/getAirportByCode";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { Grid, Table } from "@mui/joy";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import cabinClass from "../../data/CabinClass";

export default function FlightSegmentDetails({ flight, setMaxHeight, bookingDetails, flag, checkedRows, setCheckedRows }) {
    const { t } = useTranslation();
    const tableBodyRef = useRef(null);
    useEffect(() => {
        if (tableBodyRef.current) {
            const columns = tableBodyRef.current.children;
            let height = 0;

            // Find the tallest column
            for (const column of columns) {
                if (column.clientHeight > height) {
                    height = column.clientHeight;
                }
            }
            // Update the maxHeight in the parent component
            setMaxHeight((maxHeight) => height);
        }
    }, [flight]);

    const handleCheckboxChange = (segGroupIndex, segmentIndex) => {
        setCheckedRows((prevState) => {
            const updatedGroup = { ...(prevState[segGroupIndex] || {}) };
            updatedGroup[segmentIndex] = !updatedGroup[segmentIndex];

            return {
                ...prevState,
                [segGroupIndex]: updatedGroup,
            };
        });
    };


    return (
        <>
            {(flag === "reissue"
                ? flight.exisitingSegGroups && flight.exisitingSegGroups.length > 0
                : flight.segGroups && flight.segGroups.length > 0) &&
                (flag === "reissue" ? flight.exisitingSegGroups : flight.segGroups).map((segGroup, segGroupIndex) => (
                    segGroup.segments && segGroup.segments.length > 0 ? (
                        <div className="row" key={segGroupIndex}>
                            <div style={{ paddingLeft: "0px", width: flag !== "reissuecopy" ? "80%" : "100%" }}>
                                <div className="flight-itenary">
                                    <div className="flight-itenary-header">
                                        <div className="flight-itenary-title-container d-flex justify-content-between align-items-center">
                                            <div>
                                                {t("Itinerary Details")}
                                                {flag === "reissue" && (
                                                    <span className="ms-4 p-1 rounded" style={{ backgroundColor: "#671c41" }}>
                                                        Existing Itinerary
                                                    </span>
                                                )}
                                            </div>
                                            {/* {flag === "reissuecopy" && (
                                                <div className="ms-3">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                className="copy-chkbox"
                                                                checked={!!checkedRows[segGroupIndex]} // Ensure correct state binding
                                                                onChange={() => handleCheckboxChange(segGroupIndex)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                    {segGroup.segments && segGroup.segments.length > 0 ? (
                                        <Table aria-label="basic table" id="seggroupTable">
                                            <thead>
                                                <tr>
                                                    <BootstrapTooltip1 title={t("Airline/Flight No/ Coach")}>
                                                        <th className="flightItineraryColumnHeader">
                                                            {t("Airline/Flight No/ Coach")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Departure Airport / Terminal")}>
                                                        <th className="flightItineraryColumnHeader">
                                                            {t("Departure Airport / Terminal")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Departure Date & Time")}>
                                                        <th className="flightItineraryColumnHeader">
                                                            {t("Departure Date & Time")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Arrival Airport / Terminal")}>
                                                        <th className="flightItineraryColumnHeader">
                                                            {t("Arrival Airport / Terminal")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Arrival Date & Time")}>
                                                        <th className="flightItineraryColumnHeader">
                                                            {t("Arrival Date & Time")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Duration")}>
                                                        <th className="flightItineraryColumnHeader" style={{ width: "5vw", maxWidth: "5vw" }}>
                                                            {t("Duration")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Airline PNR")}>
                                                        <th className="flightItineraryColumnHeader" style={!flag?.includes("reissue") ? { width: "5vw", maxWidth: "5vw" } : undefined}
                                                        >
                                                            {t("Airline PNR")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Status")}>
                                                        <th
                                                            className="flightItineraryColumnHeader"
                                                            style={!flag?.includes("reissue") ? { width: "5vw", maxWidth: "5vw" } : undefined}
                                                        >
                                                            {t("Status")}
                                                        </th>
                                                    </BootstrapTooltip1>
                                                    {flag === "reissuecopy" && (
                                                        <BootstrapTooltip1 title={t("Actions")}>
                                                            <th className="flightItineraryColumnHeader">{t("Actions")}</th>
                                                        </BootstrapTooltip1>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody ref={tableBodyRef}>
                                                {segGroup.segments.map((segment, segmentIndex) => (
                                                    <tr key={segmentIndex}>
                                                        <td className="flightItineraryColumn">
                                                            {findAirlinebyCode(segment.mrkAirline)} <br />
                                                            <b>{segment.flightNum}</b> / {!flag?.includes("reissue") ? segment.cabinClass : (cabinClass.find((cabin) => cabin.value == segment.cabinClass)?.label || "")}
                                                        </td>
                                                        <td className="flightItineraryColumn">
                                                            {getAirportByCode(segment.origin)} <br />
                                                            <b>{segment.origin}</b> / {segment.depTerminal}
                                                        </td>
                                                        <td className="flightItineraryColumn">
                                                            {moment(segment.departureOn).format("Do MMM YYYY")}
                                                            <br />
                                                            {moment(segment.departureOn).format("hh:mm A")}
                                                        </td>
                                                        <td className="flightItineraryColumn">
                                                            {getAirportByCode(segment.destination)}
                                                            <br />
                                                            <b>{segment.destination}</b> / {segment.arrTerminal}
                                                        </td>
                                                        <td className="flightItineraryColumn">
                                                            {moment(segment.arrivalOn).format("Do MMM YYYY")}
                                                            <br />
                                                            {moment(segment.arrivalOn).format("hh:mm A")}
                                                        </td>
                                                        <td className="flightItineraryColumn" style={{ width: "5vw", maxWidth: "5vw" }}>
                                                            {getDuration(segment.duration)}
                                                        </td>
                                                        <td className="flightItineraryColumn" style={!flag?.includes("reissue") ? { width: "5vw", maxWidth: "5vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : undefined}>
                                                            <BootstrapTooltip1 title={segment.pnr ? segment.pnr : "-"}>
                                                                {segment.pnr ? segment.pnr : "-"}
                                                            </BootstrapTooltip1>
                                                        </td>
                                                        <td className="flightItineraryColumn" style={!flag?.includes("reissue") ? { width: "5vw", maxWidth: "5vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : undefined}>
                                                            <div>
                                                                <BootstrapTooltip1 title={segGroup.reIssueStatus || "-"}>
                                                                    {segGroup.reIssueStatus || "-"}
                                                                </BootstrapTooltip1>
                                                            </div>
                                                            {flag === "reissue" && segGroup.reIssueStatus !== "Confirmed" && (
                                                                <div className="text-danger text-bold">
                                                                    <BootstrapTooltip1 title={segGroup.reissueTravelDate ? moment(segGroup.reissueTravelDate).format("Do MMM YYYY") : "-"}>
                                                                        New Date: {segGroup.reissueTravelDate ? moment(segGroup.reissueTravelDate).format("Do MMM YYYY") : "-"}
                                                                    </BootstrapTooltip1>
                                                                </div>
                                                            )}
                                                        </td>

                                                        {flag === "reissuecopy" && (
                                                            <td className="flightItineraryColumn">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={!!checkedRows[segGroupIndex]?.[segmentIndex]}
                                                                            onChange={() => handleCheckboxChange(segGroupIndex, segmentIndex)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label=""
                                                                />
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                            {flag !== "reissuecopy" && (
                                <div style={{ paddingRight: "0px", width: "20%" }}>
                                    <div className="flight-itenary">
                                        <div className="flight-itenary-header">
                                            <div className="flight-itenary-title-container">
                                                <div>{t("PNR")}</div>
                                            </div>
                                        </div>
                                        <Table aria-label="basic table" id="tablePNR">
                                            <thead>
                                                <tr style={{ display: "flex" }}>
                                                    <BootstrapTooltip1 title="RBD">
                                                        <th align="center" style={{ flex: "1 1 auto" }}>{t("RBD")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title="Fare Basis">
                                                        <th align="center" style={{ flex: "1 1 auto" }}>{t("Fare Basis")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title="GDS PNR">
                                                        <th align="center" style={{ flex: "1 1 auto" }}>{t("GDS PNR")}</th>
                                                    </BootstrapTooltip1>
                                                </tr>
                                            </thead>
                                            <tbody ref={tableBodyRef}>
                                                {segGroup?.segments?.map((segment, index) => (
                                                    <tr key={index} id="seggroupPNRTable" style={{ display: "flex" }}>
                                                        <td align="center" className="flightItineraryColumn" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: "1 1 auto" }}>
                                                            <BootstrapTooltip1 title={segment?.rbd}>
                                                                {segment?.rbd}
                                                            </BootstrapTooltip1>
                                                        </td>
                                                        <td align="center" className="flightItineraryColumn" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: "1 1 auto" }}>
                                                            <BootstrapTooltip1 title={flight?.fareBasis || "-"}>
                                                                {flight?.fareBasis || "-"}
                                                            </BootstrapTooltip1>
                                                        </td>
                                                        <td align="center" className="flightItineraryColumn" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: "1 1 auto" }}>
                                                            <BootstrapTooltip1 title={flight?.pnr}>
                                                                {flight?.pnr}
                                                            </BootstrapTooltip1>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null
                ))
            }

        </>
    );
}