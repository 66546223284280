import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { Typography } from '@mui/material';
import parse from 'html-react-parser';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@material-ui/core';

const DetailedFareRule = ({ setFareRulePopUp, flightResponses, t, vendorCode }) => {

  const handleClose = () => {
    setFareRulePopUp(false);
  };

  const handleCopyClick = () => {
    if (flightResponses && flightResponses.length > 0) {
      let copyContent = "";

      flightResponses.forEach((response) => {
        const fareBasisArray = response.fareBasis || [];
        fareBasisArray.forEach((fareBasisData) => {
          copyContent += `Fare Basis: ${fareBasisData.code}\n`;
          copyContent += `Applicable: ${fareBasisData.applicable}\n`;
          copyContent += `Airline: ${fareBasisData.airLine}\n\n`;

          if (fareBasisData.detailedRules && fareBasisData.detailedRules.length > 0) {
            fareBasisData.detailedRules.forEach((rule) => {
              copyContent += `Type: ${rule.type}\n`;
              copyContent += `Details: ${rule.freeText}\n\n`;
            });
          }
        });
      });

      navigator.clipboard
        .writeText(copyContent)
        .then(() => {
          alert("Fare rules copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };


  return (
    <div >
      <div className='d-flex justify-content-between'>
        <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          {t("Detailed Fare Rule")}
          <span style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Button onClick={() => handleCopyClick()} style={{ marginLeft: '10px' }}>
              <ContentCopyIcon />
            </Button>
            {
              flightResponses && flightResponses.length > 0 ? (
                <span style={{ marginLeft: '10px', fontSize: '18px', fontWeight: '500' }}>
                  {flightResponses[0].orgin} {flightResponses[0].airTravelType === 'OneWay' ? '->' : '<->'}
                  {flightResponses[0].destination}
                  <span style={{ paddingLeft: '10px' }}>
                    ( {flightResponses[0].airTravelType} )
                  </span>
                </span>
              ) : null
            }
          </span>
        </h3>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className='p-4'
        >
          <CloseIcon />
        </IconButton>
      </div>


      {flightResponses && flightResponses.length > 0 ? (
        flightResponses.map((response, index) => {
          // Ensure fareBasis is defined and is an array
          const fareBasisArray = response.fareBasis || [];
          return fareBasisArray.length > 0 ? (
            fareBasisArray.map((fareBasisData, fareBasisDataIndex) => (
              <Accordion key={`${index}-${fareBasisDataIndex}`} defaultExpanded={fareBasisDataIndex === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-${fareBasisDataIndex}-content`}
                  id={`panel${index}-${fareBasisDataIndex}-header`}
                >
                  <h5 style={{ width: '100%', padding: '0 10px' }}> Fare Basis - {fareBasisData.code}
                    <span style={{ float: 'right', fontSize: '18px', fontWeight: '500' }}>{t("Applicable")} - {fareBasisData.applicable} <span style={{ paddingLeft: '10px' }}>( Airline - {fareBasisData.airLine} )</span></span>
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  {fareBasisData.detailedRules && fareBasisData.detailedRules.map((rule, ruleIndex) => (
                    <div key={ruleIndex}>
                      <p><strong>{t("Type")}:</strong> {rule.type}</p>
                      {vendorCode === "AERTicket" || vendorCode === "AER" ? (
                        <div>{parse(rule.freeText)}</div>
                      ) : (
                        <p><pre>{rule.freeText}</pre></p>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <p className='p-4' key={index}>{t("No fare basis available for this origin-destination pair.")}</p>
          );
        })
      ) : (
        <p className='p-4'>{t("No fare rules available.")}</p>
      )}
    </div>
  );
};

export default DetailedFareRule;
