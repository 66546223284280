import React, { useState, Component } from "react";
import "./AddMarkupBlockCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../../assets/travel.svg";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import airlineDetails from "../../../../data/AirlineDetails";
import fareTypeList from "../../../../data/FareType";
import cabinClassList from "../../../../data/CabinClass";
import TripTypeList from "../../../../data/TripType";
import { Button, FormControl, InputLabel, NativeSelect, TextField } from "@material-ui/core";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});


export default function AddMarkupBlockCard(
  { setMarkupBlockID, setfareType, setVendorID, vendorID, vendorList, loadCommissionBlocks, name, fareType, cabinClass, setcabinClass, isActive,filters, setFilters,filterCommissionBlocks,setPage,ClearFilters}) {


 const noneArr = [{ Code: "None", Name: "None" }];
 const extendedAirlineDetails = [...airlineDetails, ...noneArr];
 const statusList = ["Active", "In Active"];

 const updateFormState = (field, value) => {
  setFilters((prevState) => ({
    ...prevState,
    [field]: value
  }));
};


  let ID, vendor, type;
  const classes = useStyles();
  async function endSearch() {
    if (!ID && !type && !vendor) {
      await setMarkupBlockID('');
      await setfareType('');
      await setcabinClass('')
      await setVendorID('');
      await loadCommissionBlocks(false);
    }
  }
  console.log('filters.fareType',filters.fareType);
  return (

    <div className="add-supplier-card-container">
      <div className="add-supplier-card-title">Search Markup Block</div>
      <div className="create-supplier-contents-container">
        <div className="create-supplier-contents">
          <div className="add-supplier-if-items-container">
            {/* <div className="add-supplier-if-item margin-right-16">
              <div className="add-supplier-if-container">IF</div>
            </div> */}
            {/* <div className="add-supplier-if-item flex-1-input margin-right-16">
              
                <TextField
                  variant="outlined"
                  label="Ref ID"
                  size="small"
                  color="secondary"
                  onChange={(event) => {
                    setMarkupBlockID(event.target.value);
                    ID=(event.target.value)
                    endSearch()}}
                />
             
            </div> */}

            <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
              <div className="view-supplier-drawer-title">MarkupGroup</div>
              <div className="view-supplier-drawer-description ">{name}</div>
            </div>

            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="airline select"
                multiple
                value={filters.airline && filters.airline}
                onChange={(e, value) => updateFormState("airline", value)}
                options={extendedAirlineDetails}
                autoHighlight
                getOptionLabel={(option) => option?.Code === "All" ? "All" : option?.Name + " (" + option?.Code + ")"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Airline"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={filters.airline}
                    className="scb-custom-dropdown-height rounded"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>

            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="faretype select"
                multiple
                value={filters.fareType && filters.fareType}
                onChange={(e, value) => updateFormState("fareType", value)}
                options={fareTypeList}
                autoHighlight
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={filters.fareType}
                    label="Fare Type"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="scb-custom-dropdown-height rounded"
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>

            <div className="add-supplier-if-item flex-1-input margin-right-16">
              <Autocomplete
                id="cabin select"
                multiple
                value={filters.cabinClass && filters.cabinClass}
                onChange={(e, value) => updateFormState("cabinClass", value)}
                options={cabinClassList}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={filters.cabinClass}
                    label="Cabin Class"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="scb-custom-dropdown-height rounded"
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />
            </div>

          


            <div className="add-supplier-if-item flex-1-input margin-right-16">
            <Autocomplete
                id="vendor-select"
                multiple
                value={vendorList.filter((v) => filters.vendorId.includes(v.id)) || []} 
                onChange={(event, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    vendorId: newValue.map((v) => v.id) || [],
                  }));
                }}
                options={vendorList}
                autoHighlight
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vendor"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="scb-custom-dropdown-height rounded"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                style={{ maxWidth: "34vw" }}
              />

            </div>

              <div className="add-supplier-if-item flex-1-input margin-right-16">
                          <Autocomplete
                            id="status-select"
                            value={filters.isActive}
                            onChange={(e, value) => updateFormState("isActive", value)}
                            options={statusList}
                            autoHighlight
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className="scb-custom-dropdown-height rounded"
                              />
                            )}
                          />
             </div>

      </div>
        </div>
        <div className="add-supplier-if-item flex-1-input margin-right-16">
          <Button className="srn-btn" onClick={() => { filterCommissionBlocks(); setPage(1) }}>Search</Button>
          <Button className="srn-btn ms-3" onClick={() => ClearFilters()}>Clear</Button>
        </div>

        {/* <div className="add-supplier-if-item flex-1-input margin-right-16">
          <Button className="srn-btn" onClick={() => loadCommissionBlocks(true)} >Search Markup Block</Button>
        </div> */}
       
      </div>
    </div>
  );
}
