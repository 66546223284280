import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import ATCConfigDrawer from "./ATCConfigDrawer/ATCConfigDrawer"
import ATCConfigTable from "./ATCConfigTable/ATCConfigTable"

import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import RiseLoading from "../../Loader/RiseLoader";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ATCConfiguraton = () => {
    const [drawer, setDrawer] = useState(false);
    const [action, setAction] = useState("new");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [categories, setCategories] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    useEffect(() => {
        loadConfigurations();
        loadVendors();
    }, []);

    async function loadConfigurations() {
        setShowLoader(true);
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/AutoTicketConfiguration"
            )
            .then((response) => {
                setCategories(response.data);
                setShowLoader(false);
            })
            .catch((error) => {
                console.log(error)
                setShowLoader(false);
            });
    }


    const toggleDrawer = (value) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawer(value);

    };

    const handleSnackbarToggle = () => {
        setDrawer(false);
        setSnackbarOpen(true);
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    async function loadVendors() {
        let vendorArray = [];
        await axios
            .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    vendorArray.push({
                        id: response.data[i].bookingSwitches.id,
                        name: response.data[i].bookingSwitches.vendorName,
                    });
                }
                vendorArray.unshift({
                    id: "all",
                    name: "All",
                });
                console.log('vendorArray', vendorArray)
                setVendorList(vendorArray);
            });
    }

    const handleAtcRowClick = async (selectedRow) => {
        setSelectedItem(selectedRow);
        setAction("view");
        setDrawer(true);
    }

    return (
        <div className="search-container">
            <div className="search-content-container">
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <div className="search-search-container">
                            <div className="search-table-container">
                                <div className="search-title-add-button-container">
                                    <div className="search-heading">Auto Ticket Changer Configuration</div>
                                    <div className="search-icon-button-container">

                                        {/* {isUserHasPermission("SearchCategoryGroupWrite") ? ( */}
                                        <div
                                            className="add-search-button mt-0px"
                                            onClick={() => {
                                                setAction("new");
                                                setDrawer(true);
                                            }}
                                        >
                                            Add New
                                        </div>
                                        {/* ) : (
                                            ""
                                        )} */}
                                    </div>
                                </div>

                                <ATCConfigTable
                                    handleAtcRowClick={handleAtcRowClick}
                                    setAction={setAction}
                                    categories={categories}
                                ></ATCConfigTable>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
            <Drawer
                anchor="right"
                open={drawer}
                onClose={toggleDrawer(false)}
                BackdropProps={{ invisible: true }}
                variant="persistent"
                className="drawer-width"
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <div className="drawer-min-width-40">
                    <ATCConfigDrawer
                        selectedItem={selectedItem}
                        toggleDrawer={toggleDrawer}
                        action={action}
                        setAction={setAction}
                        handleSnackbarToggle={handleSnackbarToggle}
                        loadConfigurations={loadConfigurations}
                        vendorList={vendorList}
                        setVendorList={setVendorList}
                    ></ATCConfigDrawer>
                </div>
            </Drawer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity="success">
                    {`Setting has been ${action === "new" ? "created" : "updated"}`}
                </Alert>
            </Snackbar>
            {showLoader && <RiseLoading />}
        </div>
    );
}

export default ATCConfiguraton