import React, { useEffect, useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import airlineDetails from '../../data/AirlineDetails';
import airportDetails from '../../data/AirportDetails';
import { Button } from '@mui/joy';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from 'moment';
import cabinClass from '../../data/CabinClass';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router";
import Clarity from '@microsoft/clarity';

dayjs.extend(utc); // Use the UTC plugin
const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
];
const bookingToCabinMap = {
    F: "First",
    R: "First",
    A: "First",
    P: "First",
    C: "Business",
    I: "Business",
    D: "Business",
    J: "Business",
    Z: "Business",
};

const combineDateAndTime = (toDate, timeStamp, isNextDayArrival, previousYearAdded, previousMonth) => {
    const currentDate = dayjs();
    const currentMonth = currentDate.month();
    let date = Number(toDate.slice(0, 2));
    let monthString = toDate.slice(2, 5);
    let month = months.indexOf(monthString.toUpperCase());
    let year = currentDate.year(); // Start with the current year
    let hours = parseInt(timeStamp.slice(0, 2));
    let minutes = parseInt(timeStamp.slice(2, 4));
    if (isNextDayArrival) {
        date += 1;
    }

    let combinedDateTime = currentDate
        .set("hour", hours)
        .set("minute", minutes)
        .set("month", month)
        .set("date", date)
        .set("year", year);
    if (previousYearAdded) {
        if (month < previousMonth) {
            combinedDateTime = combinedDateTime.add(2, "year");
        } else {
            combinedDateTime = combinedDateTime.add(1, "year");
        }
    } else {
        if (month < currentMonth) {
            combinedDateTime = combinedDateTime.add(1, "year");
        }
    }
    if (combinedDateTime.isBefore(currentDate, 'minute')) {
        combinedDateTime = combinedDateTime.add(1, "year");
    }
    return combinedDateTime;
};

const parseItinerary = (lines) => {
    let parsedData = [];
    const datePattern = /\b\d{1,2}[A-Z]{3}\b/ig;
    const airportPattern = /([A-Z]{3})([A-Z]{3})/i;
    const timePattern = /#?\d{3,4}[APap]?(?:\+1)?/g;
    const itineraryLinePattern = /^[A-Za-z0-9]{2}\s*\d{1,4}\s*[A-Za-z]?\s*\d{1,2}[A-Za-z]{3}/;
    let previousYearAdded = false;
    let previousMonth = dayjs().month();
    for (let line of lines) {
        line = line.replace(/^\s*\d+(\.\s*|\s*)/, '').replace(/\s{2,}/g, ' ').trim();
        if (!line) continue;
        let airlineCode = null;
        let flightNumber = null;
        let bookingClassCode = null;
        if (!itineraryLinePattern.test(line)) continue;
        const flightMatch = line.match(/\b([A-Z0-9]{2})\s*(\d{1,4})\s*([A-Z]?)\b/);
        if (flightMatch) {
            airlineCode = flightMatch[1];
            flightNumber = flightMatch[2];
            bookingClassCode = flightMatch[3] || null;
            line = line.replace(flightMatch[0], '').trim();
        }
        const dateMatches = line.match(datePattern);
        const departureDate = dateMatches ? dateMatches[0] : null;
        const arrivalDate = dateMatches && dateMatches[1] ? dateMatches[1] : departureDate;
        const timeMatches = line.match(timePattern);
        let departureTime = null;
        let arrivalTime = null;
        if (timeMatches && timeMatches.length >= 1) {
            departureTime = timeMatches[0];
            arrivalTime = timeMatches.length > 1 ? timeMatches[1] : departureTime;
        }
        let isNextDayArrival = false;
        if (arrivalTime && arrivalTime.includes('+1')) {
            arrivalTime = arrivalTime.replace(/\+1$/, ''); // This will only remove +1 if it's at the end of the string.
            isNextDayArrival = true;
        }

        // Separate logic to remove "#" if present anywhere in the string
        if (arrivalTime && arrivalTime.includes('#')) {
            arrivalTime = arrivalTime.replace(/#/g, ''); // This will remove any occurrence of # in the string.
            isNextDayArrival = true;
        }
        departureTime = formatTime(departureTime);
        arrivalTime = formatTime(arrivalTime);
        let formattedDepartureDateTime = combineDateAndTime(departureDate, departureTime, false, previousYearAdded, previousMonth);
        let formattedArrivalDateTime = combineDateAndTime(arrivalDate, arrivalTime, isNextDayArrival, previousYearAdded, previousMonth);

        if (formattedArrivalDateTime.isBefore(formattedDepartureDateTime)) {
            formattedArrivalDateTime = formattedArrivalDateTime.add(1, 'year');
        }
        if (formattedDepartureDateTime.year() > dayjs().year()) {
            previousYearAdded = true;
        } else {
            previousYearAdded = false;
        }
        previousMonth = formattedDepartureDateTime.month();
        const airportMatches = line.match(airportPattern);
        const departureAirport = airportMatches ? airportMatches[1] : null;
        const destinationAirport = airportMatches ? airportMatches[2] : null;
        const cabinClassLabel = bookingToCabinMap[bookingClassCode] || "Economy";
        const cabinClassValue = cabinClass.find(v => v.label === cabinClassLabel)?.value;
        parsedData.push({
            airline: airlineCode,
            flightNo: flightNumber,
            bookingClass: bookingClassCode,
            cabinClass: cabinClassValue,
            cabinClassLabel: cabinClassLabel,
            depTime: formattedDepartureDateTime,
            arrTime: formattedArrivalDateTime,
            depDate: formattedDepartureDateTime,
            arrDate: formattedArrivalDateTime,
            originAirport: departureAirport,
            destinationAirport: destinationAirport,
            paxCount: 1,
            airlinePNR: "",
            gdsPNR: ""
        });
    }
    return parsedData.filter(itinerary => itinerary !== null);
}
const formatTime = (time) => {
    if (!time) return null;
    let isPM = time.toLowerCase().includes('p');
    let isAM = time.toLowerCase().includes('a');
    let formattedTime;
    let hours;
    let minutes;
    if (time.length === 4) {
        if (time.includes('P') || time.includes('A') || time.includes('p') || time.includes('a')) {
            hours = time.slice(0, time.length - 3);
            minutes = time.slice(1, 3);
        }
        else {
            hours = time.slice(0, time.length - 2);
            minutes = time.slice(-2);
        }
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    } if (time.length === 3) {
        hours = time.slice(0, time.length - 3);
        minutes = time.slice(1, -1);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
        formattedTime = `${hours}:${minutes}`;
    } else if (time.length === 5) {
        hours = time.slice(0, time.length - 3);
        minutes = time.slice(2, -1);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    } else if (time.length > 5) {
        hours = time.slice(0, time.length - 4);
        minutes = time.slice(2, -2);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    }
    let hourString = hours.toString();
    if (hourString.length === 1) {
        hours = '0' + hours;
    }
    formattedTime = `${hours}${minutes}`;
    return formattedTime;
};

function SearchItineraryParser({ search, setSearch, itineraryText, setItineraryText, dispatchIncludeAirlines, setModalIsOpen, modalIsOpen, setInputText, inputText, setItineraryData, itineraryData }) {
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        setItineraryText((prevState) => search.itineraryText);
        setItineraryData((prevState) => search.itineraryData);
    }, [])
    const [error, setError] = useState(null);
    const [startingRow, setStartingRow] = useState(null);
    const [selectedRadio, setSelectedRadio] = React.useState("oneway");
    const handleChange = (event) => {
        setSelectedRadio(event.target.value);
    };
    
    const parseItineraryText = () => {
        if (itineraryText && itineraryText?.length > 0) {
            const lines = itineraryText?.split("\n");
            let parsedData = [];
            parsedData = parseItinerary(lines);
            console.log(parsedData);
            const currentDate = dayjs();
            const pastItinerary = parsedData.some(itinerary => {
                const depDate = itinerary?.depDate;
                const arrDate = itinerary?.arrDate;
                const isPast = depDate.isBefore(currentDate);
                const monthsDiff = depDate.diff(currentDate, 'months', true);
                const isInvalidFuture = monthsDiff > 12;
                const depArrMonthsDiff = arrDate.diff(depDate, 'months', true);
                const isWithin12Months = depArrMonthsDiff <= 12;
                return isPast || isInvalidFuture || !isWithin12Months;
            });
            if (pastItinerary) {
                setError("The itinerary contains a date more than 12 months in the future.");
                setItineraryData([]);
            } else {
                if (parsedData.length === 0) {
                    setError("No valid itineraries found. Please check the format of your text.");
                    setItineraryData([]);
                } else {
                    setError(null);
                    setItineraryData(parsedData);
                }
            }
        }
    };
    const setIncludeAirlineData = async (data) => {
        if (data) {
            let list = [];
            if (typeof (data) === 'string') {
                let string = "";
                list.push(airlineDetails.find((el) => el.Code === data));
                string.length === 0
                    ? (string = data)
                    : (string = string.concat("," + data));
            } else {
                let array = data;
                array.forEach(element => {
                    list.push(airlineDetails.find((el) => el.Code === element));
                });
            }
            dispatchIncludeAirlines({ type: "includeAirlines", newValue: list });
        } else {
            dispatchIncludeAirlines({ type: "includeAirlines", newValue: [] });
        }
    };

    const getAirportData = (airport) => {
        if (airport) {
            let temp = airportDetails.find((el) => el.city_code === airport);
            return temp;
        }
    }
    const addItienaryToSearch = () => {
        const existingSearch = { ...search };
        let onwardData = itineraryData[0];
        let returnData = itineraryData[(startingRow - 1)];
        console.log("onwardData", onwardData, "returnData", returnData);
        existingSearch.originDestinations[0].departureDateTime = (onwardData.depDate);
        existingSearch.originDestinations[0].returnDateTime =
            selectedRadio === 'oneway' ? moment(onwardData.depDate).add(1, 'd') :
                returnData && returnData.depDate ?
                    (returnData.depDate) : moment(onwardData.depDate).add(1, 'd');
        console.log("existingSearch.return", existingSearch.return);
        console.log("existingSearch.depart", existingSearch.depart);
        existingSearch.adult = onwardData.paxCount;
        existingSearch.children = 0;
        existingSearch.infant = 0;
        existingSearch.originDestinations[0].origin = getAirportData(onwardData.originAirport);
        existingSearch.type = selectedRadio === 'oneway' ? 'OneWay' : 'RoundTrip';
        if (itineraryData && itineraryData.length === 1) {
            setIncludeAirlineData(onwardData.airline);
            existingSearch.flightNo = onwardData.flightNo;
            existingSearch.includeCarrier = onwardData.airline;
            existingSearch.originDestinations[0].destination = getAirportData(onwardData.destinationAirport);
        }
        else if (itineraryData.length > 1) {
            let flightNoSet = new Set();
            let includeCarrierSet = new Set();
            itineraryData.forEach((data) => {
                flightNoSet.add(data.flightNo);
                includeCarrierSet.add(data.airline);
            });
            setIncludeAirlineData(Array.from(includeCarrierSet));
            existingSearch.flightNo = Array.from(flightNoSet);
            existingSearch.includeCarrier = Array.from(includeCarrierSet).join(',');
            let airport = selectedRadio === 'oneway' ? itineraryData[(itineraryData.length - 1)].destinationAirport : itineraryData[(startingRow - 1)].originAirport;
            existingSearch.originDestinations[0].destination = getAirportData(airport);
        }
        existingSearch.class = onwardData.cabinClassLabel;
        existingSearch.pasteSearchEnabled = true;
        const { OnwardItineraryDestinations, ReturnItineraryDestinations } = extractAndCategorizeAirports(itineraryData, selectedRadio === 'oneway' ? false : true);
        const { OnwardItineraryTime, ReturnItineraryTime } = extractAndCategorizeTime(itineraryData, selectedRadio === 'oneway' ? false : true);
        const { OnwardItineraryDate, ReturnItineraryDate } = extractAndCategorizeDate(itineraryData, selectedRadio === 'oneway' ? false : true);
        const { OnwardAirlineCode, OnwardFlightNo, ReturnAirlineCode, ReturnFlightNo, } = extractAndCategorizeAirlineCodeAndNumber(itineraryData, selectedRadio === 'oneway' ? false : true);
        existingSearch.OnwardAirlineCode = OnwardAirlineCode;
        existingSearch.OnwardFlightNo = OnwardFlightNo;
        existingSearch.ReturnAirlineCode = ReturnAirlineCode;
        existingSearch.ReturnFlightNo = ReturnFlightNo;
        existingSearch.OnwardItineraryDate = OnwardItineraryDate;
        existingSearch.ReturnItineraryDate = ReturnItineraryDate;
        existingSearch.OnwardItineraryTime = OnwardItineraryTime;
        existingSearch.ReturnItineraryTime = ReturnItineraryTime;
        existingSearch.OnwardItineraryDestinations = OnwardItineraryDestinations;
        existingSearch.ReturnItineraryDestinations = ReturnItineraryDestinations;
        existingSearch.itineraryText = itineraryText;
        existingSearch.itineraryData = itineraryData;
        console.log("Search data before assign", existingSearch);
        localStorage.setItem("lastSearch", JSON.stringify(existingSearch));
        setSearch((prevState) => ({ ...existingSearch }));
        setModalIsOpen(false)
    }
    const extractAndCategorizeDate = (data, isRoundTrip) => {
        let onwardDate = new Set();
        let returnDate = new Set();
        for (let i = 0; i < data.length; i++) {
            const flight = data[i];
            const { depDate, arrDate } = flight;

            if (isRoundTrip && i >= (parseInt(startingRow) - 1)) {
                returnDate.add(depDate + '-' + arrDate);
            } else {
                onwardDate.add(depDate + '-' + arrDate);
            }
        }
        const OnwardItineraryDate = Array.from(onwardDate);
        const ReturnItineraryDate = Array.from(returnDate);
        return { OnwardItineraryDate, ReturnItineraryDate };
    };
    const extractAndCategorizeAirlineCodeAndNumber = (data, isRoundTrip) => {
        let onwardCode = [];
        let onwardNumber = [];
        let returnNumber = [];
        let returnCode = [];
        for (let i = 0; i < data.length; i++) {
            const flight = data[i];
            const { airline, flightNo } = flight;
            if (isRoundTrip && i >= (parseInt(startingRow) - 1)) {
                returnNumber.push(flightNo);
                returnCode.push(airline);
            } else {
                onwardCode.push(airline);
                onwardNumber.push(flightNo);
            }
        }
        const OnwardAirlineCode = onwardCode;
        const OnwardFlightNo = onwardNumber;
        const ReturnAirlineCode = returnCode;
        const ReturnFlightNo = returnNumber;
        return { OnwardAirlineCode, OnwardFlightNo, ReturnAirlineCode, ReturnFlightNo };
    };
    const extractAndCategorizeTime = (data, isRoundTrip) => {
        let onwardTime = new Set();
        let returnTime = new Set();
        for (let i = 0; i < data.length; i++) {
            const flight = data[i];
            const { depTime, arrTime } = flight;
            if (isRoundTrip && i >= (parseInt(startingRow) - 1)) {
                returnTime.add(depTime + '-' + arrTime);
            } else {
                onwardTime.add(depTime + '-' + arrTime);
            }
        }
        const OnwardItineraryTime = Array.from(onwardTime);
        const ReturnItineraryTime = Array.from(returnTime);
        return { OnwardItineraryTime, ReturnItineraryTime };
    };
    const extractAndCategorizeAirports = (data, isRoundTrip) => {
        let onwardDestinations = new Set();
        let returnDestinations = new Set();
        for (let i = 0; i < data.length; i++) {
            const flight = data[i];
            const { originAirport, destinationAirport } = flight;

            if (isRoundTrip && i >= (parseInt(startingRow) - 1)) {
                returnDestinations.add(originAirport + destinationAirport);
            } else {
                onwardDestinations.add(originAirport + destinationAirport);
            }
        }
        const OnwardItineraryDestinations = Array.from(onwardDestinations);
        const ReturnItineraryDestinations = Array.from(returnDestinations);
        return { OnwardItineraryDestinations, ReturnItineraryDestinations };
    };

    return (
        <div style={{ zIndex: 50 }}>
            <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} sx={{ maxWidth: '70vw', maxHeight: '70vh', overflowX: 'auto', overflowY: 'scroll', marginTop: '10vh', }}>
                <Sheet
                    variant="outlined"
                    sx={{
                        // maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        maxHeight: '60vw',
                        overflowY: 'scroll'
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <br />
                    <p style={{ textTransform: "capitalize", color: "red" }}>
                        {t('Ensure to Enter Valid Itinerary Text in a valid format.')}
                    </p>
                    <p style={{ textTransform: "capitalize", color: "red" }}>
                        {t('Ensure to Enter only Itinerary Text in the area.')}
                    </p>
                    <br />
                    <div className="m-3" style={{ display: 'flex' }}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedRadio}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="oneway"
                                    control={<Radio />}
                                    label="Oneway"
                                />
                                <FormControlLabel
                                    value="roundtrip"
                                    control={<Radio />}
                                    label="Round Trip"
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            selectedRadio === "roundtrip" ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                    <span>{(t('Return Trip Starting Row'))}</span>
                                    <input type='number' style={{ marginLeft: '10px', maxWidth: '30%', maxHeight: '30px' }} onChange={(e) => setStartingRow(e.target.value)} value={startingRow} />
                                </div>
                                : ""
                        }
                    </div>
                    <br />
                    {
                        selectedRadio === "roundtrip" && startingRow && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>{t('Please Enter Itinerary Text')}</p> :
                            selectedRadio === "oneway" && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>{t('Please Enter Itinerary Text')}t</p> :
                                selectedRadio === "roundtrip" && !startingRow && itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>{t('Please Enter Return Trip Starting Row')}</p> :
                                    selectedRadio === "roundtrip" && !startingRow && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>{t('Please Enter Itinerary Text And Return Trip Starting Row')}</p> : ""
                    }
                    <br />
                    <textarea
                        rows="5"
                        cols="80"
                        value={itineraryText}
                        onChange={(e) => { setItineraryText(e.target.value) }}
                        placeholder={t("Paste itinerary text here")}
                    />
                    <br />{
                        (selectedRadio === "roundtrip" && startingRow && itineraryText) || (selectedRadio === "oneway" && itineraryText) ? <Button variant='outlined' style={{ float: 'left', marginLeft: '10px', color: '#0a3622', borderColor: '#0a3622' }} onClick={parseItineraryText}>Parse Itinerary</Button> : ""
                    }
                    {/* <Button variant='outlined' style={{ float: 'left', marginLeft: '10px', color: '#0a3622', borderColor: '#0a3622' }} onClick={parseItineraryText}>Parse Itinerary</Button> */}
                    {((selectedRadio === "roundtrip" && startingRow && itineraryText) || (selectedRadio === "oneway" && itineraryText)) && itineraryData && itineraryData.length > 0 ? <Button variant='outlined' style={{ float: 'right', marginLeft: '10px', color: '#d63384', borderColor: '#d63384' }} onClick={addItienaryToSearch}>Add to Search</Button> : ""}
                    <br />
                    <br />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {(itineraryData && itineraryData.length > 0 && !error) && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>{t('airline')}</TableCell>
                                    <TableCell colSpan={3}>{t('Pax Count')}</TableCell>
                                    <TableCell colSpan={3}>{t('FlightNo')} </TableCell>
                                    <TableCell colSpan={3}>{t('Departure')} </TableCell>
                                    <TableCell colSpan={3}>{t('Arrival')} </TableCell>
                                    <TableCell colSpan={3}>{t('From')} </TableCell>
                                    <TableCell colSpan={3}>{t('To')} </TableCell>
                                    <TableCell colSpan={3}>{t('Cabin Class')} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itineraryData.map((flight, index) => (
                                    <TableRow key={`${index + 'pob'}`}>
                                        <TableCell colSpan={3}>{flight.airline}</TableCell>
                                        <TableCell colSpan={3}>{flight.paxCount}</TableCell>
                                        <TableCell colSpan={3}>{flight.flightNo}</TableCell>
                                        <TableCell colSpan={3}>{moment(flight?.depDate?.$d).format("DD/MM/YYYY") +
                                            " " +
                                            moment(flight?.depDate?.$d).format("HH:mm")}</TableCell>
                                        <TableCell colSpan={3}>{moment(flight?.arrDate?.$d).format("DD/MM/YYYY") +
                                            " " +
                                            moment(flight?.arrDate?.$d).format("HH:mm")}</TableCell>
                                        <TableCell colSpan={3}>{flight.originAirport}</TableCell>
                                        <TableCell colSpan={3}>{flight.destinationAirport}</TableCell>
                                        <TableCell colSpan={3}>{t(flight.cabinClassLabel)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Sheet>
            </Modal>
        </div>
    );
}

SearchItineraryParser.propTypes = {
    search: PropTypes.object.isRequired,
    setSearch: PropTypes.func.isRequired,
    dispatchIncludeAirlines: PropTypes.func.isRequired,
    setModalIsOpen: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    setInputText: PropTypes.func.isRequired,
    inputText: PropTypes.string.isRequired,
    setItineraryData: PropTypes.func.isRequired,
    itineraryData: PropTypes.array.isRequired,
};
export default SearchItineraryParser;


