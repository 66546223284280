import React, { useEffect, useState, useReducer, useRef } from "react";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import airlineDetails from "../../../data/AirlineDetails";
import Country from "../../../data/Country";
import statusOptions from '../../../data/statusOptions';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CircularProgress, Button } from "@material-ui/core";
import Notify from '../../Notifications/Notifications';
import Box from '@mui/material/Box';
import getAllPcc from '../../../functions/getAllPcc'
import axios from "axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f3f3f3',
    borderTop: '2px solid #c4bdbd',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


const PassthroughConfigurationModal = ({ status, action, data, modal, setModal, pccList, setShowLoader, showLoader, reload, setPopUpMsg, handleSnackbarToggle }) => {
    const [applicableCountries, setApplicableCountries] = useState([]);
    const [airline, setAirline] = useState([]);
    const [rail, setRail] = useState('');
    const [productType, setProductType] = useState();
    const [supplier, setSupplier] = useState([]);
    const [pcc, setPcc] = useState([]);
    const [cardType, setCardType] = useState([]);
    const [originCountry, setOriginCountry] = useState([]);
    const [destinationCountry, setDestinationCountry] = useState([]);
    const [configStatus, setConfigStatus] = useState([]);
    const railOptions = ["Italo"];
    const railPCCOptions = [{ value: "DF", label: "RailTest (DF)" }];
    const productTypeOptions = ["Flight", "Rail"];
    const cardTypes = [
        { Name: "Visa", Code: "VI" },
        { Name: "Mastercard", Code: "MX/CA" },
        { Name: "Amex", Code: "AX" },
        { Name: "UATP", Code: "TP" },
        { Name: "Diners", Code: "DS" },
    ];

    console.log('data', data)

    const handleModalClose = () => {
        setModal({ status: false, action: "", data: "" })
    }

    const handleSubmit = () => {
        console.log('Submit')
    }

    async function updateConfiguration() {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };
        if (action === "add") {
            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/PassThroughConfiguration",
                    JSON.stringify({
                        isActive: configStatus === "Active" ? true : false,
                        applicableCountries: handleArrayToString(applicableCountries, "code"),
                        supplierPCC: handleArrayToString(pcc, "value"),
                        airlines: handleArrayToString(airline, "Code"),
                        cardType: handleArrayToString(cardType, "Code"),
                        originCountry: handleArrayToString(originCountry, "code"),
                        destinationCountry: handleArrayToString(destinationCountry, "code"),
                        productType: productType,
                        rail: rail,
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    setShowLoader(false);
                    console.log(res);
                    setModal({ status: false, action: "", data: "" });
                    reload();
                    handleSnackbarToggle();
                    setPopUpMsg(true);
                })
                .catch((err) => {
                    setShowLoader(false);
                    console.log(err);
                    handleSnackbarToggle();
                    setPopUpMsg(false);
                });
        }
        else {
            await axios
                .put(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/PassThroughConfiguration/" + data.id,
                    JSON.stringify({
                        id: data.id,
                        isActive: configStatus === "Active" ? true : false,
                        createdBy: data.createdBy,
                        created: data.created,
                        applicableCountries: handleArrayToString(applicableCountries, "code"),
                        supplierPCC: handleArrayToString(pcc, "value"),
                        airlines: handleArrayToString(airline, "Code"),
                        rail: rail,
                        productType: productType,
                        cardType: handleArrayToString(cardType, "Code"),
                        originCountry: handleArrayToString(originCountry, "code"),
                        destinationCountry: handleArrayToString(destinationCountry, "code")
                    }),
                    {
                        headers,
                    }
                )
                .then((res) => {
                    setShowLoader(false);
                    console.log(res);
                    setModal({ status: false, action: "", data: "" });
                    reload();
                    handleSnackbarToggle();
                    setPopUpMsg(true);
                })
                .catch((err) => {
                    setShowLoader(false);
                    console.log(err);
                    handleSnackbarToggle();
                    setPopUpMsg(false);
                });
        }


    }

    const handleArrayToString = (arrayList, field) => {
        let strValue = "";
        if (arrayList !== undefined && arrayList.length > 0) {
            if (field === "value") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.value)
                        : (strValue = strValue + "," + arr.value)
                );
            } else if (field === "Code") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.Code)
                        : (strValue = strValue + "," + arr.Code)
                );
            } else if (field === "code") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.code)
                        : (strValue = strValue + "," + arr.code)
                );
            } else if (field === "city_code") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.city_code)
                        : (strValue = strValue + "," + arr.city_code)
                );
            } else if (field === "countrycode") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.code)
                        : (strValue = strValue + "," + arr.code)
                );
            } else if (field === "raw") {
                //console.log(arrayList);
                arrayList.map((arr) =>
                    strValue === "" ? (strValue = arr) : (strValue = strValue + "," + arr)
                );
            } else if (field === "label") {
                arrayList.map((arr) =>
                    strValue === ""
                        ? (strValue = arr.label)
                        : (strValue = strValue + "," + arr.label)
                );
            }
        }
        return strValue;
    };

    useEffect(() => {
        console.log('applicableCountries', applicableCountries);
        console.log('airline', airline);
        console.log('supplier', supplier);
        console.log('pcc', pcc);
        console.log('cardType', cardType);
        console.log('originCountry', originCountry);
        console.log('destinationCountry', destinationCountry);
        console.log('configStatus', configStatus);
    }, [applicableCountries, airline, supplier, pcc, cardType, originCountry, destinationCountry, configStatus])

    useEffect(() => {

        if (data.applicableCountries) {
            const countries = data.applicableCountries.split(",").map((countryCode) => {
                countryCode = countryCode.trim();
                return Country.find(country => country.code === countryCode) || { code: countryCode, label: countryCode };
            });
            setApplicableCountries(countries);
        } else { setApplicableCountries([]); }
        if (data.airlines) {
            const airlines = data.airlines.split(",").map((airlineCode) => {
                airlineCode = airlineCode.trim();
                return airlineDetails.find(airline => airline.Code === airlineCode) || { Code: airlineCode, Name: airlineCode };
            });
            setAirline(airlines);
        } else { setAirline([]); }

        if (data.supplierPCC) {
            const pccs = data.supplierPCC.split(",").map((pccLabel) => {
                pccLabel = pccLabel.trim();
                return pccList.find(pcc => pcc.value === pccLabel) || { label: pccLabel };
            });
            setPcc(pccs);
        } else { setPcc([]); }

        if (data.cardType) {
            const cardTypesList = data.cardType.split(",").map((cardTypeCode) => {
                cardTypeCode = cardTypeCode.trim();
                return cardTypes.find(card => card.Code === cardTypeCode) || { Code: cardTypeCode, Name: cardTypeCode };
            });
            setCardType(cardTypesList);
        } else { setCardType([]); }

        if (data.originCountry) {
            const originCountries = data.originCountry.split(",").map((countryCode) => {
                countryCode = countryCode.trim();
                return Country.find(country => country.code === countryCode) || { code: countryCode, label: countryCode };
            });
            setOriginCountry(originCountries);
        } else { setOriginCountry([]); }

        if (data.destinationCountry) {
            const destinationCountries = data.destinationCountry.split(",").map((countryCode) => {
                countryCode = countryCode.trim();
                return Country.find(country => country.code === countryCode) || { code: countryCode, label: countryCode };
            });
            setDestinationCountry(destinationCountries);
        } else { setDestinationCountry([]); }

        if (data.isActive !== undefined) {
            setConfigStatus(data.isActive ? "Active" : "In-Active");
        }
        if (data.productType) {
            setProductType(data.productType);
        }
        if (data.rail) {
            setRail(data.rail);
        }


    }, [modal])

    return (
        <Modal
            size="lg"
            show={status}
            onHide={() => handleModalClose()}
            backdrop="static"
            keyboard={false}
            className="ticket-config-modal"
            centered
        >
            <Box sx={{ ...style, width: 700 }}>
                <Modal.Header>
                    <Modal.Title>
                        <span className="action-text">{action}</span> Pass Through Configuration
                    </Modal.Title>
                    <button type="button" className="btn-close pull-right modal_close" onClick={() => handleModalClose()}></button>
                </Modal.Header>
                <Modal.Body>
                    <ValidatorForm
                        // ref={ticketConfigForm}
                        onError={() => Notify("error", "Form contains error", "Ticketing Configuration")}
                        onSubmit={handleSubmit}
                    >
                        <Container className="ticketModalContainer">
                            <Row className="">
                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        id="tags-outlined"
                                        options={productTypeOptions}
                                        disabled={action === "view"}
                                        value={productType}
                                        getOptionLabel={(option) =>
                                            option
                                        }
                                        onChange={(event, newValue) => setProductType(newValue)}
                                        // getOptionDisabled={(option) => {
                                        //     if (applicableCountries.length) {
                                        //         if (applicableCountries.some((airline) => airline.code === "All") && option.code !== 'All') {
                                        //             return true;
                                        //         } else if (applicableCountries.some((airline) => airline.code !== "All") && option.code === 'All') {
                                        //             return true;
                                        //         }
                                        //     }
                                        // }}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={productType}
                                                className="ticket-config-inputs"
                                                label="Product Type"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id="tags-outlined"
                                        options={Country}
                                        disabled={action === "view"}
                                        value={applicableCountries}
                                        getOptionLabel={(option) =>
                                            option.code === "All"
                                                ? "All"
                                                : `${option.label} (${option.code})`
                                        }
                                        onChange={(event, newValue) => setApplicableCountries(newValue)}
                                        filterSelectedOptions
                                        getOptionDisabled={(option) => {
                                            if (applicableCountries.length) {
                                                if (applicableCountries.some((airline) => airline.code === "All") && option.code !== 'All') {
                                                    return true;
                                                } else if (applicableCountries.some((airline) => airline.code !== "All") && option.code === 'All') {
                                                    return true;
                                                }
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={applicableCountries}
                                                className="ticket-config-inputs"
                                                label="Applicable Countries"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col sm={12} className="mb-2">
                                    {
                                        productType === 'Flight' ?
                                            <Autocomplete
                                                multiple
                                                limitTags={1}
                                                id="tags-outlined"
                                                options={airlineDetails}
                                                disabled={action === "view"}
                                                value={airline}
                                                getOptionLabel={(option) =>
                                                    option.Code === "All"
                                                        ? "All"
                                                        : `${option.Name} (${option.Code})`
                                                }
                                                onChange={(event, newValue) => setAirline(newValue)}
                                                filterSelectedOptions
                                                getOptionDisabled={(option) => {
                                                    if (airline.length) {
                                                        if (airline.some((airline) => airline.Code === "All") && option.Code !== 'All') {
                                                            return true;
                                                        } else if (airline.some((airline) => airline.Code !== "All") && option.Code === 'All') {
                                                            return true;
                                                        }
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        variant="filled"
                                                        color="secondary"
                                                        size="small"
                                                        disabled={action === "view"}
                                                        value={airline}
                                                        className="ticket-config-inputs"
                                                        label="Airline"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "new-password",
                                                        }}
                                                    />
                                                )}
                                            />
                                            : productType === 'Rail' ?
                                                <Autocomplete
                                                    // multiple
                                                    // limitTags={1}
                                                    id="tags-outlined"
                                                    options={railOptions}
                                                    disabled={action === "view"}
                                                    value={rail}
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    onChange={(event, newValue) => setRail(newValue)}
                                                    // filterSelectedOptions
                                                    // getOptionDisabled={(option) => {
                                                    //     if (rail.length) {
                                                    //         if (rail.some((rail) => rail.Code === "All") && option.Code !== 'All') {
                                                    //             return true;
                                                    //         } else if (rail.some((rail) => rail.Code !== "All") && option.Code === 'All') {
                                                    //             return true;
                                                    //         }
                                                    //     }
                                                    // }}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            disabled={action === "view"}
                                                            value={rail}
                                                            className="ticket-config-inputs"
                                                            label="Rail"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "new-password",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                : ''
                                    }
                                </Col>

                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id="tags-outlined"
                                        options={productType === 'Flight' ? pccList : productType === 'Rail' ? railPCCOptions : []}
                                        disabled={action === "view"}
                                        value={pcc}
                                        getOptionLabel={(option) => option.label
                                        }
                                        onChange={(event, newValue) => setPcc(newValue)}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={pcc}
                                                className="ticket-config-inputs"
                                                label="Supplier PCC"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id="tags-outlined"
                                        options={cardTypes}
                                        disabled={action === "view"}
                                        value={cardType}
                                        getOptionLabel={(option) =>
                                            option.Code === "All"
                                                ? "All"
                                                : `${option.Name} (${option.Code})`
                                        }
                                        onChange={(event, newValue) => setCardType(newValue)}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={cardType}
                                                className="ticket-config-inputs"
                                                label="Card Types"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id="tags-outlined"
                                        options={Country}
                                        disabled={action === "view"}
                                        value={originCountry}
                                        getOptionLabel={(option) =>
                                            option.code === "All"
                                                ? "All"
                                                : `${option.label} (${option.code})`
                                        }
                                        onChange={(event, newValue) => setOriginCountry(newValue)}
                                        filterSelectedOptions
                                        getOptionDisabled={(option) => {
                                            if (originCountry.length) {
                                                if (originCountry.some((originCountry) => originCountry.code === "All") && option.code !== 'All') {
                                                    return true;
                                                } else if (originCountry.some((originCountry) => originCountry.code !== "All") && option.code === 'All') {
                                                    return true;
                                                }
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={originCountry}
                                                className="ticket-config-inputs"
                                                label="Origin Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>



                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id="tags-outlined"
                                        options={Country}
                                        disabled={action === "view"}
                                        value={destinationCountry}
                                        getOptionLabel={(option) =>
                                            option.code === "All"
                                                ? "All"
                                                : `${option.label} (${option.code})`
                                        }
                                        onChange={(event, newValue) => setDestinationCountry(newValue)}
                                        filterSelectedOptions
                                        getOptionDisabled={(option) => {
                                            if (destinationCountry.length) {
                                                if (destinationCountry.some((destinationCountry) => destinationCountry.code === "All") && option.code !== 'All') {
                                                    return true;
                                                } else if (destinationCountry.some((destinationCountry) => destinationCountry.code !== "All") && option.code === 'All') {
                                                    return true;
                                                }
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                disabled={action === "view"}
                                                value={destinationCountry}
                                                className="ticket-config-inputs"
                                                label="Destination Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password",
                                                }}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col sm={12} className="mb-2">
                                    <Autocomplete
                                        id="Status select"
                                        value={configStatus}
                                        onChange={(event, newValue) => setConfigStatus(newValue)}
                                        options={statusOptions}
                                        getOptionLabel={option => option}
                                        renderInput={(params) => (
                                            <TextValidator
                                                {...params}
                                                className="ticket-config-inputs"
                                                label="Status"
                                                variant="filled"
                                                color="secondary"
                                                size="small"
                                                validators={["required"]}
                                                errorMessages={["required"]}
                                            />
                                        )}
                                    />

                                </Col>

                            </Row>
                        </Container>
                    </ValidatorForm>

                </Modal.Body>
                {
                    action !== "view" ?
                        <Modal.Footer>
                            <Row className="">
                                <Col className="">
                                    {
                                        action != "view" &&
                                        <Button
                                            className="modal_submit_btn"
                                            variant="filled"
                                            color="primary"
                                            size="large"
                                            style={{
                                                textTransform: "none",
                                                width: "100%"
                                            }}
                                            disabled={(productType === '' || productType === null || productType === undefined) ? true : false}
                                            onClick={updateConfiguration}
                                        >
                                            {action === "edit" ? "Update" : "Create"}
                                        </Button>
                                    }
                                    {showLoader && <div style={{ position: 'absolute', top: '45%', left: '50%' }}><CircularProgress /></div>}
                                </Col>
                            </Row>
                        </Modal.Footer>
                        : ""
                }
            </Box>

        </Modal>
    );
}

export default PassthroughConfigurationModal