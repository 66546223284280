import React from "react";
import "./Results.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import useConfigurationTableRow from "../../../hooks/useConfigurationTableRow";
import useConfigurationTableCell from "../../../hooks/useConfigurationTableCell";
import moment from "moment";
import { checkIfUserStaff } from "../../../services/AuthService";
import { encryptData } from "../../../providers/dataEncryptDecryptor";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Clarity from '@microsoft/clarity';
import { useLocation } from "react-router-dom";

export default function Results(props) {
	const { t } = useTranslation();
	const StyledTableRow = useConfigurationTableRow;
	const StyledTableCell = useConfigurationTableCell;
	const [snackBarOpen, setSnackBarOpen] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');
	const [alertSeverity, setAlertSeverity] = React.useState('success');
	const location = useLocation();
	function openNewTab(result) {
		sessionStorage.setItem("orderId", encryptData(result));

		let link = "";
		if (props.serviceType === 'Flight') {
			link = "/flight/view-booked-tickets";
		} else if (props.serviceType === 'Rail') {
			link = "/rail/ViewBookedTickets";
		}

		window.open(link, "_blank");
	}

	const BootstrapTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
		},
	}));

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			setAlertMessage(t("Content Copied."));
			setAlertSeverity('success');
			setSnackBarOpen(true);
		} catch (error) {
			console.error("Failed to copy text to clipboard:", error);
			setAlertMessage(t("Failed to copy content."));
			setAlertSeverity('error');
			setSnackBarOpen(true);
		}
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};


	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleSnackbarClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	let results = props.documents.map((result, index) => {
		return (
			<StyledTableRow
				key={index}
				onClick={() => openNewTab(result?.document?.OrderRefNumber)}
				className='pointer'>
				<StyledTableCell component='th' scope='row'>
					<div className="d-flex align-items-center" style={{ gap: '5px' }}>
						<div className='table-cell-title'>
							{result?.document?.OrderRefNumber}
						</div>
						<BootstrapTooltip title={"Copy"}>
							<ContentCopyIcon
								onClick={(e) => {
									e.stopPropagation(); // Prevent row click event
									copyToClipboard(result?.document?.OrderRefNumber);
								}}
								className="contentCopyIcon"
								style={{ fontSize: '16px' }}
							/>
						</BootstrapTooltip>
					</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>
						{result?.document?.Services}
					</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>{
						(!checkIfUserStaff() && result?.document?.BookingStatus === "Refund_Initiated") ? t('Cancelled') : (!checkIfUserStaff() && result?.document?.BookingStatus === "Void_Refunded") ? t('Refunded') : t(result?.document?.BookingStatus)
					}</div>
				</StyledTableCell>
				<StyledTableCell component='th' scope='row'>
					<div className='table-cell-title'>{
						// result.document.Status
						(!checkIfUserStaff() && result?.document?.Status === "Refund_Initiated") ? t('Cancelled') : (!checkIfUserStaff() && result?.document?.Status === "Void_Refunded") ? t('Refunded') : t(result?.document?.Status)
					}</div>
				</StyledTableCell>

				<StyledTableCell>
					<div className='table-cell-description'>{result?.document?.Origin}</div>
					<div className='table-cell-description'>
						{result?.document?.Destination}
					</div>
				</StyledTableCell>
				<StyledTableCell>
					<div className='table-cell-title'>{result?.document?.ClientName}</div>
				</StyledTableCell>
				<StyledTableCell>
					{
						props?.serviceType === 'Flight' && result?.document?.OrderSegment?.length > 0 ?

							<div className='table-cell-title'>
								{
									result?.document?.OrderSegment[0]?.AirlinePNR ?
										<>
											<div className="d-flex align-items-center" style={{ gap: '5px' }}>
												<div>{result?.document?.OrderSegment[0]?.AirlinePNR}</div>
												<BootstrapTooltip title={"Copy"}>
													<ContentCopyIcon
														onClick={(e) => {
															e.stopPropagation();
															copyToClipboard(result?.document?.OrderSegment[0]?.AirlinePNR);
														}}
														className="contentCopyIcon"
														style={{ fontSize: '16px' }}
													/>
												</BootstrapTooltip>
											</div>
										</>
										: " - "
								}
							</div>
							: ''
					}
					{
						props.serviceType === 'Rail' && result?.document?.RailOrderSegment?.length > 0 ?

							<div className='table-cell-title'>
								{
									result?.document?.RailOrderSegment[0]?.PNR ?
										<>
											<div className="d-flex align-items-center" style={{ gap: '5px' }}>
												<div>{result?.document?.RailOrderSegment[0]?.PNR}</div>
												<BootstrapTooltip title={"Copy"}>
													<ContentCopyIcon
														onClick={(e) => {
															e.stopPropagation(); // Prevent row click event
															copyToClipboard(result?.document?.RailOrderSegment[0]?.PNR);
														}}
														className="contentCopyIcon"
														style={{ fontSize: '16px' }}
													/>
												</BootstrapTooltip>
											</div>
										</>
										: " - "
								}
							</div>
							: ''
					}
				</StyledTableCell>
				{
					props.serviceType === 'Flight' ?

						<StyledTableCell>
							<div className='table-cell-title'>
								{result?.document?.ValidatingAirlineName}
							</div>
						</StyledTableCell>
						: ''}
				{checkIfUserStaff() && (
					<StyledTableCell>
						<div className='table-cell-title'>{result?.document?.PCC}</div>
						<div className='table-cell-description'>
							{result?.document?.Supplier}
						</div>
					</StyledTableCell>
				)}
				<StyledTableCell align="right">
					{result?.document?.BookingSource}
				</StyledTableCell>
				<StyledTableCell align='right'>
					<div className='table-cell-description'>
						Created by:{" "}
						<span style={{ color: "black" }}>
							{result?.document?.CreatedByName}
						</span>
					</div>
					<div className='table-cell-description'>
						Created on:
						{moment(result?.document?.CreatedOnDate)
							.utc()
							.format("DD/MM/YYYY")}
						{" , "}
						{(result?.document?.CreatedOnDate).split("T")[1].split(".")[0]}
						{/* {moment(result?.document?.CreatedOnDate)
							.utc()
							.format("HH:MM:SS")} */}
					</div>
					{result?.document?.ActionedOnDate && <div className='table-cell-description'>
						Modified on:
						{moment(result?.document?.ActionedOnDate)
							.utc()
							.format("DD/MM/YYYY")}
						{" , "}
						{(result?.document?.ActionedOnDate).split("T")[1].split(".")[0]}
						{/* {moment(result?.document?.ActionedOnDate)
							.utc()
							.format("HH:MM:SS")} */}
					</div>}
				</StyledTableCell>
			</StyledTableRow>
		);
	});

	return (
		<div>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				action={action}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={alertSeverity} // Use the severity state
					sx={{
						width: "100%",
						backgroundColor: alertSeverity === 'success' ? '#4caf50 !important' : "#f44336 !important",
						color: "#fff !important"
					}}
				>
					{alertMessage} {/* Display the appropriate message */}
				</Alert>
			</Snackbar>
			<TableContainer component={Paper} className='table-paper-styles'>
				<Table className='table-background'>
					<TableHead>
						<TableRow>
							<TableCell>{t("GFS ID")}</TableCell>
							<TableCell>{t("Service Type")}</TableCell>
							<TableCell>{t("Booking Status")}</TableCell>
							<TableCell>{t("Current Status")}</TableCell>
							<TableCell>{t("Origin-Destination")}</TableCell>
							<TableCell>{t("Client Name")}</TableCell>
							<TableCell>{t("PNR")}</TableCell>
							{props.serviceType === 'Flight' ? <TableCell>{t("Airline")}</TableCell> : ''}
							{checkIfUserStaff() && <TableCell>{t("Supplier")}</TableCell>}
							<TableCell>{t("Booking Source")}</TableCell>
							<TableCell align='right' width={220}>
								{t("Created By")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{results}</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}
